import { isDefined, recursiveFlattenObject } from '@/app/utils/common';
import {
	decodeGapcPartIdentityKey,
	JobPart,
	PartAssembly,
	PartSlotIds,
	PartSubAssembly
} from '@sdk/lib';
import { isNil, min, partition, sortBy, sum, uniq, uniqBy } from 'lodash-es';
import {
	AssemblyCategoryMap,
	DiagramCategoryMap,
	DisplayableAssembly,
	DisplayableDiagram,
	PartInterpretationFormData,
	PartInterpretationSelection
} from '../types';
import { accessories } from './accessories';
import { getHumanCentricAssemblyDiagrams } from './diagrams';
import {
	getPartSlotMappings,
	mapPartSlotCodeToAssembly,
	mapPartSlotCodeToDiagram
} from './part-slot-code';
import { filterSearchAssemblies, filterSearchDiagrams } from './search';
import { alphabeticSortKey, confidenceSortKey, stableSortKey } from './sort';
import {
	transformBrowsingDiagram,
	transformPartAssemblies,
	transformSubPartAssemblies
} from './transform';

type CategorisingArgs = {
	assemblies: PartAssembly[];
	search?: string;
	// todo (remove later): hack to inject local emblem parts into the app
	withLocalContent?: boolean;
};

// Categories by the abstract assembly tree
export const categoriesByAssemblies = ({
	assemblies,
	search,
	withLocalContent
}: CategorisingArgs) => {
	const [_hcas, relevants] = partition(
		assemblies,
		({ assemblyType }) => assemblyType === 'human_centric'
	);

	const hcas = sortBy(_hcas, hca => hca.description);

	// categories by HCA sub-trees
	const categories: AssemblyCategoryMap[] = [];
	// unfiltered list of assemblies for mapping diagram to assembly (and all of the other mappings)
	const unfiltered: DisplayableAssembly[] = [];

	for (const hca of hcas) {
		// make sure to include the HCA if it's also a part, showing first before any sub assemblies
		const assemblies = !isNil(hca.part)
			? transformPartAssemblies([{ ...hca, subAssemblies: [] }])
			: [];
		assemblies.push(
			...sortBy(
				transformSubPartAssemblies(hca.subAssemblies),
				confidenceSortKey,
				alphabeticSortKey,
				stableSortKey
			)
		);
		const filtered = filterSearchAssemblies(assemblies, search);
		if (filtered.length > 0) {
			categories.push({
				category: { id: hca.id, name: hca.description },
				assemblies: filtered,
				diagrams: uniqBy(getAllDiagrams(hca), ({ id }) => id)
			});
		}
		unfiltered.push(...assemblies);
	}

	// finding relevant parts (normal flow, exclude parts in diagrams that are not in any of the HCAs)
	const relevantDiagrams = uniqBy(
		hcas.flatMap(hca => getAllDiagrams(hca)),
		({ id }) => id
	);
	const relevantParts = new Set(
		relevantDiagrams
			.flatMap(({ partSlots }) => partSlots.flatMap(({ parts }) => parts))
			.map(({ partIdentity }) => partIdentity)
			.filter(isDefined)
	);
	const [relevantAssemblies] = partition(
		sortBy(transformPartAssemblies(relevants), confidenceSortKey, alphabeticSortKey, stableSortKey),
		({ within }) => within.parts.some(id => relevantParts.has(id))
	);
	const filteredRelevantAssemblies = filterSearchAssemblies(relevantAssemblies, search);
	if (filteredRelevantAssemblies.length > 0) {
		categories.push({
			category: { id: 'relevant', name: 'Relevant Parts' },
			assemblies: filteredRelevantAssemblies,
			diagrams: relevantDiagrams
		});
	}
	unfiltered.push(...relevantAssemblies);

	// todo (remove later): hack to inject local emblem parts into the app
	if (withLocalContent) {
		const accessoryAssemblies = sortBy(
			transformPartAssemblies(accessories),
			confidenceSortKey,
			alphabeticSortKey,
			stableSortKey
		);
		const filtered = filterSearchAssemblies(accessoryAssemblies, search);
		if (filtered.length > 0) {
			categories.push({
				category: { id: 'accessories', name: 'Accessories' },
				assemblies: filtered,
				diagrams: []
			});
		}
		unfiltered.push(...accessoryAssemblies);
	}

	const { assemblyToCode, diagramPartSlotToCode, partToAssembly } = getPartSlotMappings(
		{ all: unfiltered, filtered: categories.flatMap(({ assemblies }) => assemblies) },
		relevantDiagrams
	);

	return categories.map(({ category, assemblies, diagrams }) => {
		return {
			category,
			assemblies: assemblies.map(assembly => mapPartSlotCodeToAssembly(assembly, assemblyToCode)),
			diagrams: sortBy(
				diagrams
					.map(diagram => mapAssembliesToDiagram(diagram, partToAssembly))
					.map(diagram => mapPartSlotCodeToDiagram(diagram, diagramPartSlotToCode))
					.filter(({ partSlots }) =>
						partSlots.some(({ assemblies }) => assemblies && assemblies.length > 0)
					),
				({ partSlots }) => min(partSlots.map(({ code }) => code)),
				({ code }) => code
			)
		};
	});
};

// Categories by diagrams
export const categoriesByDiagrams = ({ assemblies: _assemblies, search }: CategorisingArgs) => {
	const [hcas, relevants] = partition(
		_assemblies,
		({ assemblyType }) => assemblyType === 'human_centric'
	);

	// categories by HCA to diagrams
	const categories: DiagramCategoryMap[] = [];

	const assemblies = sortBy(
		uniqBy(
			[
				...transformSubPartAssemblies(hcas.flatMap(hca => hca.subAssemblies)),
				...transformPartAssemblies(relevants)
			],
			({ id }) => id
		),
		confidenceSortKey,
		alphabeticSortKey,
		stableSortKey
	);

	const diagrams = uniqBy(_assemblies.flatMap(getAllDiagrams), ({ id }) => id);

	for (const hca of getHumanCentricAssemblyDiagrams(diagrams)) {
		const diagrams = hca.diagrams
			.map(diagram => transformBrowsingDiagram(diagram, assemblies))
			.filter(isDefined)
			.filter(diagram => diagram.assemblies.length > 0);

		const filteredDiagrams = filterSearchDiagrams(diagrams, search);

		if (filteredDiagrams.length === 0) {
			continue;
		}

		categories.push({
			category: { id: hca.id, name: hca.description },
			diagrams: filteredDiagrams
		});
	}

	const { assemblyToCode, diagramPartSlotToCode, partToAssembly } = getPartSlotMappings(
		{
			all: assemblies,
			filtered: categories.flatMap(({ diagrams }) =>
				diagrams.flatMap(({ assemblies }) => assemblies)
			)
		},
		categories.flatMap(({ diagrams }) => diagrams)
	);

	return categories
		.map(({ category, diagrams }) => {
			return {
				category,
				diagrams: sortBy(
					diagrams
						.map(({ assemblies, ...diagram }) => {
							return {
								...mapPartSlotCodeToDiagram(
									mapAssembliesToDiagram(diagram, partToAssembly),
									diagramPartSlotToCode
								),
								assemblies: sortBy(
									assemblies.map(assembly => mapPartSlotCodeToAssembly(assembly, assemblyToCode)),
									confidenceSortKey,
									alphabeticSortKey,
									stableSortKey
								)
							};
						})
						.filter(({ partSlots }) =>
							partSlots.some(({ assemblies }) => assemblies && assemblies.length > 0)
						),
					({ partSlots }) => min(partSlots.map(({ code }) => code)),
					({ code }) => code
				)
			};
		})
		.filter(({ diagrams }) => diagrams.length > 0);
};

// All the assemblies either categorised by HCA or by diagrams
export const assembliesLookup = ({
	assemblies,
	withLocalContent: withLocalEmblem
}: CategorisingArgs) => {
	const filtered = categoriesByAssemblies({ assemblies, withLocalContent: withLocalEmblem })
		.flatMap(({ assemblies }) => assemblies)
		.flatMap(assembly => recursiveFlattenObject(assembly, ({ assemblies }) => assemblies));
	const all = categoriesByDiagrams({ assemblies, withLocalContent: withLocalEmblem })
		.flatMap(({ diagrams }) => diagrams.flatMap(({ assemblies }) => assemblies))
		.flatMap(assembly => recursiveFlattenObject(assembly, ({ assemblies }) => assemblies));
	return { filtered, all: uniqBy([...all, ...filtered], ({ id }) => id), diagrams: all };
};

// Add assembly id(s) into diagram part slot for easier lookup
const mapAssembliesToDiagram = (
	{ partSlots, ...rest }: DisplayableDiagram,
	mapping: Map<string, string[]>
): DisplayableDiagram => {
	return {
		partSlots: partSlots.map(({ id, parts, assemblies: directAssemblies, ...rest }) => {
			const visibleAssemblies = uniq(
				parts.flatMap(({ partIdentity }) => {
					if (!partIdentity) {
						return [];
					}
					return mapping.get(partIdentity) ?? [];
				})
			);
			const assemblies =
				directAssemblies.length > 0
					? directAssemblies.filter(id => visibleAssemblies.includes(id))
					: visibleAssemblies;
			return {
				id,
				assemblies,
				parts,
				directAssemblies,
				...rest
			};
		}),
		...rest
	};
};

// get all diagrams recursively in the assembly
const getAllDiagrams = (assembly: PartAssembly | PartSubAssembly): DisplayableDiagram[] => {
	const assemblies =
		'kind' in assembly
			? assembly.kind === 'single'
				? [assembly.assembly]
				: assembly.variants
			: [assembly];
	const diagrams = assemblies
		.flatMap(({ diagrams }) => diagrams ?? [])
		.map(diagram => ({
			...diagram,
			partSlots: diagram.partSlots.map(({ code, ...rest }) => ({
				...rest,
				code: undefined,
				oemCode: code
			}))
		}));
	return [
		...diagrams,
		...assemblies.flatMap(({ subAssemblies }) => subAssemblies).flatMap(getAllDiagrams)
	];
};

// convert job parts into form data (with matching assemblies)
export const createSelectionFromJobParts = (
	parts: JobPart[],
	allAssemblies: DisplayableAssembly[]
): PartInterpretationFormData => {
	const res = parts
		.flatMap(part => {
			const assemblies = part.assemblyIds
				.map(id => findAssemblyVariantById(id, allAssemblies))
				.filter(isDefined);

			const assembly = findAssemblyVariantByIdentity(part.partIdentity, allAssemblies);

			if (assemblies.length > 0) {
				const quantity = Math.floor(part.quantity / assemblies.length);
				return assemblies.map(({ id, description, partSlotIds }) =>
					createSelectionFromJobPart(id, { ...part, quantity }, description, id, partSlotIds)
				);
			}
			if (!isNil(assembly)) {
				return [
					createSelectionFromJobPart(
						assembly.id,
						part,
						assembly.description,
						assembly.id,
						assembly.partSlotIds
					)
				];
			}
			return [
				createSelectionFromJobPart(
					`custom-${part.gapcBrand?.id}-${part.mpn}`,
					part,
					part.description ?? 'Unknown part',
					null,
					null
				)
			];
		})
		.filter(isDefined);

	return Object.fromEntries(res);
};

const createSelectionFromJobPart = (
	id: string,
	part: JobPart,
	description: string,
	assemblyId: string | null,
	partSlotIds: PartSlotIds | null
): [string | null, PartInterpretationSelection] => {
	return [
		id,
		{
			...decodeGapcPartIdentityKey(part.partIdentity),
			quantity: part.quantity,
			description,
			partSlotIds,
			assemblyId
		}
	];
};

export const findAssemblyById = (
	id: string | null | undefined,
	assemblies: DisplayableAssembly[]
) =>
	assemblies.find(assembly =>
		assembly.kind === 'variants'
			? assembly.variants.some(variant => variant.id === id)
			: assembly.id === id
	);

const findAssemblyVariantById = (id: string, assemblies: DisplayableAssembly[]) => {
	for (const assembly of assemblies) {
		switch (assembly.kind) {
			case 'single': {
				if (assembly.id === id) {
					return assembly;
				}
				break;
			}
			case 'variants': {
				for (const variant of assembly.variants) {
					if (variant.id === id) {
						return variant;
					}
				}
				break;
			}
		}
	}

	return null;
};

const findAssemblyVariantByIdentity = (partIdentity: string, assemblies: DisplayableAssembly[]) => {
	for (const assembly of assemblies) {
		switch (assembly.kind) {
			case 'single': {
				if (assembly.part?.partIdentity === partIdentity) {
					return assembly;
				}
				break;
			}
			case 'variants': {
				for (const variant of assembly.variants) {
					if (variant.part.partIdentity === partIdentity) {
						return variant;
					}
				}
				break;
			}
		}
	}

	return null;
};

// recursively count assemblies added into the form data
export const countAddedAssemblies = (
	assemblies: DisplayableAssembly[],
	selection: PartInterpretationFormData
): number =>
	sum(
		assemblies.map(assembly => {
			if (assembly.kind === 'single') {
				const count = assembly.part && (selection[assembly.id]?.quantity ?? 0) > 0 ? 1 : 0;

				return count + countAddedAssemblies(assembly.assemblies, selection);
			}
			const count = assembly.variants.filter(({ id }) => (selection[id]?.quantity ?? 0) > 0).length;
			return count + countAddedAssemblies(assembly.assemblies, selection);
		})
	);

import { EmptyState } from '@/app/atoms/empty-state';
import DeleteJobDialog from '@/app/features/jobs/components/delete-job-dialog';
import JobSearchBar from '@/app/features/jobs/components/job-search-bar';
import JobTable from '@/app/features/jobs/components/job-table';
import JobTableSkeleton from '@/app/features/jobs/components/job-table-skeleton';
import { withSignedIn } from '@/app/hoc/with-access';
import { useSearchQuery } from '@/app/hooks/use-search-param';
import { tlsx } from '@/app/utils/tw-merge';
import { ReactComponent as VehicleIcon } from '@assets/vehicle.svg';
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/24/solid';
import { Button, Menu } from '@mantine/core';
import { Job, SearchJobsRequest, createApi } from '@sdk/lib';
import { getConfig, jobsQueries } from '@sdk/react';
import { PageTemplate } from '@templates/page-template';
import { useMemo, useState } from 'react';

import { signOut } from '@/app/utils/auth';
import { useAnalytics } from '@hooks/use-analytics';
import { useInfiniteQuery } from '@tanstack/react-query';
import { AuthEvents } from '@utils/analytics/events.ts';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

const JobsPage = () => {
	const analytics = useAnalytics();
	const keyword = useSearchQuery('keyword');
	const [debouncedKeyword] = useDebounce(keyword.values[0]?.trim() ?? '', 250);
	const [jobToDelete, setJobToDelete] = useState<Job>();

	const payload: SearchJobsRequest = {
		keyword: debouncedKeyword || undefined
	};
	const { queryKey } = jobsQueries.search(payload);
	const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
		queryKey,
		queryFn: async ({ pageParam: cursor }: { pageParam: string | null }) => {
			const config = getConfig();
			const { data, error } = await createApi(config).jobs.search({ cursor, ...payload });
			if (error) {
				throw error;
			}
			return data;
		},
		initialPageParam: null,

		// must return undefined so react query can know if there's no next/previous page
		getNextPageParam: lastPage => lastPage.pagination.nextCursor ?? undefined,
		getPreviousPageParam: firstPage => firstPage.pagination.previousCursor ?? undefined
	});

	const signOutUserWithEvent = () => {
		analytics.logEvent(AuthEvents.logout({}));
		signOut();
	};

	const jobs = useMemo(
		() =>
			data?.pages.flatMap(({ jobs }) => jobs).filter(({ status }) => status !== 'Deleted') ?? [],
		[data]
	);
	const hasJobs = jobs.length > 0;

	return (
		<PageTemplate className={tlsx('p-0', { 'justify-center items-center': !hasJobs })}>
			<PageTemplate.Header className="p-0" borderOnScroll>
				<nav className="flex items-center justify-between w-full px-8 py-4">
					<Menu shadow="md" width="12rem" position="bottom-start">
						<Menu.Target>
							<Button variant="white" data-testid="user-menu">
								<h1 className="mr-3 text-lg font-semibold text-black">Wairau Valley</h1>
								<ChevronDownIcon className="w-4 h-4 text-black" />
							</Button>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item data-testid="user-logout" color="red" onClick={signOutUserWithEvent}>
								Log out
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
					<JobSearchBar className="w-full md:max-w-md lg:max-w-lg" />
					<Button
						component={Link}
						to="/jobs/create"
						className="text-sm font-medium"
						data-testid="job-create"
						leftIcon={<PlusIcon className="w-5 h-5 text-white" />}
					>
						New Job
					</Button>
				</nav>
			</PageTemplate.Header>
			{hasJobs ? (
				<JobTable
					jobs={jobs}
					hasNextPage={hasNextPage}
					isNextPageLoading={isFetchingNextPage}
					loadNextPage={fetchNextPage}
					onDelete={setJobToDelete}
				/>
			) : isLoading ? (
				<JobTableSkeleton />
			) : (
				<EmptyState>
					<EmptyState.Icon as={VehicleIcon} />
					<EmptyState.Title>No jobs found</EmptyState.Title>
					<EmptyState.Description>
						Start a job by tapping the plus '+' button and adding the vehicle's license plate.
					</EmptyState.Description>
				</EmptyState>
			)}
			<DeleteJobDialog job={jobToDelete} onClose={() => setJobToDelete(undefined)} />
		</PageTemplate>
	);
};

export default withSignedIn(JobsPage);

import { useJobCompletion } from '@/app/features/job/hooks/use-job-completion';
import { withSignedIn } from '@/app/hoc/with-access';
import { InheritableElementProps } from '@/types/utilties';
import { ReactComponent as CarIcon } from '@assets/car.svg';
import { HomeIcon, ShoppingCartIcon, StarIcon } from '@heroicons/react/24/outline';
import { Button, Tabs } from '@mantine/core';
import { Job } from '@sdk/lib';
import { jobsQueries } from '@sdk/react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { PageTemplate } from '@templates/page-template';
import { PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

type PageProps = {
	jobId: string;
};

const PORTAL_ID = 'job-actions';

const JobDetailPage = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { jobId } = useParams<PageProps>();
	if (!jobId) {
		throw new Error('Missing required jobId parameter');
	}

	// const roomKey = createEsimatorJobPresenceRoomKey(jobId);
	// const presence = usePresence<EstimatorJobPresence>(roomKey, {
	//   job_id: jobId,
	//   user: {
	//     first_name: "Eli",
	//     surname: "Labes",
	//   },
	// });

	const { data } = useSuspenseQuery(jobsQueries.get({ jobId }));
	const { hasCollisionMap, hasAddedParts } = useJobCompletion(data.job);

	return (
		<PageTemplate>
			<PageTemplate.Header className="grid items-center grid-cols-3 gap-4">
				<div className="flex items-center justify-start gap-4">
					<Button
						className="font-semibold"
						leftIcon={<HomeIcon className="w-5 h-5" />}
						variant="default"
						component={Link}
						to={`/job/${jobId}`}
					>
						Back to Job
					</Button>
					<VehicleDetails className="min-w-0" job={data.job} />
				</div>
				<div className="flex justify-center min-w-max">
					<Tabs value={pathname} onTabChange={value => navigate(value ?? '/')}>
						<Tabs.List className="font-semibold">
							<Tabs.Tab
								className="px-6 py-3"
								value={`/job/${jobId}/collision`}
								icon={<CarIcon className="w-5 h-5" />}
							>
								Collision
							</Tabs.Tab>
							<Tabs.Tab
								className="px-6 py-3"
								value={`/job/${jobId}/parts`}
								icon={<StarIcon className="w-5 h-5" />}
								disabled={!hasCollisionMap}
							>
								Parts
							</Tabs.Tab>
							<Tabs.Tab
								className="px-6 py-3"
								value={`/job/${jobId}/supply`}
								icon={<ShoppingCartIcon className="w-5 h-5" />}
								disabled={!hasAddedParts}
							>
								Supply
							</Tabs.Tab>
						</Tabs.List>
					</Tabs>
				</div>
				<div className="flex justify-end empty:hidden" id={PORTAL_ID} />
			</PageTemplate.Header>
			<Outlet />
		</PageTemplate>
	);
};

const VehicleDetails = ({ job, ...rest }: InheritableElementProps<'dl', { job: Job }>) => (
	<dl {...rest}>
		<dt className="sr-only">Vehicle name</dt>
		<dd className="text-sm font-semibold leading-4 text-gray-900">
			{job.vehicle?.variant?.description}
		</dd>
		<dt className="sr-only">Plate / Chassis number</dt>
		<dd className="mt-0.5 text-sm leading-4 text-gray-500 truncate">
			{job.jobNumber} - {(job.vehicle?.plateNumber ?? job.vehicle?.chassisNumber)?.toUpperCase()}
		</dd>
	</dl>
);

export const JobActions = ({ children }: PropsWithChildren) => {
	const [isMounted, setIsMounted] = useState(false);
	// We need to wait for the component to be mounted
	// before rendering the portal, as in this case, the portal
	// id is a portal itself.
	useEffect(() => {
		setIsMounted(true);
	}, []);

	if (!isMounted) {
		return null;
	}

	const element = document.getElementById(PORTAL_ID);
	if (!element) {
		return null;
	}

	return createPortal(children, element);
};

export default withSignedIn(JobDetailPage);

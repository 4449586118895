import { InheritableElementProps } from '@/types/utilties';
import { Button, ButtonProps } from '@mantine/core';

export type SaveButtonProps = InheritableElementProps<
	'button',
	ButtonProps & {
		submitting?: boolean;
	}
>;

const SaveButton = ({ disabled, submitting, ...rest }: SaveButtonProps) => {
	return (
		<Button
			aria-disabled={disabled || submitting ? 'true' : undefined}
			// data-disabled disable the button behavour without actually setting the disabled attribute
			// must use undefined because button checks if data-disabled is present regardless of value
			data-disabled={disabled || submitting ? 'true' : undefined}
			{...rest}
		>
			{submitting ? 'Saved' : 'Save'}
		</Button>
	);
};

export default SaveButton;

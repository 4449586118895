import { useMemo } from 'react';
import { formatSelectionContextReflect } from '@/app/utils/part';
import { DraftOrderItemModel } from '../../models';
import { OrderRequestTableItem } from '../../table-builder';

export const useOrderRequestTableItems = (modelItems: DraftOrderItemModel[]) => {
	const items = useMemo<OrderRequestTableItem[]>(() => {
		return modelItems.map(item => {
			let name = '';
			if (item.buyable.type === 'External') {
				name = item.buyable.description;
			} else {
				name = formatSelectionContextReflect(item.context);
			}

			return {
				id: item.local_id,
				quantity: item.quantity,
				grade: item.grade,
				status: item.status,
				order_separately: item.order_separately,
				arrival_at: item.arrival_at,
				price: item.price,
				name
			};
		});
	}, [modelItems]);

	const subtotal = useMemo(() => {
		return items.reduce((acc, item) => {
			if (item.status && typeof item.status === 'object' && 'Rejected' in item.status) {
				return acc;
			}

			return acc + (item.price ?? 0) * item.quantity;
		}, 0);
	}, [items]);

	return { items, subtotal };
};

import CreateJobForm, {
	CreateJobFormData
} from '@/app/features/vehicle/components/create-job-form';
import { useAnalytics } from '@/app/hooks/use-analytics';
import { RepairhubEvents } from '@/app/utils/analytics/events';
import { linkWithSearchParams } from '@/app/utils/url';
import { Alert } from '@mantine/core';
import { ApiError, SearchVehiclePayload, StorefrontError } from '@sdk/lib';
import { jobsMutations } from '@sdk/react';
import { useMutation } from '@tanstack/react-query';
import { isDefined } from '@utils/common';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const DELETED_JOB_ERROR = 'Cannot add events to jobs that are deleted';
const errMessages = {
	deletedJob: {
		label: 'Deleted job',
		message: 'Cannot modify deleted job'
	},
	existingJob: {
		label: 'Duplicate job number',
		message: 'Job number or claim number has been used for an existing job'
	}
};

const getErrMessage = (error: StorefrontError): { label: string; message: string } => {
	const { body } = error as ApiError;

	return body?.message === DELETED_JOB_ERROR ? errMessages.deletedJob : errMessages.existingJob;
};

const CreateJobPage = () => {
	const navigate = useNavigate();
	const { mutateAsync: upsertJob, error } = useMutation(jobsMutations.upsert);
	const { logEvent } = useAnalytics();

	const [searchParams] = useSearchParams();
	const jobId = searchParams.get('jobId');
	const jobNumber = searchParams.get('jobNumber');
	const claimNumber = searchParams.get('claimNumber');

	const errMsg = useMemo(() => (isDefined(error) ? getErrMessage(error) : null), [error]);

	const onSubmit = async ({
		jobNumber,
		searchType,
		chassis,
		plateNumber,
		claimNumber
	}: CreateJobFormData) => {
		const { job } = await upsertJob({ jobId: jobId ?? undefined, jobNumber, claimNumber });

		logEvent(RepairhubEvents.job_upserted({ job_id: job.id, job_number: jobNumber }));

		const vehicleSearchParams: SearchVehiclePayload =
			searchType === 'rego'
				? {
						vrm: { plateNumber: plateNumber ?? '' }
					}
				: {
						chassisNumber: chassis ?? ''
					};

		const link = linkWithSearchParams(`/jobs/create/vehicles`, {
			jobId: job.id,
			jobNumber,
			claimNumber
		});

		navigate(link, { state: { vehicleSearchParams } });
	};

	return (
		<>
			<CreateJobForm
				initialJobNumber={jobNumber ?? undefined}
				initialClaimNumber={claimNumber ?? undefined}
				onSubmit={onSubmit}
			/>
			{error instanceof Error && isDefined(errMsg) && (
				<Alert color="red" className="mt-4" title={errMsg.label}>
					{errMsg.message}
				</Alert>
			)}
		</>
	);
};

export default CreateJobPage;

import { isDefined } from '@/app/utils/common';
import { camelCaseIntoSentence } from '@/app/utils/string';
import { GapcAssemblyPartFitment, PartAssembly } from '@sdk/lib';
import { groupBy, intersection, isNil, mapValues, sortBy, uniqBy } from 'lodash-es';

const EXCLUDED = [
	'Required quantity',
	'Production month from',
	'Production month to',
	'Production year from',
	'Production year to'
];

export const mapFitmentDifference = (variants: PartAssembly[]): PartAssembly[] => {
	// check for differences in fitments and attributes
	const fitmentVariantPairs = mapValues(
		groupBy(
			variants.flatMap(variant =>
				variant.fitment ? variant.fitment.map(fitment => [fitment, variant] as const) : []
			),
			([fitment]) => fitment.type
		),
		pairs => uniqBy(pairs, ([fitment]) => fitment.value).length > 1
	);

	const attributeVariantPairs = mapValues(
		groupBy(
			variants.flatMap(variant =>
				variant.attributes ? variant.attributes.map(attribute => [attribute, variant] as const) : []
			),
			([attribute]) => attribute.type
		),
		pairs => uniqBy(pairs, ([attribute]) => attribute.value).length > 1
	);

	return sortBy(
		variants.map(({ fitment, attributes, ...rest }) => {
			const newFitment = transformNotes(
				fitment?.filter(fitment => fitmentVariantPairs[fitment.type])
			);
			const newAttributes = transformNotes(
				attributes?.filter(attribute => attributeVariantPairs[attribute.type])
			);
			return {
				fitment: newFitment,
				attributes: newAttributes,
				...rest
			};
		}),
		variantConfidenceSortKey,
		variantAlphabeticalSortKey,
		variantIdSortKey
	);
};

export const transformNotes = (notes: GapcAssemblyPartFitment[] | null | undefined) =>
	notes
		?.map(({ type, ...rest }) => ({ type: camelCaseIntoSentence(type), ...rest }))
		?.filter(({ type }) => !EXCLUDED.includes(type));

export const partitionVariants = (variants: PartAssembly[]) => {
	const partitions: PartAssembly[][] = [];
	outer: for (const variant of variants) {
		for (const partition of partitions) {
			if (partition.some(other => sharingDiagramLocation(variant, other))) {
				partition.push(variant);
				continue outer;
			}
		}
		partitions.push([variant]);
	}
	return partitions;
};

export const isSharingDiagramPartSlot = ({ part, diagrams }: PartAssembly) =>
	!isNil(part) &&
	!isNil(diagrams) &&
	diagrams
		.flatMap(({ partSlots }) => partSlots)
		.some(({ parts }) => {
			const partIds = parts.map(({ partIdentity }) => partIdentity).filter(isDefined);
			return (
				partIds.includes(part.partIdentity) &&
				partIds.filter(partId => part.partIdentity !== partId).length > 0
			);
		});

const sharingDiagramLocation = (assembly: PartAssembly, other: PartAssembly) =>
	intersection(
		getDiagramPartSlotCodes(assembly).map(({ id }) => id),
		getDiagramPartSlotCodes(other).map(({ id }) => id)
	).length > 0;

const getDiagramPartSlotCodes = (assembly: PartAssembly) => {
	if (isNil(assembly.part)) {
		return [];
	}
	return (
		assembly.diagrams
			?.flatMap(({ partSlots }) => partSlots)
			.filter(({ parts }) =>
				parts.some(({ partIdentity }) => partIdentity === assembly.part?.partIdentity)
			) ?? []
	);
};

const variantConfidenceSortKey = (variant: PartAssembly) => {
	return -(variant.confidence ?? 0);
};

const variantAlphabeticalSortKey = (variant: PartAssembly) => {
	return variant.description;
};

const variantIdSortKey = (variant: PartAssembly) => {
	return variant.id;
};

import { queries } from '@/sdk/react/queries';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { draft_order } from '@/sdk/reflect/reflect';
import { getLatestDate, restoreOrderRequestModel } from '../../order-request';
import { DraftOrderSelection } from '../../models';

export const useBackgroundDraftOrderRefetch = (
	jobId: string,
	form: UseFormReturn<DraftOrderSelection>
) => {
	const selection = form.watch();

	const isProcessing = (status: draft_order.DraftOrderStatus): boolean => {
		return typeof status === 'object' && 'Processing' in status;
	};

	// Work out if we need to background refetch, transitions
	// processing orders in processed.
	const isEnabled = useMemo(() => {
		return Object.values(selection.draft_orders).some(item => isProcessing(item.status));
	}, [selection]);

	const { data } = useQuery({
		...queries.draft_orders.list({ job_id: jobId }),
		enabled: isEnabled,
		refetchInterval: 30_000
	});

	useEffect(() => {
		const processedOrders = data?.draft_orders.filter(order => order.status === 'Processed');
		const dates: Date[] = [];
		for (const order of processedOrders ?? []) {
			// We only want to update items that are currently stored
			// as processing.
			if (!isProcessing(selection.draft_orders[order.id].status)) {
				continue;
			}

			for (const item of order.items) {
				dates.push(item.arrival_at ? new Date(item.arrival_at) : new Date());
			}

			const model = restoreOrderRequestModel(order);
			form.setValue(`draft_orders.${order.id}`, model);
		}

		// We need to update the latest delivery date
		// from the processed orders.
		if (dates.length) {
			const latestDate = getLatestDate(dates);
			form.setValue('delivery_date', latestDate);
		}
	}, [data]);
};

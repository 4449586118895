import { InheritableElementProps } from '@/types/utilties';
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ActionIcon, TextInput } from '@mantine/core';
import { useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { DisplayableAssembly } from '../../types';
import { getSearchSuggestions } from '../../utils/search';

type PartInterpretationSearchProps = InheritableElementProps<
	typeof TextInput,
	{
		assemblies: DisplayableAssembly[];
		selected?: string | null;
		onChange: (value: string | null) => void;
	}
>;

export const PartInterpretationSearch = ({
	assemblies,
	onChange,
	selected,
	...rest
}: PartInterpretationSearchProps) => {
	const [query, setQuery] = useState('');
	const [debouncedQuery] = useDebounce(query, 300);

	const { aliases, terms } = useMemo(
		() => getSearchSuggestions(assemblies, debouncedQuery),
		[debouncedQuery, assemblies]
	);

	const onSelect = (value: string | null) => onChange(value);

	const onClose = () => setQuery('');

	const onClear = () => {
		setQuery('');
		onChange(null);
	};

	return (
		<Combobox value={selected ?? ''} onChange={onSelect} onClose={onClose}>
			<ComboboxInput
				as={TextInput}
				className="w-full"
				placeholder="Search parts"
				type="search"
				autoComplete="off"
				data-testid="parts-search"
				icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500" />}
				displayValue={(value: string | null) => value ?? ''}
				rightSection={
					selected ? (
						<ActionIcon className="mr-2" variant="transparent" type="button" onClick={onClear}>
							<XMarkIcon className="w-4 h-4" />
						</ActionIcon>
					) : undefined
				}
				onChange={event => setQuery(event.target.value.trim())}
				{...rest}
			/>

			<ComboboxOptions
				anchor="bottom"
				className="w-[var(--input-width)] rounded-xl border py-2 mt-2 empty:invisible bg-white z-20 transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
			>
				{query && (
					<ComboboxOption
						key="query"
						value={query}
						className="flex cursor-default items-center py-1.5 px-4 select-none data-[focus]:bg-gray-400/10"
						data-testid={`parts-search-suggestion-${query}`}
					>
						<div className="text-sm text-gray-700">{query}</div>
					</ComboboxOption>
				)}

				{aliases.map(({ description: keyword }) => {
					return (
						<ComboboxOption
							key={keyword}
							value={keyword}
							className="flex cursor-default items-center py-1.5 px-4 select-none data-[focus]:bg-gray-400/10"
							data-testid={`parts-search-suggestion-${keyword}`}
						>
							<div className="text-sm text-gray-700 capitalize">{keyword}</div>
						</ComboboxOption>
					);
				})}

				{terms.length > 0 && (
					<div className="flex flex-col pt-4">
						<div className="text-xs font-semibold text-gray-600 px-4 py-2">Related assemblies</div>
						{terms.map(({ id, description }) => (
							<ComboboxOption
								key={id}
								value={description}
								className="flex cursor-default items-center py-1.5 px-4 select-none data-[focus]:bg-gray-400/10"
								data-testid={`parts-search-suggestion-${description}`}
							>
								<div className="text-sm text-gray-700 capitalize">{description}</div>
							</ComboboxOption>
						))}
					</div>
				)}
			</ComboboxOptions>
		</Combobox>
	);
};

import { createUseGesture, dragAction, pinchAction } from '@use-gesture/react';
import { useMotionValue } from 'framer-motion';
import { useRef } from 'react';
import { useDisableDefaultGestures } from '../use-disable-default-gestures';

const useGesture = createUseGesture([dragAction, pinchAction]);

type UseImagePanArgs = {
	initialScale?: number;
	scaleBounds?: {
		min: number;
		max: number;
	};
};

export const useImagePan = <Element extends HTMLElement = HTMLDivElement>({
	initialScale,
	scaleBounds
}: UseImagePanArgs) => {
	const containerRef = useRef<Element>(null);

	const x = useMotionValue(0);
	const y = useMotionValue(0);
	const scale = useMotionValue(initialScale ?? 1);

	useDisableDefaultGestures();

	useGesture(
		{
			onDrag: ({ pinching, cancel, offset: [newX, newY] }) => {
				if (pinching) {
					return cancel();
				}
				x.set(newX);
				y.set(newY);
			},
			onPinch: ({ origin: [ox, oy], first, movement: [ms], offset: [s], memo }) => {
				if (first && containerRef.current) {
					const { width, height, x: newX, y: newY } = containerRef.current.getBoundingClientRect();
					const tx = ox - (newX + width / 2);
					const ty = oy - (newY + height / 2);
					memo = [x.get(), y.get(), tx, ty];
				}

				x.set(memo[0] - (ms - 1) * memo[2]);
				y.set(memo[1] - (ms - 1) * memo[3]);
				scale.set(s);
				return memo;
			}
		},
		{
			target: containerRef,
			drag: { from: () => [x.get(), y.get()] },
			pinch: { scaleBounds: scaleBounds ?? { min: 0.25, max: 4 }, rubberband: true }
		}
	);

	return {
		x,
		y,
		scale,
		ref: containerRef
	};
};

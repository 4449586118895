import { GapcDiagramSegment } from '@/sdk/lib';
import { sortBy } from 'lodash-es';
import {
	DisplayableDiagram,
	DisplayableDiagramPartSlotGraphics,
	DisplayableDiagramSegmentedPartSlot
} from '../types';

const collisionHcaToDiagrams = [
	{
		id: 11,
		name: 'Front Bumper',
		diagram: {
			codes: [
				'5201',
				'7652',
				'5151',
				'5152',
				'5252',
				'5351',
				'5352',
				'5353',
				'5354',
				'5355',
				'7554',
				'7501',
				'7551',
				'8103',
				'8711',
				'8715',
				'8417',
				'8408',
				'8104',
				'8102',
				'8609'
			],
			names: [
				'M Trim, front',
				'M Performance aerodynamics acc.parts',
				'Air Ducts',
				'Carrier, front',
				'M Trim decor elements, front',
				'installation kit, bumper',
				'Wheelhouse/engine support',
				'Front body bracket',
				'Single parts for head lamp cleaning',
				'Reserv.,windshield/headlight washer syst',
				'Fog lights',
				'Reflector',
				'Lamps',
				'Electric parts airbag',
				'temperature sensor',
				'Park distance control',
				'various notice stickers',
				'Fan housing with fan',
				'Headlight vertical aim control sensor',
				'Emblems / letterings',
				'Exterior trim / grill',
				'Sound insulation'
			]
		}
	},
	{
		id: 6,
		name: 'Grille',
		diagram: {
			codes: ['5201', '5252', '5301', '5351', '7501', '7551'],
			names: ['M Performance aerodynamics acc.parts', 'Exterior trim / grill']
		}
	},
	{
		id: 13,
		name: 'Headlight Left',
		diagram: {
			codes: ['8505', '8101', '8103', '8104'],
			names: [
				'Headlight vertical aim control sensor',
				'Repair sections for hoses',
				'Reserv.,windshield/headlight washer syst',
				'Single parts for head lamp cleaning',
				'Lamps',
				'BMW Performance Light',
				'Headlight',
				'Individual parts for headlamp, halogen',
				'Single parts, xenon headlight',
				'Spare bulbs box'
			]
		}
	},
	{
		id: 14,
		name: 'Headlight Right',
		diagram: {
			codes: ['8505', '8101', '8103', '8104'],
			names: ['Headlight vertical aim control sensor']
		}
	},
	{
		id: 15,
		name: 'Front Body Panel',
		diagram: {
			codes: [
				'1603',
				'5352',
				'8611',
				'8719',
				'8715',
				'8718',
				'8714',
				'8712',
				'8711',
				'8717',
				'8716'
			],
			names: [
				'Cooling system - coolant pump/thermostat',
				'Coolant radiator mounting hardware',
				'Fan housing with fan',
				'Expansion tank',
				'Cooling system - Engine coolant hoses',
				'Cooling system coolant hoses',
				'Trans. oil cooler line / heat exchanger',
				'Sound insulation',
				'Front panel',
				'Reinforcement for body, front',
				'Repair cable, airbag',
				'Electric parts airbag',
				'Horn',
				'Cooling water hoses',
				'Evaporator / Expansion valve',
				'Condenser air conditioning',
				'Coolant lines'
			]
		}
	},
	{
		id: 5,
		name: 'Bonnet',
		diagram: {
			codes: ['5302', '5353', '5303', '5354'],
			names: [
				'M Performance aerodynamics acc.parts',
				'M Performance front flap, carbon  - F87',
				'Engine hood/mounting parts',
				'Front hatch / locking system',
				'Hood seals',
				'Emblems / letterings',
				'Underhood shield',
				'Sound insulation'
			]
		}
	},
	{
		id: 21,
		name: 'Front Fender Left',
		diagram: {
			codes: ['7507', '7553', '5302', '5353', '6103'],
			names: [
				'M Performance aerodynamics acc.parts',
				'Front side panel',
				'Wheelhouse/engine support',
				'Front body bracket left',
				'Cover door sill / wheel arch',
				'Emblems / letterings',
				'Wheelarch trim',
				'Reinforcement for body, front'
			]
		}
	},
	{
		id: 22,
		name: 'Front Fender Right',
		diagram: { codes: ['7507', '7553', '5302', '5353', '6103'], names: [] }
	},
	{
		id: 33,
		name: 'Cowl',
		diagram: {
			codes: [],
			names: [
				'Splash wall parts',
				'Trim, windshield cowl, outer',
				'Reinforcement for body, front',
				'Front panel'
			]
		}
	},
	{
		id: 34,
		name: 'Windshield',
		diagram: {
			codes: [],
			names: [
				'BMW Advanced Car Eye',
				'BMW Advanced Car Eye 2',
				'BMW Advanced Car Eye 2.0',
				'Int.r.v.mir.w/.integrated gar.dr.opener',
				'Glazing',
				'Covers for interior rearview mirror',
				'Interior mirror',
				'Sensor rain/light/solar/condensation',
				'Set of wiper blades',
				'Single wiper parts',
				'Single parts for windshield cleaning',
				'Reserv.,windshield/headlight washer syst',
				'KaFAS camera'
			]
		}
	},
	{
		id: 16,
		name: 'Steering Column',
		diagram: {
			codes: ['4501', '4504'],
			names: [
				'M Performance steer. wheel II w/ display',
				'M Performance steering wheel w/ display',
				'M Performance steering wheel w/o display',
				'M Performance steering wl II w/o display',
				'M sports strng whl,airbag,multifunction',
				'Security kit',
				'Control unit,steering wheel mod.,M-Sport',
				'Radio remote control receiver',
				'Control unit lights',
				'Shift paddles, Sport automatic trans.',
				'Switch, steering wheel heater',
				'Switch, strng wheel, multifunction Sport',
				'Switch,st. wheel, multifunction M-Sport',
				'Switch, Start-Stop and ring antenna',
				'Switch unit steering column',
				'Horn',
				'Airbag'
			]
		}
	},
	{
		id: 1,
		name: 'Front Door Left',
		diagram: {
			codes: [
				'7401',
				'7404',
				'7451',
				'7456',
				'7403',
				'6701',
				'6751',
				'6703',
				'6753',
				'6752',
				'6704',
				'6754',
				'6401',
				'6712',
				'6765',
				'7552',
				'6451',
				'7503',
				'8602',
				'8121',
				'8420',
				'8202',
				'8401'
			],
			names: [
				'M Performance aerodynamics acc.parts',
				'M Performance interior trim covers',
				'Front door-hinge/door brake',
				'Master key locking',
				'Radio remote control',
				'Glazing',
				'Door window lifting mechanism front',
				'Locking system, door, front',
				'Trim and seals for door, front',
				'Cover door sill / wheel arch',
				'Emblems / letterings',
				'Door trim panel',
				'Door cable harnesses',
				'Window lifter switch',
				'Switch,power window passenger side/rear',
				'Single parts f front door loudspeaker',
				'Audio wiring harness, duplicate',
				'Electric parts airbag',
				'Airbag'
			]
		}
	},
	{
		id: 2,
		name: 'Front Door Right',
		diagram: {
			codes: [
				'7401',
				'7404',
				'7451',
				'7456',
				'7403',
				'6701',
				'6751',
				'6703',
				'6753',
				'6752',
				'6704',
				'6754',
				'6401',
				'6712',
				'6765',
				'7552',
				'6451',
				'7503',
				'8602',
				'8121',
				'8420',
				'8202',
				'8401'
			],
			names: []
		}
	},
	{
		id: 19,
		name: 'Wing Mirror Left',
		diagram: {
			codes: ['8701', '8106'],
			names: [
				'M Performance exterior mirror flaps',
				'Exterior mirror',
				'Mirror glass',
				'Various lamps'
			]
		}
	},
	{ id: 20, name: 'Wing Mirror Right', diagram: { codes: ['8701', '8106'], names: [] } },
	{ id: 3, name: 'Rear Door Left', diagram: { codes: [], names: ['Lateral trim panel rear'] } },
	{ id: 4, name: 'Rear Door Right', diagram: { codes: [], names: [] } },
	{
		id: 17,
		name: 'Rear Quarter Panel Left',
		diagram: {
			codes: [],
			names: [
				'Fill-in flap',
				'Side panel/tail trim',
				'Cover door sill / wheel arch',
				'Wheelarch trim',
				'Notice label for fuel'
			]
		}
	},
	{ id: 18, name: 'Rear Quarter Panel Right', diagram: { codes: [], names: [] } },
	{
		id: 10,
		name: 'Tailgate',
		diagram: {
			codes: [
				'6553',
				'6706',
				'6756',
				'6705',
				'6755',
				'6707',
				'6757',
				'7155',
				'6709',
				'6762',
				'6708',
				'6761',
				'7501',
				'7551',
				'6712',
				'6765',
				'7504',
				'8117',
				'8111',
				'8113',
				'8504',
				'8502',
				'8115'
			],
			names: ['M Performance aerodynamics acc.parts', 'Emblems / letterings']
		}
	},
	{
		id: 12,
		name: 'Trunk Lid',
		diagram: {
			codes: [],
			names: [
				'M Performance aerodynamics acc.parts',
				'Mounting parts for trunk floor panel',
				'Tailgate closing system',
				'Trunk lid',
				'Emblems / letterings',
				'Left trunk trim',
				'Right TRUNK TRIM',
				'Trim panel, trunk floor',
				'Trunk lid trim panel',
				'Third stoplamp',
				'License plate lamp, LED',
				'Reversing camera'
			]
		}
	},
	{
		id: 35,
		name: 'Back Glass',
		diagram: { codes: [], names: ['Glazing', 'Third stoplamp', 'Reversing camera'] }
	},
	{
		id: 7,
		name: 'Tail Light Left',
		diagram: { codes: ['8111'], names: ['Rear light', 'Reflector'] }
	},
	{ id: 8, name: 'Tail Light Right', diagram: { codes: ['8111'], names: [] } },
	{
		id: 9,
		name: 'Rear Bumper',
		diagram: {
			codes: ['7601', '5202', '5253', '7652', '8112', '8417', '8116', '8113', '8115'],
			names: [
				'M Performance aerodynamics acc.parts',
				'Carrier, rear',
				'M Trim, rear',
				'Emblems / letterings',
				'Reversing camera',
				'Installation kit, bumper'
			]
		}
	}
];

export const getHumanCentricAssemblyDiagrams = (allDiagrams: DisplayableDiagram[]) => {
	const categorised = new Set<DisplayableDiagram['id']>();
	const hcas = sortBy(
		collisionHcaToDiagrams
			.map(({ diagram, id, name }) => {
				const diagrams = allDiagrams.filter(
					({ name, code }) => diagram.codes.includes(code) || diagram.names.includes(name)
				);
				diagrams.forEach(({ id }) => categorised.add(id));
				return {
					id: `${id}`,
					description: name,
					diagrams
				};
			})
			.filter(({ diagrams }) => diagrams.length > 0),
		hca => hca.description
	);

	const others = {
		id: 'other',
		description: 'Other Diagrams',
		diagrams: allDiagrams.filter(({ id }) => !categorised.has(id))
	};

	if (others.diagrams.length === 0) {
		return hcas;
	}

	return [...hcas, others];
};

export const transformDiagramGraphics = (
	partSlots: DisplayableDiagramSegmentedPartSlot[]
): DisplayableDiagramPartSlotGraphics[] => {
	const images = partSlots.flatMap(
		partSlot =>
			partSlot.images?.map(
				(image): DisplayableDiagramPartSlotGraphics => ({
					kind: 'segment',
					href: image.src,
					x: image.x1,
					y: image.y1,
					width: image.x2 - image.x1,
					height: image.y2 - image.y1,
					partSlot
				})
			) ?? []
	);

	const hotspots = partSlots.flatMap(partSlot =>
		partSlot.hotspots.map(
			(hotspot): DisplayableDiagramPartSlotGraphics => ({
				kind: 'hotspot',
				x: hotspot.x1Px,
				y: hotspot.y1Px,
				width: hotspot.x2Px - hotspot.x1Px,
				height: hotspot.y2Px - hotspot.y1Px,
				partSlot
			})
		)
	);

	const polygons = sortBy(
		partSlots.flatMap(partSlot => partSlot.segments.map(segment => [segment, partSlot] as const)),
		([segment]) => -segmentArea(segment)
	).map(
		([segment, partSlot]): DisplayableDiagramPartSlotGraphics => ({
			kind: 'polygon',
			d: segmentVectorPath(segment),
			partSlot
		})
	);

	// Sort graphics on the diagram by:
	// - Segmented image at the back
	// - Hotspot boxes on top
	// - Polygons on top sorted by size (largest first)
	return [...images, ...hotspots, ...polygons];
};

const segmentVectorPath = ({ polygons }: GapcDiagramSegment) => {
	return `${polygons.reduce(
		(path, { x, y }, index) => `${path}${index === 0 ? 'M' : 'L'} ${x} ${y} `,
		''
	)}Z`;
};

const segmentArea = ({ polygons }: GapcDiagramSegment) => {
	const x1 = Math.min(...polygons.map(({ x }) => x));
	const x2 = Math.max(...polygons.map(({ x }) => x));
	const y1 = Math.min(...polygons.map(({ y }) => y));
	const y2 = Math.max(...polygons.map(({ y }) => y));

	return (x2 - x1) * (y2 - y1);
};

import { useEffect } from 'react';

export const useDisableDefaultGestures = () =>
	useEffect(() => {
		const handler = (e: Event) => e.preventDefault();
		document.addEventListener('gesturestart', handler);
		document.addEventListener('gesturechange', handler);
		document.addEventListener('gestureend', handler);
		return () => {
			document.removeEventListener('gesturestart', handler);
			document.removeEventListener('gesturechange', handler);
			document.removeEventListener('gestureend', handler);
		};
	}, []);

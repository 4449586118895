import { isDefined, recursiveFlattenObject } from '@/app/utils/common';
import { isNil } from 'lodash-es';
import { useCallback } from 'react';
import {
	CategoryMap,
	DiagramCategoryMap,
	DisplayableDiagram,
	DisplayableDiagramPartSlot
} from '../../types';
import { formatCode } from '../../utils/part-slot-code';

type UseHighlightsArgs = {
	categories: CategoryMap[];
	diagramCategories: DiagramCategoryMap[];
	diagrams: DisplayableDiagram[];
	state: {
		category?: string;
		diagram?: string;
		highlighted: string[];
		isViewingAssemblies: boolean;
	};
	setState: (state: {
		view?: string;
		category?: string;
		diagram?: string;
		highlighted?: string[];
		scrollTo?: string | null;
	}) => void;
};

export const useHighlights = ({
	state,
	diagrams,
	categories,
	diagramCategories,
	setState
}: UseHighlightsArgs) => {
	const outsideHighlight = useCallback(
		(ids: string[], isInView = true) => {
			// check if current assemblies is highlighted, then remove only these specific assemblies
			if (ids.some(id => state.highlighted.includes(id))) {
				setState({
					highlighted: state.highlighted.filter(id => !ids.includes(id)),
					scrollTo: null
				});
				return false;
			}

			const relevantCategories = isInView ? categories : diagramCategories;

			// check if should switch HCA
			const highlightedCategories = relevantCategories.filter(category => {
				const assemblies =
					'assemblies' in category
						? category.assemblies
						: category.diagrams.flatMap(({ assemblies }) => assemblies);

				const withinCategory = new Set(
					assemblies
						.flatMap(assembly => recursiveFlattenObject(assembly, ({ assemblies }) => assemblies))
						.flatMap(assembly =>
							assembly.kind === 'variants' ? assembly.variants.map(({ id }) => id) : [assembly.id]
						)
						.filter(isDefined)
				);
				return ids.every(id => withinCategory.has(id));
			});

			const shouldUpdateCategory =
				highlightedCategories.length > 0 &&
				!highlightedCategories.some(each => each.category.id === state.category);

			const relevantDiagrams = shouldUpdateCategory ? highlightedCategories[0].diagrams : diagrams;

			// check if should switch diagram
			const highlightedDiagrams = relevantDiagrams
				.filter(({ partSlots }) =>
					partSlots.some(({ assemblies }) => ids.some(id => assemblies?.includes(id)))
				)
				.map(({ id }) => id);

			const shouldUpdateDiagram =
				isNil(state.diagram) ||
				(highlightedDiagrams.length > 0 && !highlightedDiagrams.includes(state.diagram));

			setState({
				view: !isInView && state.isViewingAssemblies ? 'diagrams' : undefined,
				category: shouldUpdateCategory ? highlightedCategories[0].category.id : undefined,
				diagram: shouldUpdateDiagram ? highlightedDiagrams[0] : undefined,
				highlighted: ids,
				scrollTo: null
			});

			return true;
		},
		[categories, diagrams, state, setState]
	);

	const diagramHighlight = useCallback(
		(partSlot: DisplayableDiagramPartSlot | null) => {
			if (!partSlot?.assemblies) {
				setState({
					highlighted: [],
					scrollTo: null
				});
				return false;
			}

			// when not viewing assemblies (view by diagram) not need to switch categories as all assemblies in diagram would be shown
			if (!state.isViewingAssemblies) {
				setState({
					highlighted: partSlot.assemblies,
					scrollTo: partSlot.assemblies[0]
				});
				return true;
			}

			// check if expanded HCA needed to be changed
			const highlightedCategories = categories
				.map(category => {
					const assemblies =
						'assemblies' in category
							? category.assemblies
							: category.diagrams.flatMap(({ assemblies }) => assemblies);
					const flattenAssemblies = assemblies.flatMap(assembly =>
						recursiveFlattenObject(assembly, ({ assemblies }) => assemblies)
					);

					return { ...category, assemblies: flattenAssemblies };
				})
				.filter(({ assemblies }) => {
					const withinCategory = new Set(
						assemblies
							.flatMap(assembly =>
								assembly.kind === 'variants' ? assembly.codes : [assembly.code]
							)
							.filter(isDefined)
							.map(formatCode)
					);
					return partSlot.code && withinCategory.has(formatCode(partSlot.code));
				});

			const currentHighlightedCategory = highlightedCategories.find(
				({ category: { id } }) => id === state.category
			);

			const highlightedCategory = currentHighlightedCategory ?? highlightedCategories[0];

			setState({
				category: state.category ? highlightedCategory.category.id : undefined,
				highlighted: partSlot.assemblies,
				scrollTo: partSlot.assemblies[0]
			});
			return true;
		},
		[categories, state, setState]
	);

	const assemblyHighlight = useCallback(
		(ids: string[]) => {
			// check if current assemblies is highlighted, then remove only these specific assemblies
			const isHighlighted = ids.every(id => state.highlighted.includes(id));
			if (isHighlighted) {
				setState({
					highlighted: state.highlighted.filter(id => !ids.includes(id)) ?? null,
					scrollTo: null
				});
				return false;
			}

			// when not viewing assemblies (view by diagram) not need to switch diagrams since all assemblies are on that diagram
			if (!state.isViewingAssemblies) {
				const highlightedCategories = categories.filter(category => {
					if ('assemblies' in category) {
						return false;
					}
					const withinCategory = new Set(
						category.diagrams
							.flatMap(({ partSlots }) => partSlots)
							.flatMap(({ assemblies }) => assemblies ?? [])
							.filter(isDefined)
					);
					return ids.every(id => withinCategory.has(id));
				});

				const shouldUpdateCategory =
					highlightedCategories.length > 0 &&
					!highlightedCategories.some(each => each.category.id === state.category);

				const relevantDiagrams = shouldUpdateCategory
					? highlightedCategories[0].diagrams
					: diagrams;

				// check if should switch diagram
				const highlightedDiagrams = relevantDiagrams
					.filter(({ partSlots }) =>
						partSlots.some(({ assemblies }) => ids.some(id => assemblies?.includes(id)))
					)
					.map(({ id }) => id);

				const shouldUpdateDiagram =
					isNil(state.diagram) ||
					(highlightedDiagrams.length > 0 && !highlightedDiagrams.includes(state.diagram));

				setState({
					category: shouldUpdateCategory ? highlightedCategories[0].category.id : undefined,
					diagram: shouldUpdateDiagram ? highlightedDiagrams[0] : undefined,
					highlighted: ids,
					scrollTo: null
				});
				return true;
			}

			// check if should switch diagram showing to a relevant one
			const highlightedDiagrams = diagrams
				.filter(({ partSlots }) =>
					partSlots.some(({ assemblies }) => ids.some(id => assemblies?.includes(id)))
				)
				.map(({ id }) => id);

			const shouldUpdateDiagram =
				isNil(state.diagram) ||
				(highlightedDiagrams.length > 0 && !highlightedDiagrams.includes(state.diagram));

			setState({
				diagram: shouldUpdateDiagram ? highlightedDiagrams[0] : undefined,
				highlighted: ids,
				scrollTo: null
			});
			return true;
		},
		[setState, diagrams, state]
	);

	return { outsideHighlight, diagramHighlight, assemblyHighlight };
};

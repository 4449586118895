import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import {
	RadioGroup,
	RadioGroupProps,
	RadioOptionProps as HeadlessRadioOptionProps
} from '@headlessui/react';
import { ForwardedRef, forwardRef } from 'react';

type CardRadioGroupProps<T> = Omit<RadioGroupProps<'div', T>, 'className'> & {
	className?: string;
};

export const CardRadioGroup = forwardRef(
	<T,>({ className, ...rest }: CardRadioGroupProps<T>, ref: ForwardedRef<HTMLDivElement>) => {
		return <RadioGroup ref={ref} className={tlsx('space-y-1', className)} {...rest} />;
	}
);

export const RadioLabel = ({ className, ...rest }: InheritableElementProps<'label', object>) => (
	<RadioGroup.Label
		className={tlsx('text-sm cursor-pointer font-medium text-gray-900', className)}
		{...rest}
	/>
);

export const RadioOptions = ({
	className,
	horizontal,
	...rest
}: InheritableElementProps<
	'div',
	{
		horizontal?: boolean;
	}
>) => (
	<div className={tlsx('flex flex-col gap-2', className, { 'flex-row': horizontal })} {...rest} />
);

export type RadioOptionProps<T> = Omit<HeadlessRadioOptionProps<'div', T>, 'as'>;

export const RadioOption = forwardRef(
	<T,>({ className, ...rest }: RadioOptionProps<T>, ref: ForwardedRef<HTMLDivElement>) => (
		<RadioGroup.Option
			ref={ref}
			as="div"
			className={props =>
				tlsx(
					'relative cursor-pointer rounded-lg ring ring-gray-300 p-4 focus:outline-none transition-shadow',
					{ 'ring-theme-500 ring-2': props.checked },
					typeof className === 'function' ? className(props) : className
				)
			}
			{...rest}
		/>
	)
);

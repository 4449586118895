import { InheritableElementProps } from '@/types/utilties';
import { JobStatus } from '@sdk/lib';
import { Badge } from '../../../../atoms/badge';

const statusProps: Record<JobStatus, { label: string; variant: string }> = {
	Active: {
		label: 'Draft',
		variant: 'white'
	},
	Completed: {
		label: 'Completed',
		variant: 'green'
	},
	Ordered: {
		label: 'Ordered',
		variant: 'green'
	},
	Deleted: {
		label: 'Deleted',
		variant: 'red'
	}
};

export type JobStatusBadgeProps = InheritableElementProps<
	'div',
	{
		status?: JobStatus;
	}
>;

const JobStatusBadge = ({ status, ...rest }: JobStatusBadgeProps) => {
	if (!status) {
		return null;
	}

	const { label = 'Active', variant = 'white' } = statusProps[status];

	return (
		<Badge className="ps-4 pe-4 py-0.5" size="small" variant={variant} rounded {...rest}>
			{label}
		</Badge>
	);
};

export default JobStatusBadge;

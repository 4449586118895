export const PartInterpretationSkeleton = () => {
	return (
		<div className="grid flex-1 grid-cols-1 md:grid-cols-2 gap-4">
			<div className="relative flex flex-col w-full h-full border rounded-md">
				<div className="w-full h-full bg-gray-100 rounded animate-pulse" />
			</div>
			<div className="relative flex flex-col w-full h-full border rounded-md pt-6">
				<div className="flex items-center px-6 pb-2 w-full gap-12">
					<div className="w-max flex-[3] bg-gray-200 rounded h-9 animate-pulse" />
					<div className="w-max flex-1 bg-gray-200 rounded h-9 animate-pulse" />
				</div>
				{Array.from({ length: 4 }).map((_, i) => (
					<div key={`${i}`} className="h-14 px-6 flex items-center border-b">
						<div className="flex items-center justify-center w-6 mr-3">
							<div className="bg-gray-200 rounded animate-pulse h-4 w-4" />
						</div>
						<div className="bg-gray-200 rounded animate-pulse h-6 w-[24ch]" />
						<div className="bg-gray-200 rounded animate-pulse h-6 w-[2ch] ml-4" />
					</div>
				))}
			</div>
		</div>
	);
};

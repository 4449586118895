import { EmptyState } from '@/app/atoms/empty-state';
import ImageWithSkeleton from '@/app/atoms/image-with-skeleton';
import {
	DisplayableDiagram,
	DisplayableDiagramPartSlot
} from '@/app/features/part-interpretation/types';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { isNil } from 'lodash-es';
import { useEffect } from 'react';
import { PartAssemblyDiagram } from './subcomponents';

type PartInterpretationDiagramProps = InheritableElementProps<
	'div',
	{
		diagram?: DisplayableDiagram;
		diagrams: DisplayableDiagram[];
		highlighted?: string[];
		isViewingAssemblies?: boolean;
		onHighlight: (partSlot: DisplayableDiagramPartSlot | null) => void;
		onDiagramSelect: (diagramId: string) => void;
	}
>;

export const PartInterpretationDiagram = ({
	className,
	diagrams,
	diagram,
	highlighted,
	onHighlight,
	onDiagramSelect,
	children,
	...rest
}: PartInterpretationDiagramProps) => {
	useEffect(() => {
		if (!diagram && diagrams.length > 0) {
			onDiagramSelect(diagrams[0].id);
		}
	}, [diagram, diagrams]);

	return (
		<div
			className={tlsx(
				'relative flex items-center justify-center border rounded-md',
				{
					'bg-gray-50': isNil(diagram)
				},
				className
			)}
			{...rest}
		>
			{diagram ? (
				<PartAssemblyDiagram
					key={diagram.id}
					className="flex-1 h-full"
					diagram={diagram}
					highlighted={highlighted}
					onHighlight={onHighlight}
				/>
			) : (
				<div className="flex flex-col items-center justify-center gap-1">
					<EmptyState>
						<EmptyState.Title>No assembly selected</EmptyState.Title>
						<EmptyState.Description>
							Choose an assembly from the list to view its diagram here
						</EmptyState.Description>
					</EmptyState>
				</div>
			)}
			{children}
		</div>
	);
};

type BrowserProps = InheritableElementProps<
	'div',
	{
		description: string;
		diagrams: DisplayableDiagram[];
		onSelect: (diagramId: string) => void;
	}
>;

export const PartIntepretationDiagramBrowser = ({
	className,
	description,
	diagrams,
	onSelect,
	...rest
}: BrowserProps) => {
	return (
		<div
			className={tlsx('flex flex-col gap-6 p-6 flex-1 w-full h-full border rounded-md', className)}
			{...rest}
		>
			<span className="font-semibold">{description}</span>
			<div className="grid grid-cols-3 lg:grid-cols-4 w-full gap-6">
				{diagrams.map(diagram => (
					<button
						type="button"
						key={diagram.id}
						className="flex flex-col gap-2"
						onClick={() => onSelect(diagram.id)}
					>
						<ImageWithSkeleton
							className="aspect-square w-32 lg:w-36 xl:w-40 rounded ring-1 ring-gray-200"
							src={diagram.image.thumb}
							loading="lazy"
						/>
						<span className="capitalize text-start [text-wrap:pretty] text-sm">
							{diagram.name} ({diagram.code})
						</span>
					</button>
				))}
			</div>
		</div>
	);
};

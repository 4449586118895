import { ApiConfig } from '@sdk/lib';

let apiConfig: ApiConfig | undefined;

export const getConfig = () => {
	if (!apiConfig) {
		throw new Error('Api config is not set');
	}
	return Object.freeze({ ...apiConfig });
};

export const setConfig = (config: ApiConfig) => {
	apiConfig = config;
};

export const updateConfig = (config: Partial<ApiConfig>) => {
	if (!apiConfig) {
		throw new Error('Cannot update api config when it is not set');
	}

	Object.assign(apiConfig, config);
};

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JobCartItemInsertManyRequest } from '../models/JobCartItemInsertManyRequest';
import type { JobCartItemUpsertRequest } from '../models/JobCartItemUpsertRequest';
import type { NormalizedResponse_for_SingularItemPayload_for_Cart_and_GetCartResponseAdditional } from '../models/NormalizedResponse_for_SingularItemPayload_for_Cart_and_GetCartResponseAdditional';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CartService {
	constructor(public readonly httpRequest: BaseHttpRequest) {}
	/**
	 * add items to jobs cart
	 * @param requestBody
	 * @returns NormalizedResponse_for_SingularItemPayload_for_Cart_and_GetCartResponseAdditional
	 * @throws ApiError
	 */
	public addToJobCart(
		requestBody: JobCartItemUpsertRequest
	): CancelablePromise<NormalizedResponse_for_SingularItemPayload_for_Cart_and_GetCartResponseAdditional> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/jobs.carts.items.upsert',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
	/**
	 * insert many items to jobs cart
	 * @param requestBody
	 * @returns NormalizedResponse_for_SingularItemPayload_for_Cart_and_GetCartResponseAdditional
	 * @throws ApiError
	 */
	public insertManyToJobCart(
		requestBody: JobCartItemInsertManyRequest
	): CancelablePromise<NormalizedResponse_for_SingularItemPayload_for_Cart_and_GetCartResponseAdditional> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/jobs.carts.items.insert-many',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
}

import { EmptyState } from '@/app/atoms/empty-state';
import SaveButton from '@/app/atoms/save-button';
import { PartInterpretationCustomPart } from '@/app/features/part-interpretation/components/part-interpretation-custom-part';
import {
	PartIntepretationDiagramBrowser,
	PartInterpretationDiagram
} from '@/app/features/part-interpretation/components/part-interpretation-diagram';
import { PartAssemblyDiagram } from '@/app/features/part-interpretation/components/part-interpretation-diagram/subcomponents';
import {
	DiagramBrowsingForm,
	PartInterpretationForm
} from '@/app/features/part-interpretation/components/part-interpretation-form';
import { PartInterpretationSearch } from '@/app/features/part-interpretation/components/part-interpretation-search';
import PartInterpretationSelection from '@/app/features/part-interpretation/components/part-interpretation-selection';
import { useHighlightAnalytics } from '@/app/features/part-interpretation/hooks/use-highlight-analytics';
import { useHighlights } from '@/app/features/part-interpretation/hooks/use-highlights';
import { usePartInterpretationForm } from '@/app/features/part-interpretation/hooks/use-part-interpretation-form';
import { PartInterpretationFormData } from '@/app/features/part-interpretation/types';
import {
	assembliesLookup,
	categoriesByAssemblies,
	categoriesByDiagrams
} from '@/app/features/part-interpretation/utils';
import { useMeasurement } from '@/app/hooks/use-measure';
import { useSearchQueries } from '@/app/hooks/use-search-queries';
import { useUnsavedChanges } from '@/app/hooks/use-unsaved-changes';
import { tlsx } from '@/app/utils/tw-merge';
import { PlusIcon } from '@heroicons/react/24/outline';
import { ActionIcon, Button, Loader, Switch } from '@mantine/core';
import { jobsQueries } from '@sdk/react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { compact, entries, find, isNil, sortBy, sum, uniqBy } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { JobActions } from '../job-detail';

type PageParams = {
	jobId: string;
};

const PartInterpretationPage = () => {
	const { jobId } = useParams<PageParams>();
	if (!jobId) {
		throw new Error('Missing required jobId parameter');
	}

	const { value: nav } = useMeasurement('navigation-bar');
	const navigate = useNavigate();

	const [queries, setQueries] = useSearchQueries([
		'q',
		'diagram',
		'category',
		'view',
		{ name: 'highlighted', separator: ',' }
	]);

	const [scrollTo, setScrollTo] = useState<string | null>(null);
	const [customPart, setCustomPart] = useState(false);

	const [{ data: assembliesData, isFetching }, { data: partsData }] = useSuspenseQueries({
		queries: [
			{
				...jobsQueries.getPartAssembliesTree({ jobId }),
				staleTime: 60 * 1000,
				refetchOnMount: false
			},
			jobsQueries.listParts({ jobId })
		]
	});

	const isViewingAssemblies = useMemo(
		() => !queries.view || queries.view === 'assemblies',
		[queries]
	);

	const isSearching = useMemo(() => !isNil(queries.q) && queries.q.trim().length > 0, [queries]);

	// todo (remove later): hack to know when to inject local emblem parts into the app
	const isToyota = useMemo(
		() => assembliesData.job.vehicle?.variant?.uvdbProperties.includes('UMAK1380'),
		[assembliesData.job]
	);

	const assemblies = useMemo(
		() =>
			assembliesLookup({ assemblies: assembliesData.tree.assemblies, withLocalContent: isToyota }),
		[assembliesData.tree.assemblies, isToyota]
	);

	const brands = useMemo(
		() =>
			sortBy(
				uniqBy(
					compact(
						assemblies.all.flatMap(assembly =>
							assembly.kind == 'single'
								? [assembly.part?.brand]
								: assembly.variants.map(({ part }) => part.brand)
						)
					),
					({ id }) => id
				),
				({ id }) => id
			),
		[assemblies]
	);

	const { categories, assemblyCategories, diagramCategories } = useMemo(() => {
		const assemblyCategories = categoriesByAssemblies({
			assemblies: assembliesData.tree.assemblies,
			search: queries.q,
			withLocalContent: isToyota
		});
		const diagramCategories = categoriesByDiagrams({
			assemblies: assembliesData.tree.assemblies,
			search: queries.q,
			withLocalContent: isToyota
		});

		return {
			categories: isViewingAssemblies ? assemblyCategories : diagramCategories,
			assemblyCategories,
			diagramCategories
		};
	}, [assembliesData, queries.q, isViewingAssemblies, isToyota]);

	const category = useMemo(
		() => categories.find(({ category }) => category.id === queries.category),
		[categories, queries]
	);

	const diagrams = useMemo(() => {
		const filtered = !isSearching
			? category?.diagrams
			: uniqBy(
					categories.flatMap(({ diagrams }) => diagrams),
					({ id }) => id
				);

		return filtered ?? [];
	}, [category, queries]);

	const diagram = useMemo(
		() =>
			find(
				uniqBy(
					categories.flatMap(({ diagrams }) => diagrams),
					({ id }) => id
				),
				diagram => diagram.id === queries.diagram
			),
		[categories, queries]
	);

	const isNotViewingDiagramYet = useMemo(
		() => isNil(queries.diagram) && isViewingAssemblies && diagrams.length > 0,
		[isViewingAssemblies, queries, diagrams]
	);

	const { control, selection, isDirty, isSubmitting, setValue, submit, handleSubmit } =
		usePartInterpretationForm({
			jobId,
			parts: partsData.parts,
			assemblies
		});

	const { assemblyHighlight, diagramHighlight, outsideHighlight } = useHighlights({
		categories,
		diagramCategories,
		diagrams: uniqBy(
			categories.flatMap(({ diagrams }) => diagrams),
			({ id }) => id
		),
		state: {
			highlighted: queries.highlighted ?? [],
			category: queries.category,
			diagram: queries.diagram,
			isViewingAssemblies
		},
		setState: ({ scrollTo, ...queries }) => {
			setQueries(queries);
			if (scrollTo !== undefined) {
				setScrollTo(scrollTo);
			}
		}
	});

	const { diagramHighlightWithAnalytics } = useHighlightAnalytics({
		job: assembliesData.job,
		assemblies: assemblies.all,
		diagram,
		diagramHighlight
	});

	const toggleViewAllDiagrams = useCallback(() => {
		setQueries({
			view: isViewingAssemblies ? 'diagrams' : 'assemblies',
			category: null,
			diagram: null,
			highlighted: null
		});
		setScrollTo(null);
	}, [setQueries, setScrollTo]);

	const toggleCustomPartDialog = useCallback(() => {
		setCustomPart(prev => !prev);
	}, [setCustomPart]);

	const onSubmit = useCallback(
		async (data: PartInterpretationFormData) => {
			await submit(data);
			const hasAddedParts = sum(Object.values(data).map(selection => selection?.quantity ?? 0)) > 0;
			if (hasAddedParts) {
				navigate(`/job/${jobId}/supply`);
			}
		},
		[navigate, submit]
	);

	console.log(entries(selection).filter(([_, value]) => !!value));

	useUnsavedChanges(isDirty && !isSubmitting);

	useEffect(() => {
		// if currently viewing a diagram but the list of diagrams doesn't exist anymore, deselect the diagram
		if (!isNil(queries.diagram) && diagrams.length === 0) {
			setQueries({ diagram: null });
		}
	}, [diagrams, queries]);

	return (
		<div
			className={tlsx('grid flex-1 grid-cols-1 md:grid-cols-2 gap-4')}
			data-testid="parts-section"
		>
			<JobActions>
				<div className="flex items-center gap-4">
					{isFetching && <Loader size="xs" />}
					<PartInterpretationSelection
						control={control}
						selection={selection}
						highlighted={queries.highlighted ?? []}
						assemblies={assemblies}
						isViewingAssemblies={isViewingAssemblies}
						onHighlight={outsideHighlight}
					/>
					<SaveButton
						type="submit"
						key="parts-submit"
						form="recommendations-form"
						disabled={!isDirty}
						submitting={isSubmitting}
						onClick={handleSubmit(onSubmit)}
					/>
				</div>
			</JobActions>
			<div
				className="flex flex-col gap-2 sticky"
				data-testid="parts-diagram-view"
				style={{
					top: (nav?.height ?? 0) + 16,
					height: `calc(100dvh - ${nav?.height ?? 0}px - 3rem)`
				}}
			>
				{isNotViewingDiagramYet ? (
					<PartIntepretationDiagramBrowser
						className="flex-1 max-h-full overflow-auto"
						description={
							isSearching
								? `Diagrams related to "${queries.q}"`
								: category?.category?.name ?? 'All diagrams'
						}
						diagrams={diagrams}
						onSelect={id => {
							setScrollTo(null);
							setQueries({
								diagram: id,
								highlighted: null
							});
						}}
					/>
				) : (
					<PartInterpretationDiagram
						className="flex-1 max-h-full"
						diagram={diagram}
						diagrams={diagrams}
						highlighted={queries.highlighted}
						onHighlight={diagramHighlightWithAnalytics}
						onDiagramSelect={id => {
							setScrollTo(null);
							setQueries({
								diagram: id,
								highlighted: null
							});
						}}
					>
						{isViewingAssemblies && (
							<PartAssemblyDiagram.Selection
								className="absolute bottom-4 left-4"
								selectedDiagram={diagram}
								diagrams={diagrams}
								onSelect={id => {
									setScrollTo(null);
									setQueries({
										diagram: id,
										highlighted: null
									});
								}}
							/>
						)}
					</PartInterpretationDiagram>
				)}
			</div>
			<div
				className="relative flex flex-col w-full h-full border rounded-md pt-6"
				data-testid="parts-assemblies-view"
				style={{
					height: `calc(100dvh - ${nav?.height ?? 0}px - 3rem)`
				}}
			>
				<div className="flex items-center px-6 pb-2 w-full gap-12">
					<PartInterpretationSearch
						assemblies={isViewingAssemblies ? assemblies.filtered : assemblies.diagrams}
						selected={queries.q}
						onChange={q => setQueries({ q })}
					/>
					<Switch
						className="flex-shrink-0"
						labelPosition="left"
						label="View all diagrams"
						checked={!isViewingAssemblies}
						onChange={toggleViewAllDiagrams}
					/>
				</div>
				{categories.length > 0 ? (
					isViewingAssemblies ? (
						<PartInterpretationForm
							className="flex-1 w-full h-full"
							id="recommendations-form"
							control={control}
							selection={selection}
							job={assembliesData.job}
							category={queries.category ?? null}
							highlighted={queries.highlighted ?? []}
							isSearching={isSearching}
							scrollTo={scrollTo}
							categories={assemblyCategories}
							onHighlight={assemblyHighlight}
							onSubmit={handleSubmit(onSubmit)}
							onChangeCategory={id => {
								setScrollTo(null);
								setQueries({ category: id, highlighted: null, diagram: null });
							}}
						/>
					) : (
						<DiagramBrowsingForm
							className="flex-1 w-full h-full"
							id="recommendations-form"
							control={control}
							selection={selection}
							job={assembliesData.job}
							category={queries.category ?? null}
							diagram={queries.diagram ?? null}
							highlighted={queries.highlighted ?? []}
							isSearching={isSearching}
							scrollTo={scrollTo}
							categories={diagramCategories}
							onHighlight={assemblyHighlight}
							onSubmit={handleSubmit(onSubmit)}
							onChangeCategory={id => {
								setScrollTo(null);
								setQueries({ category: id, highlighted: null, diagram: null });
							}}
							onChangeDiagram={(id, category) => {
								setScrollTo(null);
								setQueries({ category, highlighted: null, diagram: id });
							}}
						/>
					)
				) : (
					<div className="flex-1 w-full h-full flex items-center justify-center p-6">
						<EmptyState>
							<EmptyState.Title>No part matches your search</EmptyState.Title>
							<EmptyState.Description className="text-balance">
								Try using different terminology, view all diagrams, or add a custom part
							</EmptyState.Description>
							<Button type="button" variant="subtle" onClick={toggleCustomPartDialog}>
								Add "{queries.q}" as custom part
							</Button>
						</EmptyState>
					</div>
				)}
			</div>
			<PartInterpretationCustomPart
				id="custom-part"
				open={customPart}
				initialDescription={queries.q}
				brands={brands}
				onSubmit={({ brand, mpn, description }) => {
					if (!brand || !mpn || !description) return;
					const part = {
						gapcBrandId: brand,
						mpn,
						partSlotIds: null,
						assemblyId: null,
						quantity: 1,
						description
					};
					setValue(`${brand}-${mpn}`, part, {
						shouldDirty: true,
						shouldValidate: true,
						shouldTouch: true
					});
					toggleCustomPartDialog();
				}}
				onClose={toggleCustomPartDialog}
			/>

			<ActionIcon
				className="fixed bottom-9 right-9 rounded-full z-30"
				variant="filled"
				color="blue"
				size="xl"
				onClick={toggleCustomPartDialog}
			>
				<PlusIcon className="w-5 h-6" />
			</ActionIcon>
		</div>
	);
};

export default PartInterpretationPage;

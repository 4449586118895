import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { ActionIcon } from '@mantine/core';

type ZoomStepperProps = InheritableElementProps<
	'div',
	{
		onZoomIn: () => void;
		onZoomOut: () => void;
	}
>;

const ZoomStepper = ({ className, onZoomIn, onZoomOut, ...rest }: ZoomStepperProps) => {
	return (
		<div
			className={tlsx('rounded-lg border bg-white shadow-sm divide-y divide-gray-200', className)}
			{...rest}
		>
			<ActionIcon
				variant="transparent"
				color="blue"
				className={tlsx('w-8 h-8', 'rounded-b-none')}
				onClick={onZoomIn}
			>
				+
			</ActionIcon>
			<ActionIcon
				variant="transparent"
				color="blue"
				className={tlsx('w-8 h-8', 'rounded-t-none border-x-0 border-b-0')}
				onClick={onZoomOut}
			>
				-
			</ActionIcon>
		</div>
	);
};

export default ZoomStepper;

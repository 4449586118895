import { InheritableElementProps } from '@/types/utilties';
import { Badge } from '@atoms/badge';
import { BadgeVariant } from '@atoms/badge/types';
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/solid';

type ConfidenceProps = InheritableElementProps<
	'span',
	{
		confidence: number;
	}
>;

type ConfidenceVariant = Extract<BadgeVariant, 'red' | 'yellow' | 'green'>;

type ConfidenceInfo = {
	variant: ConfidenceVariant;
	text: string;
};

const getConfidenceVariant = (confidence: number): ConfidenceInfo => {
	if (confidence >= 25) {
		return { variant: 'green', text: 'Often' };
	}
	if (confidence >= 3) {
		return { variant: 'yellow', text: 'Sometimes' };
	}
	return { variant: 'red', text: 'Rarely' };
};

export const ConfidenceBadge = ({ confidence, ...rest }: ConfidenceProps) => {
	const { variant, text } = getConfidenceVariant(confidence);
	return (
		<Badge size="small" rounded variant={variant} {...rest}>
			<Badge.LeadingIcon as={ArrowPathRoundedSquareIcon} />
			{text}
		</Badge>
	);
};

/**
 * todo (remove later): temp hack to find local contents (expect to be non-permanent)
 */

import { PartAssembly, PartAssemblyInfo, encodeGapcPartIdentityKey } from '@/sdk/lib';
import LOCAL_CONTENTS from './local-contents.json';

const TOYOTA_BRAND = {
	id: 'GBRN20403',
	isOem: true,
	name: 'Toyota Group'
};

export const accessories = LOCAL_CONTENTS.map(({ group, contents }) => {
	const assembly: PartAssembly = {
		id: group.id,
		description: group.description,
		assemblyType: 'part',
		// make sure these parts are on the bottom as they are manually injected
		confidence: group.description === 'Misc' ? -1 : 0,
		subAssemblies: contents.map(({ id, description, part_number }) => {
			const part: PartAssemblyInfo = {
				gapcBrandId: TOYOTA_BRAND.id,
				mpn: part_number,
				partIdentity: encodeGapcPartIdentityKey({
					gapcBrandId: TOYOTA_BRAND.id,
					mpn: part_number
				})
			};

			const assembly: PartAssembly = {
				id,
				assemblyType: 'part',
				subAssemblies: [],
				confidence: 0,
				description,
				part
			};

			return {
				kind: 'single',
				assembly
			};
		})
	};

	return assembly;
});

import { useUpsertParts } from '@/app/features/part-interpretation/hooks/use-upsert-parts';
import { useAnalytics } from '@/app/hooks/use-analytics';
import { RepairhubEvents } from '@/app/utils/analytics/events';
import { isDefined } from '@/app/utils/common';
import {
	decodeGapcPartIdentityKey,
	encodeGapcPartIdentityKey,
	JobPart,
	JobPartInput
} from '@sdk/lib';
import { entries, groupBy, isNil, sum } from 'lodash-es';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { uniqBy } from '../../../../../sdk/lib/utils/array';
import { DisplayableAssembly, PartInterpretationFormData } from '../../types';
import { createSelectionFromJobParts } from '../../utils';

type UsePartInterpretationFormArgs = {
	jobId: string;
	parts: JobPart[];
	assemblies: {
		filtered: DisplayableAssembly[];
		all: DisplayableAssembly[];
	};
};

export const usePartInterpretationForm = ({
	jobId,
	parts,
	assemblies
}: UsePartInterpretationFormArgs) => {
	const { logEvent } = useAnalytics();
	const { mutateAsync: upsertParts } = useUpsertParts();
	const { control, handleSubmit, reset, watch, formState, setValue } =
		useForm<PartInterpretationFormData>({
			defaultValues: createSelectionFromJobParts(
				parts,
				uniqBy([...assemblies.filtered, ...assemblies.all], ({ id }) => id)
			)
		});

	const { isDirty, isSubmitting } = formState;
	const selection = watch();

	console.log(formState.isValid, formState.isSubmitting);

	const submit = useCallback(
		async (data: PartInterpretationFormData) => {
			const selection = Object.values(data).filter(value => !isNil(value));

			const parts = entries(
				groupBy(selection, ({ gapcBrandId, mpn }) =>
					encodeGapcPartIdentityKey({ gapcBrandId, mpn })
				)
			).map(
				([partIdentity, selection]): JobPartInput => ({
					...decodeGapcPartIdentityKey(partIdentity),
					quantity: sum(selection.map(({ quantity }) => quantity)),
					description: selection[0].description,
					partSlot: selection[0].partSlotIds?.gapcPartTypeId
						? {
								gapcPartTypeId: selection[0].partSlotIds.gapcPartTypeId,
								gapcPositionId: selection[0].partSlotIds.gapcPositionId
							}
						: null,
					assemblyIds: selection.map(({ assemblyId }) => assemblyId).filter(isDefined)
				})
			);

			await upsertParts({
				jobId,
				parts
			});

			logEvent(
				RepairhubEvents.job_parts_upserted({
					job_id: jobId,
					parts
				})
			);

			// retain changes (job parts won't immediately get updated, should prevent added parts from flashing to default values)
			reset(undefined, { keepValues: true });
		},
		[jobId, upsertParts, logEvent, reset]
	);

	return {
		control,
		isDirty,
		isSubmitting,
		selection,
		handleSubmit,
		submit,
		setValue
	};
};

import { isDefined } from '@/sdk/lib/utils/object';
import { minBy } from 'lodash-es';
import { DisplayableAssembly } from '../types';

// give a sort key based on description
export const alphabeticSortKey = (assembly: DisplayableAssembly): string => {
	return assembly.description;
};

// give a sort key based on confidence
export const confidenceSortKey = (assembly: DisplayableAssembly): number => {
	if (assembly.confidence) {
		return -assembly.confidence;
	}
	if (assembly.assemblies.length === 0) {
		return 0;
	}
	return Math.min(...assembly.assemblies.map(confidenceSortKey));
};

// give a sort key based on id
export const codeSortKey = (assembly: DisplayableAssembly): number | undefined => {
	if (assembly.kind === 'single') {
		return assembly.code?.[0];
	}
	const codes = assembly.variants.map(({ code }) => code).filter(isDefined);
	const code = minBy(codes, ([_, second]) => second);
	return code?.[1];
};

// give a sort key based on id
export const stableSortKey = (assembly: DisplayableAssembly): string => {
	return assembly.id;
};

import { isDefined, recursiveFlattenObject } from '@/app/utils/common';
import { PartAssemblyInfo } from '@sdk/lib';
import { head, isNil, isNumber, maxBy, sortBy } from 'lodash-es';
import { DiagramPartSlotCode, DisplayableAssembly, DisplayableDiagram } from '../types';
import { confidenceSortKey } from './sort';

// Get a mapping of assembly of
// - part slot code/number, used for adding part slot code/number to assemblies
// - diagram part slot to part slot code/number, used for adding part slot code/number to assemblies
// - gapc part to assembly, used for finding assemblies for diagram part slot
export const getPartSlotMappings = (
	assemblies: {
		all: DisplayableAssembly[];
		filtered: DisplayableAssembly[];
	},
	diagrams: DisplayableDiagram[]
) => {
	const flattenAssemblies = sortBy(assemblies.all, confidenceSortKey).flatMap(assembly =>
		recursiveFlattenObject(assembly, ({ assemblies }) => assemblies)
	);

	const filteredAssemblyIds = new Set(
		assemblies.filtered
			.flatMap(assembly => recursiveFlattenObject(assembly, ({ assemblies }) => assemblies))
			.flatMap(assembly =>
				assembly.kind === 'single' ? [assembly.id] : assembly.variants.map(({ id }) => id)
			)
	);

	const diagramPartSlots = diagrams
		.flatMap(diagram => diagram.partSlots)
		.map(partSlot => ({
			...partSlot,
			partIds: new Set(partSlot.parts.map(part => part.partIdentity).filter(isDefined))
		}));

	let primary = 1;

	const diagramPartSlotToCode = new Map<string, DiagramPartSlotCode>();
	const assemblyToCode = new Map<string, DiagramPartSlotCode>();
	const partToAssembly = new Map<string, string[]>();

	const setPartSlotCode = (
		id: string,
		part: PartAssemblyInfo,
		newCode: () => DiagramPartSlotCode
	) => {
		// use existing (by assemblies)
		const directPartSlots = diagramPartSlots.filter(({ assemblies }) => assemblies.includes(id));
		const directExisting = directPartSlots
			.map(({ id }) => diagramPartSlotToCode.get(id))
			.find(isDefined);
		if (directExisting) {
			assemblyToCode.set(id, directExisting);
			return;
		}

		// use existing (by parts)
		const partSlots = diagramPartSlots.filter(({ partIds }) => partIds.has(part.partIdentity));
		const existing = partSlots.map(({ id }) => diagramPartSlotToCode.get(id)).find(isDefined);
		if (existing && directPartSlots.length == 0) {
			assemblyToCode.set(id, existing);
			return;
		}

		// create new and assign to assembly and all part slots referencing assembly
		const code: DiagramPartSlotCode = newCode();
		assemblyToCode.set(id, code);

		if (directPartSlots.length > 0) {
			directPartSlots.forEach(({ id }) => diagramPartSlotToCode.set(id, code));
		} else {
			partSlots.forEach(({ id }) => diagramPartSlotToCode.set(id, code));
		}
	};

	for (const assembly of flattenAssemblies) {
		switch (assembly.kind) {
			case 'single': {
				const { part, id } = assembly;
				if (isNil(part)) {
					continue;
				}

				// part to assembly
				if (filteredAssemblyIds.has(id)) {
					partToAssembly.set(part.partIdentity, [
						...(partToAssembly.get(part.partIdentity) ?? []),
						id
					]);
				}

				setPartSlotCode(id, part, () => [primary++, 1]);
				break;
			}
			case 'variants': {
				const { variants } = assembly;
				const parts = variants.map(({ part }) => part);

				// part to assembly
				for (const { part, id } of variants) {
					if (filteredAssemblyIds.has(id)) {
						partToAssembly.set(part.partIdentity, [
							...(partToAssembly.get(part.partIdentity) ?? []),
							id
						]);
					}
				}

				// continue existings
				const continued = head(
					sortBy(
						parts
							.map(({ partIdentity }) => {
								const partSlot = diagramPartSlots.find(({ partIds }) => partIds.has(partIdentity));
								return partSlot ? diagramPartSlotToCode.get(partSlot?.id) : null;
							})
							.filter(isDefined),
						([, secondary]) => secondary
					)
				) ?? [primary++, 0];

				for (const { id, part } of variants) {
					setPartSlotCode(id, part, () => {
						if (continued[1] >= 26) {
							continued[0] = primary++;
							continued[1] = 0;
						}
						return [continued[0], ++continued[1]];
					});
				}
				break;
			}
		}
	}

	return { assemblyToCode, diagramPartSlotToCode, partToAssembly };
};

export const mapPartSlotCodeToAssembly = (
	{ id, assemblies, ...assembly }: DisplayableAssembly,
	mapping: Map<string, DiagramPartSlotCode>
): DisplayableAssembly => {
	const base = {
		id,
		assemblies: assemblies.map(assembly => mapPartSlotCodeToAssembly(assembly, mapping))
	};

	if (assembly.kind === 'single') {
		return { ...base, ...assembly, code: mapping.get(id) ?? assembly.code };
	}

	const variants = assembly.variants.map(({ id, code, ...rest }) => ({
		id,
		code: mapping.get(id) ?? code,
		...rest
	}));

	return {
		...base,
		...assembly,
		variants,
		codes: variants.map(({ code }) => code).filter(isDefined)
	};
};

export const mapPartSlotCodeToDiagram = (
	{ partSlots, ...rest }: DisplayableDiagram,
	mapping: Map<string, DiagramPartSlotCode>
): DisplayableDiagram => {
	return {
		partSlots: partSlots.map(({ id, code, ...rest }) => ({
			id,
			code: mapping.get(id) ?? code,
			...rest
		})),
		...rest
	};
};

export const formatCode = (code: DiagramPartSlotCode | null | undefined): string | null => {
	if (!code) {
		return null;
	}
	const [primary, secondary] = code;
	return [`${primary}`, numToAlpha(secondary)].join('');
};

export const formatPrimaryCode = (
	code: DiagramPartSlotCode | DiagramPartSlotCode[] | null | undefined
): string | null => {
	if (!code) {
		return null;
	}
	const [first] = code;
	if (isNumber(first)) {
		return `${first}`;
	}
	return `${modePrimaryCode(code as DiagramPartSlotCode[])}`;
};

const numToAlpha = (num: number): string => {
	if (num < 27) {
		return String.fromCharCode(num + 64).toLowerCase();
	}
	const front = Math.floor(num / 27);
	const back = Math.round(num % 27);
	return `${numToAlpha(front)}${numToAlpha(back + 1)}`;
};

const modePrimaryCode = (codes: DiagramPartSlotCode[]): DiagramPartSlotCode[0] | undefined => {
	return maxBy(
		codes.map(([primary]) => ({
			primary,
			count: codes.filter(other => other[0] === primary).length
		})),
		({ count }) => count
	)?.primary;
};

import { Job } from '@sdk/lib';

export const useJobCompletion = (job: Job) => {
	const hasCollisionMap = job.collisions.length > 0;
	const hasAddedParts = job.addedPartsCount > 0;

	return {
		hasCollisionMap,
		hasAddedParts
	};
};

import { jobsMutations, jobsQueries } from '@sdk/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUpsertParts = () => {
	const client = useQueryClient();
	return useMutation({
		...jobsMutations.upsertParts,
		onSettled: (data, _, variables) => {
			const jobKey = jobsQueries.get({ jobId: variables.jobId }).queryKey;

			if (!data?.job) {
				return;
			}
			client.setQueryData(jobKey, () => ({ job: data.job }));
			client.invalidateQueries(jobsQueries.listParts({ jobId: variables.jobId }));
		}
	});
};

import { GetJobResult } from '@sdk/lib';
import { jobsMutations, jobsQueries } from '@sdk/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUpdateCollisions = () => {
	const queryClient = useQueryClient();

	return useMutation({
		...jobsMutations.upsertCollisions,
		onMutate: async ({ jobId, collisions }) => {
			const queryOptions = jobsQueries.get({ jobId });
			await queryClient.cancelQueries(queryOptions);

			// performing optimistic updates
			const previousJob = queryClient.getQueryData<GetJobResult>(queryOptions.queryKey);
			queryClient.setQueryData<GetJobResult>(queryOptions.queryKey, prev => {
				if (!prev) {
					return;
				}

				return {
					...prev,
					job: {
						...prev.job,
						collisions
					}
				};
			});

			return {
				queryKey: queryOptions.queryKey,
				previousJob
			};
		},
		onSettled: (_, error, { jobId }, context) => {
			if (!context?.queryKey) {
				return;
			}
			if (error) {
				queryClient.setQueryData(context.queryKey, context.previousJob);
				return;
			}
			queryClient.invalidateQueries({ queryKey: context.queryKey });
			queryClient.invalidateQueries(jobsQueries.getPartAssembliesTree({ jobId }));
		}
	});
};

export const uniqBy = <T>(array: T[], iteratee: (value: T) => unknown): T[] => {
	const set = new Set();
	const result = [];

	for (const value of array) {
		const computed = iteratee(value);

		if (!set.has(computed)) {
			set.add(computed);
			result.push(value);
		}
	}

	return result;
};

export const groupBy = <T>(array: T[], iteratee: (value: T) => any): Record<string, T[]> => {
	const result: Record<string, T[]> = {};
	for (const value of array) {
		const key = iteratee(value).toString();

		if (result[key]) {
			result[key].push(value);
		} else {
			result[key] = [value];
		}
	}

	return result;
};

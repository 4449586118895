import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Accordion } from '@mantine/core';
import { Job } from '@sdk/lib';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { AssemblyCategoryMap, DiagramCategoryMap, PartInterpretationFormData } from '../../types';
import { countAddedAssemblies } from '../../utils';
import { ControlledPartAssemblyItems, DiagramBrowsingPreview } from './subcomponents';

type PartInterpretationAccordionItemProps = InheritableElementProps<
	'div',
	{
		selection: PartInterpretationFormData;
		control: Control<PartInterpretationFormData>;
		job: Job;
		category: string | null;
		categoryMap: AssemblyCategoryMap;
		highlighted: string[];
		scrollTo: string | null;
		onHighlight: (ids: string[]) => void;
	}
>;

const PartInterpretationAccordionItem = ({
	className,
	category,
	categoryMap,
	control,
	selection,
	job,
	highlighted,
	scrollTo,
	onHighlight,
	...rest
}: PartInterpretationAccordionItemProps) => {
	const addedCount = useMemo(
		() => countAddedAssemblies(categoryMap.assemblies, selection),
		[categoryMap, selection]
	);
	return (
		<Accordion.Item
			value={categoryMap.category.id}
			className={tlsx('flex flex-col w-full', className)}
			{...rest}
		>
			<Accordion.Control
				className={tlsx('px-6 sticky top-0 bg-white z-10', {
					'shadow-sm': category === categoryMap.category.id
				})}
			>
				<span className="font-semibold text-gray-900">{categoryMap.category.name}</span>
				{addedCount > 0 && (
					<span className="text-blue-600 text-sm font-semibold pl-4">{addedCount}</span>
				)}
			</Accordion.Control>

			<Accordion.Panel>
				<div className="flex flex-col w-full divide-y divide-gray-100">
					<ControlledPartAssemblyItems
						assemblies={categoryMap.assemblies}
						control={control}
						selection={selection}
						job={job}
						highlighted={highlighted}
						onHighlight={onHighlight}
						scrollTo={scrollTo}
					/>
				</div>
			</Accordion.Panel>
		</Accordion.Item>
	);
};

type PartInterpretationResultItemProps = InheritableElementProps<
	'div',
	{
		selection: PartInterpretationFormData;
		control: Control<PartInterpretationFormData>;
		job: Job;
		categoryMap: AssemblyCategoryMap;
		highlighted: string[];
		scrollTo: string | null;
		onHighlight: (ids: string[]) => void;
	}
>;

const PartInterpretationSearchResultItem = ({
	className,
	categoryMap,
	control,
	selection,
	job,
	highlighted,
	scrollTo,
	onHighlight,
	...rest
}: PartInterpretationResultItemProps) => {
	const addedCount = useMemo(
		() => countAddedAssemblies(categoryMap.assemblies, selection),
		[categoryMap, selection]
	);
	return (
		<div className={tlsx('flex flex-col w-full py-1', className)} {...rest}>
			<div className="px-6 py-2 bg-white z-10">
				<span className="font-semibold text-gray-900 text-sm">
					{categoryMap.category.name}
					{addedCount > 0 && <span className="text-blue-600 text-sm pl-4">{addedCount}</span>}
				</span>
			</div>
			<div className="flex flex-col w-full divide-y divide-gray-100 p-2">
				<ControlledPartAssemblyItems
					assemblies={categoryMap.assemblies}
					control={control}
					selection={selection}
					job={job}
					highlighted={highlighted}
					onHighlight={onHighlight}
					scrollTo={scrollTo}
				/>
			</div>
		</div>
	);
};

type PartInterpretationFormProps = InheritableElementProps<
	'form',
	{
		selection: PartInterpretationFormData;
		control: Control<PartInterpretationFormData>;
		job: Job;
		category: string | null;
		categories: AssemblyCategoryMap[];
		highlighted: string[];
		scrollTo: string | null;
		isSearching?: boolean;
		onHighlight: (ids: string[]) => void;
		onChangeCategory: (id: string | null) => void;
	}
>;

export const PartInterpretationForm = ({
	className,
	control,
	selection,
	job,
	category,
	categories,
	highlighted,
	scrollTo,
	isSearching,
	onHighlight,
	onChangeCategory,
	...rest
}: PartInterpretationFormProps) => {
	return (
		<form className={tlsx('max-h-full overflow-auto', className)} noValidate {...rest}>
			{!isSearching ? (
				<Accordion
					key="accordion"
					className="group flex-1 w-full h-full"
					value={category}
					transitionDuration={0}
					chevronPosition="left"
					chevron={<ChevronRightIcon className="w-4 h-4" />}
					styles={{
						// Change accordion icon to rotate 90deg instead of 180deg
						chevron: {
							'&[data-rotate]': {
								transform: 'rotate(90deg)'
							}
						}
					}}
					onChange={onChangeCategory}
				>
					{categories.map(categoryMap => (
						<PartInterpretationAccordionItem
							key={categoryMap.category.id}
							category={category}
							categoryMap={categoryMap}
							control={control}
							selection={selection}
							job={job}
							highlighted={highlighted}
							onHighlight={onHighlight}
							scrollTo={scrollTo}
						/>
					))}
				</Accordion>
			) : (
				categories.map(categoryMap => (
					<PartInterpretationSearchResultItem
						key={categoryMap.category.id}
						categoryMap={categoryMap}
						control={control}
						selection={selection}
						job={job}
						highlighted={highlighted}
						onHighlight={onHighlight}
						scrollTo={scrollTo}
					/>
				))
			)}
		</form>
	);
};

type DiagramBrowsingAccordionItemProps = InheritableElementProps<
	'div',
	{
		selection: PartInterpretationFormData;
		control: Control<PartInterpretationFormData>;
		job: Job;
		category: string | null;
		diagram: string | null;
		categoryMap: DiagramCategoryMap;
		highlighted: string[];
		scrollTo: string | null;
		onHighlight: (ids: string[]) => void;
		onChangeDiagram: (id: string) => void;
	}
>;

const DiagramBrowsingAccordionItem = ({
	className,
	category,
	diagram,
	categoryMap,
	control,
	selection,
	job,
	highlighted,
	scrollTo,
	onHighlight,
	onChangeDiagram,
	...rest
}: DiagramBrowsingAccordionItemProps) => {
	const addedCount = useMemo(
		() =>
			countAddedAssemblies(
				categoryMap.diagrams.flatMap(({ assemblies }) => assemblies),
				selection
			),
		[categoryMap, selection]
	);
	const browsedDiagram = useMemo(
		() => categoryMap.diagrams.find(({ id }) => id === diagram),
		[categoryMap, diagram]
	);
	return (
		<Accordion.Item
			value={categoryMap.category.id}
			className={tlsx('flex flex-col w-full', className)}
			{...rest}
		>
			<Accordion.Control
				className={tlsx('px-6 sticky top-0 bg-white z-10', {
					'shadow-sm': category === categoryMap.category.id
				})}
			>
				<span className="font-semibold text-gray-900">{categoryMap.category.name}</span>
				{addedCount > 0 && (
					<span className="text-blue-600 text-sm font-semibold pl-4">{addedCount}</span>
				)}
			</Accordion.Control>

			<Accordion.Panel>
				<div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 w-full px-3 pt-3 pb-4 gap-6 border-b">
					{categoryMap.diagrams.map(({ id, ...rest }) => {
						const isSelected = id === diagram;
						return (
							<DiagramBrowsingPreview
								key={id}
								diagram={{ id, ...rest }}
								selection={selection}
								isSelected={isSelected}
								onChangeDiagram={onChangeDiagram}
							/>
						);
					})}
				</div>
				<div className="text-xs px-3 py-4">
					All assemblies in <span className="capitalize font-bold">{browsedDiagram?.name}</span>
				</div>
				<div className="flex flex-col w-full divide-y divide-gray-100">
					{browsedDiagram?.assemblies && (
						<ControlledPartAssemblyItems
							assemblies={browsedDiagram?.assemblies}
							control={control}
							selection={selection}
							job={job}
							highlighted={highlighted}
							onHighlight={onHighlight}
							scrollTo={scrollTo}
						/>
					)}
				</div>
			</Accordion.Panel>
		</Accordion.Item>
	);
};

type DiagramBrowsingSearchItemProps = InheritableElementProps<
	'div',
	{
		selection: PartInterpretationFormData;
		control: Control<PartInterpretationFormData>;
		job: Job;
		category: string | null;
		diagram: string | null;
		categoryMap: DiagramCategoryMap;
		highlighted: string[];
		scrollTo: string | null;
		onHighlight: (ids: string[]) => void;
		onChangeDiagram: (id: string, category?: string) => void;
	}
>;

const DiagramBrowsingSearchItem = ({
	className,
	diagram,
	category,
	categoryMap,
	control,
	selection,
	job,
	highlighted,
	scrollTo,
	onHighlight,
	onChangeDiagram,
	...rest
}: DiagramBrowsingSearchItemProps) => {
	const addedCount = useMemo(
		() =>
			countAddedAssemblies(
				categoryMap.diagrams.flatMap(({ assemblies }) => assemblies),
				selection
			),
		[categoryMap, selection]
	);
	const browsedDiagram = useMemo(
		() =>
			categoryMap.category.id === category
				? categoryMap.diagrams.find(({ id }) => id === diagram)
				: null,
		[categoryMap, category, diagram]
	);
	return (
		<div className={tlsx('flex flex-col w-full py-1', className)} {...rest}>
			<div className="px-6 py-2 bg-white z-10">
				<span className="font-semibold text-gray-900 text-sm">
					{categoryMap.category.name}
					{addedCount > 0 && <span className="text-blue-600 text-sm pl-4">{addedCount}</span>}
				</span>
			</div>
			<div className="flex flex-col w-full p-2">
				<div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 w-full px-3 pt-3 pb-4 gap-6">
					{categoryMap.diagrams.map(({ id, ...rest }) => {
						const isSelected = id === diagram && categoryMap.category.id === category;
						return (
							<DiagramBrowsingPreview
								key={id}
								diagram={{ id, ...rest }}
								selection={selection}
								isSelected={isSelected}
								onChangeDiagram={id => onChangeDiagram(id, categoryMap.category.id)}
							/>
						);
					})}
				</div>
				{browsedDiagram && (
					<>
						<div className="text-xs px-4 py-4 border-t">
							All assemblies in <span className="capitalize font-bold">{browsedDiagram?.name}</span>
						</div>
						<div className="flex flex-col w-full divide-y divide-gray-100 px-3">
							{browsedDiagram?.assemblies && (
								<ControlledPartAssemblyItems
									assemblies={browsedDiagram.assemblies}
									control={control}
									selection={selection}
									job={job}
									highlighted={highlighted}
									onHighlight={onHighlight}
									scrollTo={scrollTo}
								/>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

type DiagramBrowsingFormProps = InheritableElementProps<
	'form',
	{
		selection: PartInterpretationFormData;
		control: Control<PartInterpretationFormData>;
		job: Job;
		category: string | null;
		diagram: string | null;
		categories: DiagramCategoryMap[];
		highlighted: string[];
		scrollTo: string | null;
		isSearching?: boolean;
		onHighlight: (ids: string[]) => void;
		onChangeCategory: (id: string | null) => void;
		onChangeDiagram: (id: string, category?: string) => void;
	}
>;

export const DiagramBrowsingForm = ({
	className,
	control,
	selection,
	job,
	category,
	diagram,
	categories,
	highlighted,
	scrollTo,
	isSearching,
	onHighlight,
	onChangeCategory,
	onChangeDiagram,
	...rest
}: DiagramBrowsingFormProps) => {
	return (
		<form className={tlsx('max-h-full overflow-auto', className)} noValidate {...rest}>
			{!isSearching ? (
				<Accordion
					key="accordion"
					className="group flex-1 w-full h-full"
					value={category}
					transitionDuration={0}
					chevronPosition="left"
					chevron={<ChevronRightIcon className="w-4 h-4" />}
					styles={{
						// Change accordion icon to rotate 90deg instead of 180deg
						chevron: {
							'&[data-rotate]': {
								transform: 'rotate(90deg)'
							}
						}
					}}
					onChange={onChangeCategory}
				>
					{categories.map(categoryMap => (
						<DiagramBrowsingAccordionItem
							key={categoryMap.category.id}
							category={category}
							diagram={diagram}
							categoryMap={categoryMap}
							control={control}
							selection={selection}
							job={job}
							highlighted={highlighted}
							onHighlight={onHighlight}
							scrollTo={scrollTo}
							onChangeDiagram={onChangeDiagram}
						/>
					))}
				</Accordion>
			) : (
				categories.map(categoryMap => (
					<DiagramBrowsingSearchItem
						key={categoryMap.category.id}
						category={category}
						diagram={diagram}
						categoryMap={categoryMap}
						control={control}
						selection={selection}
						job={job}
						highlighted={highlighted}
						onHighlight={onHighlight}
						scrollTo={scrollTo}
						onChangeDiagram={onChangeDiagram}
					/>
				))
			)}
		</form>
	);
};

import { DEFAULT_CURRENCY } from './app/utils/currency';
import { getEnvironment } from './environment';

export const getBaseConfig = () => {
	const environment = getEnvironment();
	return {
		webKey: environment.webKey,
		basePath: environment.basePath,
		currencyCode: DEFAULT_CURRENCY,
		vehicle: {
			uvdbRegionId: environment.uvdbRegionId
		},
		sentryDSN: environment.sentryDSN
	};
};

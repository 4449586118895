import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType
} from 'react-router-dom';

import { getBaseConfig } from './config.ts';
import { getEnvironmentName } from './environment.ts';

const config = getBaseConfig();

if (config.sentryDSN === '' || import.meta.env.DEV) {
	console.debug('Not initializing Sentry');
} else {
	Sentry.init({
		dsn: config.sentryDSN,
		environment: getEnvironmentName(),
		integrations: [
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false
			}),
			Sentry.browserProfilingIntegration(),
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			})
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture all transactions
		// Control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [
			/https:\/\/api\.dev[1-8]\.partly\.pro/,
			/https:\/\/api\.partly\.com/,
			/https:\/\/api\.au-2\.storefront\.partly\.com/,
			/https:\/\/api\.region-au-1\.storefront-dev1\.partly\.pro/
		],

		// Ignore network transactions to third party services
		ignoreTransactions: [
			/.*\.mixpanel\.com/,
			/.*\.transifex\.net/,
			/.*\.hotjar\.io/,
			/.*\.hubspot\.com/,
			/.*\.hubapi\.com/,
			/.*\.hscollectedforms\.net/,
			/.*\.hsbanner\.com/
		],

		// Session Replay
		replaysSessionSampleRate: 1.0,
		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0
	});
}

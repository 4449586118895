import { EmptyState } from '@/app/atoms/empty-state';
import SaveButton from '@/app/atoms/save-button';
import {
	VehicleAssemblySelector,
	VehicleCollisionSelector
} from '@/app/features/collisions/components/vehicle-collision-selector';
import {
	CollisionAreaFormData,
	VehicleCollisionAssembly
} from '@/app/features/collisions/components/vehicle-collision-selector/types';
import {
	createCollisionSelection,
	createCollisionSeverities
} from '@/app/features/collisions/components/vehicle-collision-selector/utils';
import { useUpdateCollisions } from '@/app/features/collisions/hooks/use-update-collisions';
import { withSignedIn } from '@/app/hoc/with-access';
import { useAnalytics } from '@/app/hooks/use-analytics';
import { useUnsavedChanges } from '@/app/hooks/use-unsaved-changes';
import { JobActions } from '@/app/pages/job-detail';
import { SplitCollisionTemplate } from '@/app/templates/split-collision-template';
import { RepairhubEvents } from '@/app/utils/analytics/events';
import { Alert } from '@mantine/core';
import { jobsQueries } from '@sdk/react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { sortBy } from 'lodash-es';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

type PageParams = {
	jobId: string;
};

const FORM_ID = 'collision-form';

const CollisionMapPage = () => {
	const { jobId } = useParams<PageParams>();
	if (!jobId) {
		throw new Error('Missing required job id parameter');
	}

	const navigate = useNavigate();
	const { logEvent } = useAnalytics();
	const [{ data: jobData }, { data: collisionMapData, isError: isUnsupportedBodyType }] =
		useSuspenseQueries({
			queries: [jobsQueries.get({ jobId }), jobsQueries.getCollisionMap({ jobId })]
		});

	const { mutateAsync: updateJob } = useUpdateCollisions();

	const { control, watch, formState, handleSubmit } = useForm<CollisionAreaFormData>({
		defaultValues: createCollisionSelection(jobData.job.collisions, jobData.job.addedPartsCount > 0)
	});

	const selection = watch();

	const { isDirty, isSubmitting } = formState;

	const collisions = useMemo(() => {
		const areas = Object.fromEntries(
			collisionMapData.collisionMap.collisionAreas.map(({ id, name, hcas }) => [
				id,
				{ id, name, hcas }
			])
		);

		const collisions = createCollisionSeverities(selection);

		return sortBy(
			collisions.map(({ id, severity }): VehicleCollisionAssembly => {
				const { name } = areas[id];
				return {
					id,
					severity,
					name
				};
			}),
			({ id }) => id
		);
	}, [selection, collisionMapData.collisionMap]);

	const onSubmit = async (data: CollisionAreaFormData) => {
		const collisions = createCollisionSeverities(data);
		await updateJob({ jobId, collisions });
		logEvent(RepairhubEvents.job_collisions_upserted({ job_id: jobId, collisions }));
		navigate(`/job/${jobId}/parts`);
	};

	useUnsavedChanges(isDirty && !isSubmitting);

	return (
		<form id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
			<SplitCollisionTemplate>
				<JobActions>
					<SaveButton
						key="collision-submit"
						type="submit"
						form={FORM_ID}
						disabled={!isDirty || collisions.length === 0}
						submitting={isSubmitting}
					/>
				</JobActions>
				<SplitCollisionTemplate.Collision>
					<VehicleCollisionSelector
						collisionMapId={collisionMapData.collisionMap.id}
						collisionAreas={collisionMapData.collisionMap.collisionAreas}
						control={control}
					/>
				</SplitCollisionTemplate.Collision>
				<SplitCollisionTemplate.Detail>
					{isUnsupportedBodyType ? (
						<div className="grid flex-1 place-items-center">
							<Alert color="red" className="mt-4" title="Unsupported vehicle">
								Vehicle of this body style is currently not supported.
							</Alert>
						</div>
					) : (
						<>
							<h1 className="text-xl font-semibold">Collision regions</h1>
							{collisions.length > 0 ? (
								<VehicleAssemblySelector control={control} collisions={collisions} />
							) : (
								<div className="grid flex-1 place-items-center">
									<EmptyState className="max-w-sm">
										<EmptyState.Title>No mapped damage</EmptyState.Title>
										<EmptyState.Description>
											Select the damaged regions on the vehicle map that require replacement parts.
										</EmptyState.Description>
									</EmptyState>
								</div>
							)}
						</>
					)}
				</SplitCollisionTemplate.Detail>
			</SplitCollisionTemplate>
		</form>
	);
};

export default withSignedIn(CollisionMapPage);

import JobStatusBadge from '@/app/features/jobs/components/job-status-badge';
import { tlsx } from '@/app/utils/tw-merge';
import { linkWithSearchParams } from '@/app/utils/url';
import { InheritableElementProps } from '@/types/utilties';
import { TrashIcon } from '@heroicons/react/24/outline';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import { ActionIcon, Menu } from '@mantine/core';
import { Job, JobStatus } from '@sdk/lib';
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	useReactTable
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useCallback, useDeferredValue, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';

type JobListItemData = {
	vehicle?: {
		name: string;
		plate: string;
	};
	job: {
		id: string;
		label: string;
		createdAt: Date;
	};
	status?: JobStatus;
	collisions: number;
	parts: number;
	orders: number;
};

const remToPixel = (rem: number) =>
	rem * parseFloat(getComputedStyle(document.documentElement).fontSize);

const linkForJobState = (job: Job): string => {
	// missing all required information about vehicle (need either rego or vin)
	if (!job.vehicle || (!job.vehicle.plateNumber && !job.vehicle.chassisNumber)) {
		return linkWithSearchParams('/jobs/create', {
			jobId: job.id,
			jobNumber: job.jobNumber ?? undefined,
			claimNumber: job.claimNumber ?? undefined
		});
	}

	// missing variant (but there's either rego or vin)
	if (!job.vehicle.variant) {
		return linkWithSearchParams('/jobs/create/vehicles', {
			jobId: job.id,
			jobNumber: job.jobNumber ?? undefined,
			claimNumber: job.claimNumber ?? undefined,
			chassisNumber: job.vehicle.chassisNumber ?? undefined,
			plateNumber: job.vehicle.plateNumber ?? undefined,
			plateState: job.vehicle.plateState ?? undefined
		});
	}

	return `/job/${job.id}`;
};

const columnHelper = createColumnHelper<JobListItemData>();

type VirtualizedJobTableProps = {
	jobs: Job[];
	hasNextPage: boolean;
	isNextPageLoading: boolean;
	loadNextPage: () => void;
	onDelete: (job: Job) => void;
	size: Size;
};

const VirtualizedJobTable = ({
	jobs,
	hasNextPage,
	isNextPageLoading,
	loadNextPage,
	onDelete,
	size
}: VirtualizedJobTableProps) => {
	// compute the size of each job list item
	// the size of each list item is a fixed size but in rem not in pixels
	const itemSize = remToPixel(6);

	// We need a reference to the scrolling element for logic down below
	const tableContainerRef = useRef<HTMLDivElement>(null);

	const [scrollOffset, setScrollOffset] = useState(0);
	const deferredOffset = useDeferredValue(scrollOffset);

	const data = useMemo(
		() =>
			jobs.map(
				(job): JobListItemData => ({
					vehicle: job.vehicle
						? {
								name: job.vehicle.variant?.description ?? 'Unknown vehicle',
								plate: job.vehicle.plateNumber ?? job.vehicle.chassisNumber ?? '-'
							}
						: undefined,
					job: {
						id: job.id,
						label: job.jobNumber ?? '-',
						createdAt: new Date(job.createdAt)
					},
					status: job.status,
					collisions: job.collisions.length,
					parts: job.addedPartsCount,
					orders: job.cartItemsCount
				})
			),
		[jobs]
	);

	const columns = useMemo(
		() => [
			columnHelper.accessor('vehicle', {
				id: 'vehicle',
				cell: info => {
					const variant = info.getValue();
					const job = jobs[info.row.index];
					const link = linkForJobState(job);

					if (!variant) {
						return (
							<Link
								className="text-base font-semibold text-gray-600"
								to={link}
								data-testid={`job-row-vehicle-${job.id}`}
							>
								No Vehicle selected
							</Link>
						);
					}

					return (
						<Link to={link} data-testid={`job-row-vehicle-${job.id}`}>
							<div className="max-w-full text-base font-semibold text-gray-600 truncate">
								{variant.name}
							</div>
							<div className="text-sm text-gray-600">{variant.plate.toUpperCase()}</div>
						</Link>
					);
				},
				header: () => <span className="text-sm font-semibold text-gray-600">Vehicle</span>,
				minSize: size.width / 4
			}),
			columnHelper.accessor('job', {
				id: 'job',
				cell: info => {
					const { label, createdAt } = info.getValue();
					const job = jobs[info.row.index];
					const link = linkForJobState(job);

					return (
						<Link to={link} data-testid={`job-row-date-${job.id}`}>
							<div className="text-gray-600 truncate">{label}</div>
							<div className="text-sm text-gray-600">
								{new Date(createdAt).toLocaleDateString('en-NZ')}
							</div>
						</Link>
					);
				},
				header: () => <span className="text-sm font-semibold text-gray-600">Job</span>,
				minSize: size.width / 8
			}),
			columnHelper.accessor('status', {
				id: 'status',
				cell: info => {
					const status = info.getValue();
					const job = jobs[info.row.index];
					return (
						<Link
							to={`/job/${job.id}/orders`}
							className="flex items-center w-full h-full"
							data-testid={`job-row-status-${job.id}`}
						>
							<JobStatusBadge status={status} />
						</Link>
					);
				},
				header: () => <span className="text-sm font-semibold text-gray-600">Status</span>,
				size: size.width / 12
			}),
			columnHelper.accessor('collisions', {
				id: 'collisions',
				cell: info => {
					const collisions = info.getValue();
					return (
						<div className="flex items-center justify-end h-full text-gray-600">
							{collisions > 0 ? collisions : '-'}
						</div>
					);
				},
				header: () => (
					<span className="ml-auto text-sm font-semibold text-gray-600">Collision</span>
				),
				size: size.width / 16
			}),
			columnHelper.accessor('parts', {
				id: 'parts',
				cell: info => {
					const parts = info.getValue();
					return (
						<div className="flex items-center justify-end h-full text-gray-600">
							{parts > 0 ? parts : '-'}
						</div>
					);
				},
				header: () => <span className="ml-auto text-sm font-semibold text-gray-600">Parts</span>,
				size: size.width / 16
			}),
			columnHelper.accessor('orders', {
				id: 'order',
				cell: info => {
					const orders = info.getValue();
					return (
						<div className="flex items-center justify-end h-full text-gray-600">
							{orders > 0 ? orders : '-'}
						</div>
					);
				},
				header: () => <span className="ml-auto text-sm font-semibold text-gray-600">Order</span>,
				size: size.width / 16
			}),
			columnHelper.display({
				id: 'actions',
				cell: ({ row }) => {
					const job = jobs[row.index];
					return (
						<div className="flex items-center justify-end h-full text-gray-600">
							<Menu shadow="lg" width="12rem" position="left">
								<Menu.Target>
									<ActionIcon>
										<EllipsisHorizontalIcon
											className="w-5 h-5"
											data-testid={`job-delete-menu-${job.id}`}
										/>
									</ActionIcon>
								</Menu.Target>

								<Menu.Dropdown>
									<Menu.Item
										color="red"
										icon={<TrashIcon className="w-6 h-6" />}
										onClick={() => onDelete(job)}
										data-testid={`job-delete-${job.id}`}
									>
										Delete job
									</Menu.Item>
								</Menu.Dropdown>
							</Menu>
						</div>
					);
				},
				size: size.width / 16
			})
		],
		[size]
	);

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel()
	});

	const { rows } = table.getRowModel();
	const headerGroups = table.getHeaderGroups();

	const rowVirtualizer = useVirtualizer({
		count: rows.length,
		estimateSize: () => itemSize,
		getScrollElement: () => tableContainerRef.current,
		measureElement:
			typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
				? element => element?.getBoundingClientRect().height
				: undefined,
		overscan: 5
	});

	const virtualRows = rowVirtualizer.getVirtualItems();

	const loadOnEndReached = useCallback(
		(containerRefElement?: HTMLDivElement | null) => {
			if (containerRefElement) {
				// load when reaching within 5 rows to the bottom
				const threshold = itemSize * 5;
				const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
				const distanceToBottom = scrollHeight - scrollTop - clientHeight;
				if (distanceToBottom < threshold && !isNextPageLoading && hasNextPage) {
					loadNextPage();
				}
			}
		},
		[loadNextPage, isNextPageLoading, hasNextPage, itemSize]
	);

	const onContainerScroll = useCallback(
		(event: React.UIEvent<HTMLDivElement, UIEvent>) => {
			if (event.target instanceof HTMLDivElement) {
				loadOnEndReached(event.target);
				setScrollOffset(event.target.scrollTop);
			}
		},
		[loadOnEndReached]
	);

	useEffect(() => {
		loadOnEndReached(tableContainerRef.current);
	}, [loadOnEndReached]);

	return (
		<div
			className={tlsx('relative overflow-auto')}
			ref={tableContainerRef}
			onScroll={onContainerScroll}
			style={{
				...size
			}}
		>
			<table className="relative grid w-full h-auto">
				<thead
					className={tlsx('grid sticky top-0 z-10 w-full bg-white', {
						'border-b border-gray-200 shadow-lg shadow-black/5': deferredOffset > 0
					})}
				>
					{headerGroups.map(({ id, headers }) => (
						<tr key={id} className="flex justify-around w-full px-10 py-3 bg-white border-b">
							{headers.map(header => (
								<th
									key={header.id}
									className="flex items-center"
									style={{ width: header.column.getSize() }}
								>
									{flexRender(header.column.columnDef.header, header.getContext())}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody className="relative grid w-full" style={{ height: rowVirtualizer.getTotalSize() }}>
					{virtualRows.map(virtualRow => {
						const row = rows[virtualRow.index];
						const visibleCells = row.getVisibleCells();
						return (
							<tr
								key={row.id}
								className="absolute flex justify-around w-full max-w-full px-10 py-6 border-b border-gray-200"
								ref={node => rowVirtualizer.measureElement(node)}
								data-testid={`job-row-${row.original.job.id}`}
								data-index={virtualRow.index}
								data-job-row-id={row.original.job.id}
								style={{ transform: `translateY(${virtualRow.start}px)` }}
							>
								{visibleCells.map(cell => (
									<td
										className="flex flex-col justify-center z-[5]"
										key={cell.id}
										style={{
											width: cell.column.getSize()
										}}
									>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

type JobTableProps = InheritableElementProps<'div', Omit<VirtualizedJobTableProps, 'size'>>;

const JobTable = ({
	jobs,
	hasNextPage,
	isNextPageLoading,
	loadNextPage,
	onDelete,
	className,
	...rest
}: JobTableProps) => {
	return (
		<div className={tlsx('flex-1 pb-safe-offset-1', className)} {...rest}>
			<AutoSizer>
				{size => (
					<VirtualizedJobTable
						jobs={jobs}
						hasNextPage={hasNextPage}
						isNextPageLoading={isNextPageLoading}
						loadNextPage={loadNextPage}
						onDelete={onDelete}
						size={size}
					/>
				)}
			</AutoSizer>
		</div>
	);
};

export default JobTable;

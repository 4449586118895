import {
	GetJobSupplyRecommendationsResult,
	JobPart,
	PartSupplyOffer,
	SupplyVendor
} from '@/sdk/lib';
import { isDefined } from '@/sdk/lib/utils/object';
import { draft_order, part_slot } from '@/sdk/reflect/reflect';
import { match } from '@/types/match';
import { cloneDeep } from 'lodash-es';
import { UseFormReturn } from 'react-hook-form';
import { v4 } from 'uuid';
import {
	AddItemPayload,
	DraftOrderItemModel,
	DraftOrderSelection,
	JobPartItemSelection,
	OrderRequestModel
} from './models';
import { compareOfferIds, createPartSelectionContexts } from './supply';

export const restoreOrderRequestModel = (
	request: draft_order.exp.DraftOrder
): OrderRequestModel => {
	const items: DraftOrderItemModel[] = request.items.map(item => {
		return {
			...item,
			id: item.id,
			local_id: item.id,
			buyable: createBuyableOffer(item.buyable)
		};
	});

	return {
		...request,
		// We still use a v4 id here to avoid the situation where user
		// input messes with updating nested objects (e.g. full stops).
		local_id: v4(),
		order_id: request.id ?? null,
		items
	};
};

export const createOrderRequestItem = (
	offer: PartSupplyOffer,
	context: part_slot.exp.PartSelectionContexts | null,
	quantity: number
): DraftOrderItemModel => {
	return {
		id: null,
		local_id: v4(),
		status: 'Pending',
		order_separately: false,
		arrival_at: offer.shipping?.eta ? new Date(offer.shipping?.eta).valueOf() : null,
		context,
		quantity,
		price: offer.price.price,
		grade: offer.grade ?? null,
		buyable: {
			type: 'Listing',
			offer: {
				type: 'Product',
				offer_id: offer.offer.type === 'Product' ? offer.offer.offerId : '',
				listing_id: offer.offer.listing.id
			}
		}
	};
};

const createBuyableOffer = (
	buyable: draft_order.exp.DraftOrderItemBuyable
): draft_order.DraftOrderItemBuyable => {
	if (buyable.type === 'Listing') {
		if (buyable.offer.type === 'Product') {
			return {
				type: 'Listing',
				offer: {
					type: 'Product',
					offer_id: buyable.offer.offer_id,
					listing_id: buyable.offer.listing.id
				}
			};
		}

		return {
			type: 'Listing',
			offer: {
				type: 'Kit',
				listing_id: buyable.offer.listing.id,
				offer_ids: buyable.offer.offer_ids
			}
		};
	}

	return {
		type: 'External',
		description: buyable.description,
		identity: buyable.identity
	};
};

export const createItemContext = (
	part: JobPart | null | undefined
): part_slot.exp.PartSelectionContexts | null => {
	if (!part) {
		return null;
	}

	const context: part_slot.exp.PartSelectionContext = {
		description: part.description,
		mpn: part.mpn,
		gapc_brand: null,
		gapc_part_type: null,
		gapc_position: null
	};

	if (part.partSlot?.gapcPartType) {
		context.gapc_part_type = {
			id: part.partSlot.gapcPartType.id,
			name: part.partSlot.gapcPartType.name,
			aliases: [],
			categorizations: [],
			gapc_properties: [],
			uvdb_property_prefixes: []
		};
	}

	if (part.partSlot?.gapcPosition) {
		context.gapc_position = {
			id: part.partSlot.gapcPosition.id,
			name: part.partSlot.gapcPosition.name
		};
	}

	if (part.gapcBrand) {
		context.gapc_brand = {
			id: part.gapcBrand.id,
			name: part.gapcBrand.name,
			is_oem: part.gapcBrand.isOem
		};
	}

	return [context];
};

export const createNewOrderRequest = (
	vendor: SupplyVendor,
	items: DraftOrderItemModel[]
): OrderRequestModel => {
	return {
		status: 'Draft',
		created_at: new Date().valueOf(),
		updated_at: null,
		local_id: vendor.id,
		vendor: {
			Partner: vendor
		},
		target_deliver_before_timestamp: new Date().valueOf(),
		vendor_notes: null,
		items,
		estimator_notes: null,
		images: [],
		order_id: null
	};
};

export const applyOfferSelection = (
	prev: JobPartItemSelection | null,
	next: JobPartItemSelection,
	form: UseFormReturn<DraftOrderSelection>,
	recommendations: GetJobSupplyRecommendationsResult
) => {
	const selection = form.getValues();
	const nextOffer = recommendations.offers[next.offerId];
	if (!nextOffer) {
		return;
	}

	// Step 1: If we have an existing selection for the part, we
	// need to remove it from that order.
	if (prev) {
		const prevOffer = recommendations.offers[prev.offerId];
		if (prevOffer) {
			const existingRequest = Object.values(selection.draft_orders).find(
				order => order.vendor.Partner.id === prevOffer.vendor.id && order.status === 'Draft'
			);

			if (existingRequest) {
				const existingItem = existingRequest.items.findIndex(
					item =>
						item.buyable.type === 'Listing' && compareOfferIds(prevOffer.offer, item.buyable.offer)
				);

				if (existingItem !== -1) {
					existingRequest.items.splice(existingItem, 1);
					if (existingRequest.items.length === 0) {
						delete selection.draft_orders[existingRequest.local_id];
						form.setValue(`draft_orders`, selection.draft_orders);
					} else {
						form.setValue(`draft_orders.${existingRequest.local_id}`, existingRequest);
					}
				}
			}
		}
	}

	// Step 2: Check if we already have a draft order for this vendor
	// that we can re-use.
	const existingRequest = Object.values(selection.draft_orders).find(
		order => order.vendor.Partner.id === nextOffer.vendor.id && order.status === 'Draft'
	);

	// Step 3: Find the job part that this offer is for, we need this for
	// the context + default quantity.
	const jobPart = recommendations.parts.find(
		part => part.part.partIdentity === nextOffer.gapcPart.partIdentity
	);

	// Step 4: Create a new item for the draft order
	const context = createItemContext(jobPart?.part);
	const newItem = createOrderRequestItem(nextOffer, context, next.quantity);

	// Step 5: If we don't have an existing request, create a new one
	// with a single item.
	if (!existingRequest) {
		const newOrder = createNewOrderRequest(nextOffer.vendor, [newItem]);
		form.setValue(`draft_orders.${newOrder.local_id}`, newOrder);
		return;
	}

	// Step 6: If we do have an existing request, then we need to update.
	// Start by finding the existing item, supply will always be a listing
	// so we can compare by the offer id.
	const existingItem = existingRequest.items.findIndex(
		item => item.buyable.type === 'Listing' && compareOfferIds(nextOffer.offer, item.buyable.offer)
	);

	// Step 7: If it is a new item then add it and return early.
	if (existingItem == -1) {
		existingRequest.items.push(newItem);
		form.setValue(`draft_orders.${existingRequest.local_id}`, existingRequest);
		return;
	}

	// Step 8: Remove the item if the quantity is 0, then validate
	// that the order can still exist.
	if (next.quantity === 0) {
		existingRequest.items.splice(existingItem, 1);

		if (existingRequest.items.length === 0) {
			delete selection.draft_orders[existingRequest.local_id];
		}

		form.setValue(`draft_orders`, selection.draft_orders);
		return;
	}

	// Step 9: Lastly update the quantity of the existing item
	// and update the form.
	existingRequest.items[existingItem] = {
		...existingRequest.items[existingItem],
		quantity: next.quantity
	};

	form.setValue(`draft_orders.${existingRequest.local_id}`, existingRequest);
};

export const getLatestDate = (dates: Date[]): Date => {
	return dates.reduce((acc, date) => {
		if (date > acc) {
			return date;
		}

		return acc;
	}, dates[0]);
};

export const restoreOrderSelection = (
	selection: DraftOrderSelection,
	draft_orders: draft_order.exp.DraftOrder[]
): DraftOrderSelection => {
	for (const draftOrder of draft_orders) {
		selection.draft_orders[draftOrder.id] = restoreOrderRequestModel(draftOrder);
	}

	/// We only want to attempt auto transition if all the
	// draft orders are in a state that can be auto transitioned.
	selection.attempt_auto_transition_order = draft_orders.every(
		order =>
			order.attempt_auto_transition_order.enabled &&
			match(order.status, {
				Draft: () => true,
				Processing: () => true,
				Processed: () => true,
				Finalised: () => false,
				Cancelled: () => false
			})
	);

	// We want to set the delivery date to
	// the latest possible date that isn't finalised.
	const dates = draft_orders.flatMap(order => {
		if (order.status === 'Finalised') {
			return [];
		}

		const itemDates = order.items
			.filter(item => !item.order_separately)
			.map(item => (item.arrival_at ? new Date(item.arrival_at) : new Date()));
		itemDates.push(new Date(order.target_deliver_before_timestamp));

		return itemDates;
	});

	const latestDate = getLatestDate(dates);
	if (latestDate > new Date()) {
		selection.delivery_date = latestDate;
	}

	return selection;
};

export const getLatestPossibleDeliveryDateFromSelection = (
	selection: DraftOrderSelection
): Date => {
	const dates = Object.values(selection.draft_orders)
		.filter(order => order.status !== 'Finalised')
		.flatMap(order => {
			const itemDates = order.items
				.filter(item => !item.order_separately)
				.map(item => (item.arrival_at ? new Date(item.arrival_at) : new Date()));

			return itemDates;
		});

	return getLatestDate(dates);
};

export const createSelectionFromSupply = (
	selection: DraftOrderSelection,
	recommendation: GetJobSupplyRecommendationsResult
): DraftOrderSelection => {
	const offersByVendor = recommendation.recommendations[0].offers.reduce(
		(acc, offerId) => {
			const offer = recommendation.offers[offerId];
			if (!offer) {
				return acc;
			}

			const vendorId = offer.vendor.id;
			if (!acc[vendorId]) {
				acc[vendorId] = { vendor: offer.vendor, offers: [] };
			}

			acc[vendorId].offers.push(offer);

			return acc;
		},
		{} as Record<string, { vendor: SupplyVendor; offers: PartSupplyOffer[] }>
	);

	const jobPartMap = recommendation.parts.reduce(
		(acc, jobPart) => {
			acc[jobPart.part.partIdentity] = jobPart.part;
			return acc;
		},
		{} as Record<string, JobPart>
	);

	const dates: Date[] = [];
	for (const vendorOffers of Object.values(offersByVendor)) {
		const items = vendorOffers.offers.map(offer => {
			const jobPart = jobPartMap[offer.gapcPart.partIdentity];
			const context = createItemContext(jobPart);
			const item = createOrderRequestItem(offer, context, jobPart?.quantity ?? 1);

			dates.push(new Date(item.arrival_at ?? new Date()));
			return item;
		});

		const newOrderRequest = createNewOrderRequest(vendorOffers.vendor, items);
		selection.draft_orders[newOrderRequest.local_id] = newOrderRequest;
	}

	const latestDate = getLatestDate(dates);
	if (latestDate > new Date()) {
		selection.delivery_date = latestDate;
	}

	return selection;
};

export const onAddExternalItem = (
	selection: DraftOrderSelection,
	data: AddItemPayload,
	form: UseFormReturn<DraftOrderSelection>
) => {
	const existingOrder = Object.values(selection.draft_orders).find(
		order => order.vendor.Partner.id == data.vendor.id && order.status === 'Draft'
	);
	if (!existingOrder) {
		const newOrder = createNewOrderRequest(data.vendor, [data.item]);
		form.setValue(`draft_orders.${data.vendor.id}`, newOrder);
		return;
	}

	existingOrder.items.push(data.item);
	form.setValue(`draft_orders.${existingOrder.local_id}`, existingOrder);
};

type RemoteOrderIds = Map<string, Map<string, string>>;

// todo: this method could do with a refactor
export const buildIngestFromSelection = (
	selection: DraftOrderSelection,
	remoteRequests: draft_order.exp.DraftOrder[],
	supplyHashId: string
): draft_order.DraftOrderIngest[] => {
	// If we have any draft orders, only send those to the server.
	// Otherwise, send everything.
	let values = Object.values(selection.draft_orders);
	const hasDrafts = values.some(order => order.status === 'Draft');
	values = values.filter(order => {
		if (hasDrafts) {
			return order.status === 'Draft';
		}

		return true;
	});

	remoteRequests = remoteRequests.filter(order => {
		if (hasDrafts) {
			return order.status === 'Draft';
		}

		return true;
	});

	const remoteIds = remoteRequests.reduce((acc, request) => {
		if (request.status !== 'Draft') {
			return acc;
		}

		acc.set(
			request.id,
			request.items.reduce((acc, item) => {
				acc.set(item.id, item.id);
				return acc;
			}, new Map<string, string>())
		);

		return acc;
	}, new Map() as RemoteOrderIds);

	const ingests: draft_order.DraftOrderIngest[] = [];

	for (const orderRequest of values) {
		// Draft orders are either updates or inserts
		match(orderRequest.status, {
			Draft: () => {
				if (orderRequest.order_id) {
					const items: draft_order.DraftOrderItemIngest[] = orderRequest.items.map(item => {
						const commonItem = {
							arrival_at: item.arrival_at,
							context: createPartSelectionContexts(item.context),
							grade: item.grade,
							price: item.price,
							quantity: item.quantity
						};

						if (item.id) {
							remoteIds.get(orderRequest.local_id)?.delete(item.id);
							return <draft_order.DraftOrderItemIngest>{
								Update: { id: item.id, ...commonItem }
							};
						}
						return <draft_order.DraftOrderItemIngest>{
							Insert: {
								...commonItem,
								buyable: item.buyable
							}
						};
					});

					const itemsToDelete = Array.from(remoteIds.get(orderRequest.order_id)?.keys() ?? []);
					for (const toDelete of itemsToDelete) {
						items.push({ Remove: toDelete });
					}

					remoteIds.delete(orderRequest.order_id);

					ingests.push({
						Update: {
							id: orderRequest.order_id,
							attempt_auto_transition_order: selection.attempt_auto_transition_order,
							target_deliver_before_timestamp: selection.delivery_date.valueOf(),
							estimator_notes: orderRequest.estimator_notes,
							supply_hash_id: supplyHashId,
							images: [],
							items
						}
					});
				} else {
					// If the order is new, we don't need to check any of the ids
					ingests.push({
						Insert: {
							attempt_auto_transition_order: selection.attempt_auto_transition_order,
							estimator_notes: orderRequest.estimator_notes,
							supply_hash_id: supplyHashId,
							vendor: {
								Partner: orderRequest.vendor.Partner.id
							},
							target_deliver_before_timestamp: selection.delivery_date.valueOf(),
							items: orderRequest.items.map(item => {
								const insert: draft_order.DraftOrderItemInsert = {
									buyable: item.buyable,
									arrival_at: item.arrival_at,
									context: createPartSelectionContexts(item.context),
									grade: item.grade,
									price: item.price,
									quantity: item.quantity
								};

								return insert;
							})
						}
					});
				}
			},
			Cancelled: () => {
				/** no-op. Cancels are done immedietly  */
			},
			Processing: () => {
				/** no-op. No updates for processing */
			},
			Processed: () => {
				const areAllRejected = orderRequest.items.every(item => {
					return match(item.status, {
						Pending: () => false,
						Approved: () => false,
						Rejected: () => true
					});
				});
				// todo: cleanup, also we need to do something to
				// orders that are all rejected (cancelled maybe)?
				if (!areAllRejected && orderRequest.order_id) {
					ingests.push({
						Finalise: {
							id: orderRequest.order_id,
							target_deliver_before_timestamp: selection.delivery_date.valueOf(),
							items: orderRequest.items
								.map(item =>
									match<
										draft_order.DraftOrderItemStatus,
										draft_order.DraftOrderItemFinalise | null
									>(item.status, {
										Pending: () => null,
										Approved: ({ reason, details }) => {
											const finalise: draft_order.DraftOrderItemFinalise = {
												id: item.local_id,
												order_separately: item.order_separately,
												quantity: item.quantity,
												status: undefined,
												status_detail: undefined
											};

											match(reason, {
												Estimator: () => {
													finalise.status = 'Approved';
													finalise.status_detail = details;
												},
												Supplier: () => {
													/** no-op, remote is already correct */
												}
											});

											return finalise;
										},
										Rejected: ({ details, reason }) => {
											const finalise: draft_order.DraftOrderItemFinalise = {
												id: item.local_id,
												order_separately: item.order_separately,
												quantity: item.quantity,
												status: undefined,
												status_detail: undefined
											};

											match(reason, {
												Estimator: reason => {
													finalise.status = {
														Rejected: reason
													};
													finalise.status_detail = details;
												},
												Supplier: () => {
													/** no-op, remote is already correct */
												}
											});

											return finalise;
										}
									})
								)
								.filter(isDefined)
						}
					});
				}
			},
			Finalised: () => {
				/** no-op. End of flow  */
			}
		});
	}

	const ordersToDelete = Array.from(remoteIds.keys());
	for (const toDelete of ordersToDelete) {
		ingests.push({ Remove: toDelete });
	}

	return ingests;
};

export const onRecommendationSelect = (
	offerIds: string[],
	recommendations: GetJobSupplyRecommendationsResult,
	draftOrders: Record<string, OrderRequestModel>
) => {
	const selection = cloneDeep(draftOrders);

	for (const draftOrder of Object.values(selection)) {
		if (draftOrder.status !== 'Draft') {
			continue;
		}

		draftOrder.items = draftOrder.items.filter(item => item.buyable.type !== 'Listing');
	}

	for (const offerId of offerIds) {
		const offer = recommendations.offers[offerId];
		if (!offer) {
			continue;
		}

		// Step 2: Check if we already have a draft order for this vendor
		// that we can re-use.
		const existingRequest = Object.values(selection).find(
			order => order.vendor.Partner.id === offer.vendor.id && order.status === 'Draft'
		);

		// Step 3: Find the job part that this offer is for, we need this for
		// the context + default quantity.
		const jobPart = recommendations.parts.find(
			part => part.part.partIdentity === offer.gapcPart.partIdentity
		);

		// Step 4: Create a new item for the draft order
		const context = createItemContext(jobPart?.part);
		const newItem = createOrderRequestItem(offer, context, jobPart?.part.quantity ?? 1);

		// Step 5: If we don't have an existing request, create a new one
		// with a single item.
		if (!existingRequest) {
			const newOrder = createNewOrderRequest(offer.vendor, [newItem]);
			selection[newOrder.local_id] = newOrder;
			continue;
		}

		existingRequest.items.push(newItem);
	}

	for (const draftOrder of Object.values(selection)) {
		if (draftOrder.items.length === 0) {
			delete selection[draftOrder.local_id];
		}
	}

	return selection;
};

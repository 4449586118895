import { GapcPartIdentity, PartSlotIds } from '@sdk/lib';
import { createContext, useContext, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { PartInterpretationFormData } from '../../types';

export type GapcSelection = {
	partSlotIds: PartSlotIds | null;
	quantity: number;
	description?: string | null;
} & GapcPartIdentity;

type SelectionContext = {
	selection: PartInterpretationFormData | null;
	setSelection: (selection: PartInterpretationFormData | null) => void;
};

const SelectionContext = createContext<SelectionContext>(
	// Lies, but we resolve this before any children
	// are used.
	{} as SelectionContext
);

export const usePartInterpretationSelection = () => {
	const context = useContext(SelectionContext);

	const capture = (selection: PartInterpretationFormData) => {
		context.setSelection(selection);
	};

	const reset = () => context.setSelection(null);

	return {
		selection: context.selection,
		capture,
		reset
	};
};

// This provider is used to store selection between pages
// related to parts interpretation.
export const PartIntepretationSelectionProvider = () => {
	const [selection, setSelection] = useState<PartInterpretationFormData | null>(null);
	const context = useMemo(() => ({ selection, setSelection }), [selection, setSelection]);

	return (
		<SelectionContext.Provider value={context}>
			<Outlet />
		</SelectionContext.Provider>
	);
};

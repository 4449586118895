import { isDefined } from '@/app/utils/common';
import { createPartSlotIds } from '@/app/utils/part';
import { PartAssembly, PartSubAssembly } from '@/sdk/lib';
import { compact, isNil, sortBy, uniqBy } from 'lodash-es';
import { DisplayableAssembly, DisplayableBrowsingDiagram, DisplayableDiagram } from '../types';
import { getPartAssemblySearchables } from './search';
import { alphabeticSortKey, confidenceSortKey } from './sort';
import {
	isSharingDiagramPartSlot,
	mapFitmentDifference,
	partitionVariants,
	transformNotes
} from './variants';

// Transform part sub assemblies into displayable information
export const transformSubPartAssemblies = (
	subAssemblies: PartSubAssembly[]
): DisplayableAssembly[] =>
	compactAssemblies(subAssemblies.flatMap(subAssembly => transformSubPartAssembly(subAssembly)));

// Transform part  assemblies into displayable information
export const transformPartAssemblies = (assemblies: PartAssembly[]): DisplayableAssembly[] =>
	compactAssemblies(assemblies.flatMap(assembly => transformPartAssembly(assembly)));

// Transform a part sub assembly into displayable information
export const transformSubPartAssembly = (
	subAssembly: PartSubAssembly
): (DisplayableAssembly | null)[] => {
	if (subAssembly.kind === 'single') {
		return [transformPartAssembly(subAssembly.assembly)];
	}

	const variants = uniqBy(
		subAssembly.variants.filter(({ part }) => !isNil(part)),
		({ id }) => id
	);

	if (variants.length === 1) {
		return [transformPartAssembly(variants[0])];
	}

	// todo (remove later): temp fix for issues with part slot (part type and location) classification
	// partition variants if they share or not a diagram part slot / location
	const partitions = partitionVariants(variants);

	return partitions.map(variants =>
		variants.length === 1
			? transformPartAssembly(variants[0])
			: transformPartAssemblyVariants(variants)
	);
};

// Transform a single part assembly into displayable information
export const transformPartAssembly = (assembly: PartAssembly): DisplayableAssembly | null => {
	const assemblies = sortBy(
		transformSubPartAssemblies(assembly.subAssemblies),
		confidenceSortKey,
		alphabeticSortKey
	);

	const diagrams =
		assembly.diagrams?.map(({ id, image }) => ({ id, imageUrl: image.thumb })) ??
		assemblies.flatMap(({ diagrams }) => diagrams);

	const within = {
		assemblies: [...assemblies.flatMap(({ within }) => within.assemblies), assembly.id],
		parts: [
			...assemblies.flatMap(({ within }) => within.parts),
			...(assembly.part?.partIdentity ? [assembly.part.partIdentity] : [])
		]
	};

	const searchables = getPartAssemblySearchables(assembly);

	// todo (remove later): showing fitment information for non-variant parts sharing diagram part code likely fixed in the future
	const sharingDiagramPartSlot = isSharingDiagramPartSlot(assembly);
	const fitment = sharingDiagramPartSlot ? transformNotes(assembly.fitment) : null;
	const attributes = sharingDiagramPartSlot ? transformNotes(assembly.attributes) : null;

	// checking if the node needed to be removed (either because it's a dead end)
	const noPartsWithinTree = within.parts.length === 0;
	if (noPartsWithinTree) {
		return null;
	}

	return flattenNestedAssembly({
		kind: 'single',
		id: assembly.id,
		description: assembly.description,
		part: assembly.part,
		confidence: assembly.confidence,
		partSlot: assembly.partSlot,
		partSlotIds: createPartSlotIds(assembly.partSlot),
		availability: assembly.supply?.availability,
		grades: assembly.supply?.grades,
		shipping: assembly.supply?.shipping,
		diagrams,
		assemblies,
		within,
		fitment,
		attributes,
		searchables
	});
};

// Transform a variant of part assemblies into displayable information
export const transformPartAssemblyVariants = (
	variants: PartAssembly[]
): DisplayableAssembly | null => {
	const assemblies = sortBy(
		transformSubPartAssemblies(variants.flatMap(({ subAssemblies }) => subAssemblies)),
		confidenceSortKey,
		alphabeticSortKey
	);

	const assemblyVariants = compact(
		mapFitmentDifference(variants).map(
			({ part, partSlot, description, quantityRequired, supply, ...rest }) => {
				if (!part) {
					return null;
				}
				return {
					part,
					description,
					partSlot,
					partSlotIds: createPartSlotIds(partSlot),
					availability: supply?.availability,
					grades: supply?.grades,
					shipping: supply?.shipping,
					...rest
				};
			}
		)
	);

	const id = assemblyVariants.map(({ id }) => id).join('/');

	const diagrams = variants
		.flatMap(({ diagrams }) => diagrams ?? [])
		.map(({ id, image }) => ({ id, imageUrl: image.thumb }));

	const within = {
		assemblies: [
			...assemblies.flatMap(({ within }) => within.assemblies),
			...variants.map(({ id }) => id)
		],
		parts: [
			...assemblies.flatMap(({ within }) => within.parts),
			...variants.map(({ part }) => part?.partIdentity).filter(isDefined)
		]
	};

	const searchables = variants.flatMap(getPartAssemblySearchables);

	// checking if the node needed to be removed (either because it's a dead end or not relevant to search)
	const noPartsWithinTree = within.parts.length === 0;
	if (noPartsWithinTree) {
		return null;
	}

	return flattenNestedAssembly({
		kind: 'variants',
		id,
		assemblies,
		within,
		searchables,
		description: variants[0].description,
		diagrams: diagrams.length > 0 ? diagrams : assemblies.flatMap(({ diagrams }) => diagrams),
		variants: assemblyVariants
	});
};

export const flattenNestedAssembly = (assembly: DisplayableAssembly): DisplayableAssembly => {
	const hasSubAssemblies = assembly.assemblies.length > 0;
	const isItsOwnPart = assembly.kind === 'single' ? assembly.part : assembly.variants.length > 0;

	if (!hasSubAssemblies || !isItsOwnPart) {
		return assembly;
	}

	const childAssembly: DisplayableAssembly = {
		...assembly,
		assemblies: [],
		within: {
			assemblies: [assembly.id],
			parts: compact(
				assembly.kind === 'variants'
					? assembly.variants.map(({ part }) => part?.partIdentity)
					: [assembly.part?.partIdentity]
			)
		}
	};

	// check if adding parent as child is unecessary if it's repeated already
	const isChildAlready = childAssembly.within.parts.every(partIdentity =>
		assembly.assemblies.flatMap(({ within }) => within.parts).includes(partIdentity)
	);

	const parentId = `${assembly.id}-0`;
	return {
		kind: 'single',
		id: parentId,
		description: assembly.description,
		diagrams: assembly.diagrams,
		partSlotIds: null,
		searchables: assembly.searchables,
		assemblies: isChildAlready ? assembly.assemblies : [childAssembly, ...assembly.assemblies],
		within: {
			assemblies: [...assembly.within.assemblies, parentId],
			parts: assembly.within.parts
		}
	};
};

// transform diagram into displayable information with matching assembly sub-trees
export const transformBrowsingDiagram = (
	diagram: DisplayableDiagram,
	assemblies: DisplayableAssembly[]
): DisplayableBrowsingDiagram | null => {
	const relevantParts = new Set(
		diagram.partSlots
			.flatMap(({ parts }) => parts)
			.map(({ partIdentity }) => partIdentity)
			.filter(isDefined)
	);

	const diagramAssemblies = uniqByParts(filterRelevantAssemblies(assemblies, relevantParts));
	if (diagramAssemblies.length === 0) {
		return null;
	}
	return {
		...diagram,
		assemblies: diagramAssemblies
	};
};

// filter assembly recursively by a set of part identities
export const filterRelevantAssemblies = (
	assemblies: DisplayableAssembly[],
	relevantParts: Set<string>
): DisplayableAssembly[] => {
	return assemblies
		.filter(({ within }) => within.parts.some(id => relevantParts.has(id)))
		.map(({ assemblies, ...rest }) => ({
			...rest,
			assemblies: filterRelevantAssemblies(assemblies, relevantParts)
		}));
};

// recursively unique assemblies by part identities
const uniqByParts = (
	assemblies: DisplayableAssembly[],
	seenParts: Set<string> = new Set()
): DisplayableAssembly[] => {
	const filtered: DisplayableAssembly[] = [];
	for (const assembly of assemblies) {
		if (assembly.kind === 'single' && assembly.part) {
			if (seenParts.has(assembly.part.partIdentity)) {
				continue;
			}
			seenParts.add(assembly.part.partIdentity);
		}

		if (
			assembly.kind === 'variants' &&
			assembly.variants.every(({ part }) => seenParts.has(part.partIdentity))
		) {
			continue;
		}

		const subAssemblies = uniqByParts(assembly.assemblies, seenParts);
		const isItsOwnPart =
			assembly.kind === 'single' ? !isNil(assembly.part) : assembly.variants.length > 0;

		if (subAssemblies.length > 0 || isItsOwnPart) {
			filtered.push({
				...assembly,
				assemblies: subAssemblies
			});
		}
	}
	return filtered;
};

const compactAssemblies = (assemblies: (DisplayableAssembly | null)[]) =>
	uniqBy(compact(assemblies), ({ id }) => id);

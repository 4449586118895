import { useAnalytics } from '@/app/hooks/use-analytics';
import { RepairhubEvents } from '@/app/utils/analytics/events';
import { Job, decodeGapcPartIdentityKey } from '@sdk/lib';
import { isNil } from 'lodash-es';
import { useCallback } from 'react';
import { DisplayableAssemblyVariants } from '../../types';

export const useAmbiguityAnalytics = (job: Job) => {
	const { logEvent } = useAnalytics();

	const logAmbiguity = useCallback(
		(assembly: DisplayableAssemblyVariants) =>
			logEvent(
				RepairhubEvents.job_part_variants_ambiguity({
					job_id: job.id,
					job_number: job.jobNumber ?? '',
					vehicle: job.vehicle?.plateNumber ?? job.vehicle?.chassisNumber ?? '',
					is_showing_fitment_info: assembly.variants.some(
						({ fitment, attributes }) => !isNil(fitment) || !isNil(attributes)
					),
					variants: assembly.variants.map(({ part, description }) => {
						const { mpn, gapcBrandId } = decodeGapcPartIdentityKey(part.partIdentity);
						return {
							mpn,
							description,
							gapc_brand_id: gapcBrandId
						};
					})
				})
			),
		[job, logEvent]
	);

	return logAmbiguity;
};

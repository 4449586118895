import { useMeasurement } from '@/app/hooks/use-measure';
import { tlsx } from '@/app/utils/tw-merge';
import { isDefined } from '@/sdk/lib/utils/object';
import { ShoppingBagIcon } from '@heroicons/react/24/outline';
import { Button, Popover } from '@mantine/core';
import { encodeGapcPartIdentityKey } from '@sdk/lib';
import { entries, sortBy } from 'lodash-es';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { DisplayableAssembly, PartInterpretationFormData } from '../../types';
import { findAssemblyById } from '../../utils';
import { alphabeticSortKey, codeSortKey } from '../../utils/sort';
import { ControlledCustomPartItem, ControlledPartSelectionItem } from './subcomponents';

type PartInterpretationSelectionProps = {
	control: Control<PartInterpretationFormData>;
	selection: PartInterpretationFormData;
	highlighted: string[];
	assemblies: {
		filtered: DisplayableAssembly[];
		all: DisplayableAssembly[];
	};
	isViewingAssemblies: boolean;
	onHighlight: (ids: string[], viewAllDiagrams?: boolean) => void;
};

const PartInterpretationSelection = ({
	control,
	selection,
	assemblies,
	isViewingAssemblies,
	highlighted,
	onHighlight
}: PartInterpretationSelectionProps) => {
	const { value: nav } = useMeasurement('navigation-bar');
	const selectionCount = useMemo(() => {
		return Object.values(selection).reduce((acc, value) => acc + (value?.quantity > 0 ? 1 : 0), 0);
	}, [selection]);

	const assemblySelection = useMemo(() => {
		return sortBy(
			Object.values(selection)
				.map(value => {
					const id = value?.assemblyId;
					if (!value?.quantity || !id) return null;
					if (!isViewingAssemblies) {
						const assembly = findAssemblyById(value.assemblyId, assemblies.all);
						if (!assembly) {
							return null;
						}
						return {
							...value,
							id,
							assembly,
							isInView: true
						};
					}

					const assembly = findAssemblyById(value.assemblyId, assemblies.filtered);
					if (assembly) {
						return {
							...value,
							id,
							assembly,
							isInView: true
						};
					}

					const fallback = findAssemblyById(value.assemblyId, assemblies.all);
					if (!fallback) {
						return null;
					}
					return {
						...value,
						id,
						assembly: fallback,
						isInView: false
					};
				})
				.filter(isDefined),
			({ assembly }) => codeSortKey(assembly),
			({ assembly }) => alphabeticSortKey(assembly)
		);
	}, [selection, isViewingAssemblies, assemblies]);

	const otherSelection = useMemo(() => {
		return sortBy(
			entries(selection)
				.map(([id, value]) => {
					if (!value?.quantity) return null;
					if (value.assemblyId) return null;

					return {
						id,
						...value
					};
				})
				.filter(isDefined),
			({ description }) => description
		);
	}, [selection, assemblySelection]);

	const shouldShowSelectionDifference = useMemo(
		() => otherSelection.length > 0 && assemblySelection.length > 0,
		[otherSelection, assemblySelection]
	);

	return (
		<Popover
			width="25rem"
			position="bottom-end"
			withArrow
			shadow="md"
			disabled={selectionCount === 0}
			keepMounted={true}
		>
			<Popover.Target>
				<Button
					size="sm"
					variant="light"
					data-testid="parts-added"
					data-disabled={selectionCount === 0 ? 'true' : undefined}
				>
					<ShoppingBagIcon className="w-4 h-4 mr-2" />
					{selectionCount}
				</Button>
			</Popover.Target>
			<Popover.Dropdown
				className="py-4 px-6 overflow-auto"
				style={{ maxHeight: `calc(100dvh - ${nav?.height}px - 1rem)` }}
			>
				<div
					className={tlsx('flex items-center justify-between w-full', {
						'pb-3.5': selectionCount > 0
					})}
				>
					<span className="font-semibold text-gray-900">
						{selectionCount > 0 ? `${selectionCount} parts selected` : 'No part selected'}
					</span>
				</div>
				{assemblySelection.length > 0 && (
					<>
						{shouldShowSelectionDifference && (
							<div className="text-gray-600 text-xs font-medium pt-1">Selected parts</div>
						)}
						<div className="divide-y">
							{assemblySelection.map(({ assembly, id, gapcBrandId, mpn, isInView }) => (
								<ControlledPartSelectionItem
									key={`${assembly.id}${encodeGapcPartIdentityKey({ gapcBrandId, mpn })}`}
									control={control}
									id={id}
									highlighted={highlighted}
									assembly={assembly}
									onHighlight={ids => onHighlight(ids, isInView)}
								/>
							))}
						</div>
					</>
				)}

				{otherSelection.length > 0 && (
					<>
						{shouldShowSelectionDifference && (
							<div className="text-gray-600 text-xs font-medium pt-1">Custom parts</div>
						)}
						<div className="divide-y">
							{otherSelection.map(({ id, ...part }) => (
								<ControlledCustomPartItem key={id} id={id} control={control} part={part} />
							))}
						</div>
					</>
				)}
			</Popover.Dropdown>
		</Popover>
	);
};

export default PartInterpretationSelection;

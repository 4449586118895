import { Badge } from '@/app/atoms/badge';
import { ConfidenceBadge } from '@/app/atoms/confidence-badge';
import ImageWithSkeleton from '@/app/atoms/image-with-skeleton';
import FitmentInfo from '@/app/molecules/fitment-info';
import { formatShippingTime } from '@/app/utils/date';
import { formatGradeRange } from '@/app/utils/grade';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { ChevronRightIcon, PlusIcon } from '@heroicons/react/24/outline';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { Checkbox, CheckboxProps, Indicator } from '@mantine/core';
import { Availability, Job, decodeGapcPartIdentityKey } from '@sdk/lib';
import { isNil, uniqBy } from 'lodash-es';
import { ElementRef, ReactNode, useEffect, useId, useMemo, useRef, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useAmbiguityAnalytics } from '../../hooks/use-ambiguity-analytics';
import {
	DisplayableAssembly,
	DisplayableAssemblyVariant,
	DisplayableBrowsingDiagram,
	PartInterpretationFormData,
	PartInterpretationSelection
} from '../../types';
import { countAddedAssemblies } from '../../utils';
import { formatCode, formatPrimaryCode } from '../../utils/part-slot-code';

const AVAILABILITY: Record<Availability, string> = {
	InStock: 'Available',
	OutOfStock: 'None available',
	NoSupply: 'No supply'
};

const CONFINDENCE_THRESHOLD = 10;

export type ControlledPartAssemblyItemsProps = InheritableElementProps<
	'div',
	{
		control: Control<PartInterpretationFormData>;
		selection: PartInterpretationFormData;
		job: Job;
		assemblies: DisplayableAssembly[];
		highlighted: string[];
		scrollTo: string | null;
		parentAdded?: boolean;
		onHighlight: (ids: string[]) => void;
	}
>;

export const ControlledPartAssemblyItems = ({
	control,
	assemblies,
	selection,
	highlighted,
	onHighlight,
	...rest
}: ControlledPartAssemblyItemsProps) => {
	const [open, setOpen] = useState<string>();

	// open the first assembly that itself or a sub assemblies is highlighted
	// keep it open / togglable after unless other interaction happens
	useEffect(() => {
		for (const assembly of assemblies) {
			if (assembly.within.assemblies.some(id => highlighted.includes(id))) {
				setOpen(assembly.id);
				break;
			}
		}
	}, [highlighted, assemblies]);

	return assemblies.map(assembly => (
		<ControlledPartAssemblyItem
			key={assembly.id}
			assembly={assembly}
			control={control}
			selection={selection}
			highlighted={highlighted}
			open={open === assembly.id}
			onOpen={id => setOpen(prev => (prev !== id ? id : undefined))}
			onHighlight={onHighlight}
			{...rest}
		/>
	));
};

export type ControlledPartAssemblyItemProps = InheritableElementProps<
	'div',
	{
		control: Control<PartInterpretationFormData>;
		selection: PartInterpretationFormData;
		job: Job;
		assembly: DisplayableAssembly;
		highlighted: string[];
		scrollTo: string | null;
		parentAdded?: boolean;
		open: boolean;
		onOpen: (id: string) => void;
		onHighlight: (ids: string[]) => void;
	}
>;

export const ControlledPartAssemblyItem = ({
	control,
	assembly,
	selection,
	highlighted,
	onHighlight,
	...rest
}: ControlledPartAssemblyItemProps) => {
	switch (assembly.kind) {
		case 'single': {
			const { id, part, partSlotIds } = assembly;
			if (isNil(part)) {
				return (
					<PartAssemblyItem
						control={control}
						assembly={assembly}
						selection={selection}
						highlighted={highlighted}
						onHighlight={onHighlight}
						{...rest}
					/>
				);
			}
			return (
				<Controller
					control={control}
					name={id}
					render={({ field }) => (
						<PartAssemblyItem
							control={control}
							assembly={assembly}
							selection={selection}
							highlighted={highlighted}
							onHighlight={onHighlight}
							checkbox={{
								checked: field.value?.quantity > 0,
								onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
									const event: PartInterpretationSelection = {
										...decodeGapcPartIdentityKey(part.partIdentity),
										partSlotIds,
										description: assembly.description,
										quantity: e.target.checked ? 1 : 0,
										assemblyId: id
									};

									field.onChange(event);
								}
							}}
							{...rest}
						/>
					)}
				/>
			);
		}
		case 'variants': {
			const { variants } = assembly;
			const referToDiagram = uniqBy(variants, ({ code }) => code).length === variants.length;

			return (
				<PartAssemblyItem
					control={control}
					assembly={assembly}
					selection={selection}
					highlighted={highlighted}
					onHighlight={onHighlight}
					{...rest}
				>
					<div className="flex flex-col w-full gap-2 pb-3">
						<span className="text-xs font-semibold text-gray-500 ml-[4.75rem]">
							{variants.length} variants
						</span>
						{assembly.variants.map(variant => (
							<Controller
								key={variant.id}
								control={control}
								name={variant.id}
								render={({ field }) => (
									<Variant
										id={variant.id}
										variant={variant}
										highlighted={highlighted}
										onHighlight={onHighlight}
										checkbox={{
											checked: field.value?.quantity > 0,
											onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
												const event: PartInterpretationSelection = {
													...decodeGapcPartIdentityKey(variant.part.partIdentity),
													description: assembly.description,
													partSlotIds: variant.partSlotIds,
													quantity: e.target.checked ? 1 : 0,
													assemblyId: variant.id
												};

												field.onChange(event);
											}
										}}
										referToDiagram={referToDiagram}
									/>
								)}
							/>
						))}
					</div>
				</PartAssemblyItem>
			);
		}
	}
};

export type PartAssemblyItemProps = InheritableElementProps<
	'div',
	{
		control: Control<PartInterpretationFormData>;
		selection: PartInterpretationFormData;
		job: Job;
		assembly: DisplayableAssembly;
		highlighted: string[];
		scrollTo: string | null;
		open: boolean;
		onOpen: (id: string) => void;
		onHighlight: (ids: string[]) => void;
		checkbox?: Omit<CheckboxProps, 'id' | 'radius' | 'size'>;
		parentAdded?: boolean;
		children?: ReactNode | null;
	}
>;

export const PartAssemblyItem = ({
	control,
	selection,
	job,
	assembly,
	highlighted,
	open,
	onOpen,
	onHighlight,
	checkbox,
	parentAdded,
	children,
	className,
	scrollTo,
	...rest
}: PartAssemblyItemProps): ReactNode | null => {
	const id = useId();
	const ref = useRef<ElementRef<'div'>>(null);
	const trackAmbiguity = useAmbiguityAnalytics(job);

	const assemblyIds = useMemo(
		() => (assembly.kind === 'variants' ? assembly.variants.map(({ id }) => id) : [assembly.id]),
		[assembly]
	);

	const code = useMemo(
		() =>
			assembly.kind === 'single' ? formatCode(assembly.code) : formatPrimaryCode(assembly.codes),
		[assembly]
	);

	const part = useMemo(() => {
		if (assembly.kind === 'single') {
			return assembly.part;
		}
		const highlightedVariant = assembly.variants.find(({ id }) => highlighted.includes(id));
		return (highlightedVariant ?? assembly.variants[0]).part;
	}, [assembly, highlighted]);

	const addedCount = useMemo(
		() => countAddedAssemblies(assembly.assemblies, selection),
		[assembly, selection]
	);

	const isItsOwnPart = useMemo(
		() => !isNil(assembly.kind === 'single' ? assembly.code : assembly.codes),
		[assembly]
	);

	const isHighlighted = useMemo(
		() => assemblyIds.some(id => highlighted.includes(id)),
		[assemblyIds, highlighted]
	);

	const isExpanded = useMemo(() => assembly.assemblies.length > 0 && open, [assembly, open]);

	const isConsideredRecommended = useMemo(() => {
		if (assembly.kind === 'single') {
			return !isNil(assembly.confidence) && assembly.confidence > CONFINDENCE_THRESHOLD;
		}
		return assembly.variants.some(
			({ confidence }) => !isNil(confidence) && confidence > CONFINDENCE_THRESHOLD
		);
	}, [assembly]);

	const isAdded = useMemo(() => {
		if (assembly.kind === 'single') {
			const { part } = assembly;
			return (
				!isNil(part) &&
				!isNil(selection[part.partIdentity]) &&
				selection[part.partIdentity].quantity > 0
			);
		}

		return assembly.variants.some(
			({ part: { partIdentity } }) =>
				!isNil(selection[partIdentity]) && selection[partIdentity].quantity > 0
		);
	}, [assembly, selection]);

	const onPartSlotClick = () => {
		if (assembly.kind === 'single') {
			onHighlight([assembly.id]);
			return;
		}
		const highlightedVariant = assembly.variants.find(({ id }) => highlighted.includes(id));
		const highlightedId = isNil(highlightedVariant)
			? [assembly.variants[0].id]
			: [highlightedVariant.id];
		onHighlight(highlightedId);
	};

	const onRowClick = () => {
		if (assembly.assemblies.length === 0) {
			onPartSlotClick();
			return;
		}
		onOpen(assembly.id);
	};

	// scroll highlighted element to the center of the screen
	useEffect(() => {
		if (isHighlighted && scrollTo && assemblyIds.includes(scrollTo)) {
			ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}, [isHighlighted, scrollTo]);

	// track ambiguity
	useEffect(() => {
		if (assembly.kind === 'variants' && (isExpanded || isHighlighted)) {
			trackAmbiguity(assembly);
		}
	}, [assembly, isExpanded, isHighlighted]);

	return (
		<div
			className={tlsx('relative flex flex-col w-full gap-3', {
				'outline outline-2 outline-gray-300 rounded z-[5]': isHighlighted && isItsOwnPart,
				className
			})}
			data-testid={`parts-assembly-${assembly.id}`}
			{...rest}
		>
			<div className="flex flex-col gap-3 w-full pl-4 py-4 hover:bg-gray-50" ref={ref}>
				<div className="flex items-center gap-3" ref={ref}>
					{checkbox ? (
						<label htmlFor={id} className="px-3">
							<Checkbox
								id={id}
								radius="xl"
								size="md"
								data-code={code}
								data-testid={`parts-assembly-add-${part?.partIdentity}`}
								{...checkbox}
							/>
						</label>
					) : (
						<button type="button" onClick={onRowClick}>
							{assembly.kind === 'variants' ? (
								<PlusIcon
									className="w-4 h-4 my-1 mx-4"
									data-testid={`parts-assembly-expand-${assembly.id}`}
								/>
							) : (
								<ChevronRightIcon
									className={tlsx('w-4 h-4 my-1 mx-4', {
										'rotate-90': isExpanded
									})}
									data-testid={`parts-assembly-expand-${assembly.id}`}
								/>
							)}
						</button>
					)}
					{code && (
						<PartSlot
							type="button"
							code={code}
							isHighlighted={isHighlighted}
							onClick={onPartSlotClick}
							data-testid={`parts-assembly-highlight-${code}`}
						/>
					)}
					<button
						type="button"
						className="flex-1 flex items-center text-start text-sm break-normal"
						onClick={onRowClick}
					>
						{assembly.description}{' '}
						{addedCount > 0 && (
							<span className="text-blue-600 text-xs font-medium pl-4">{addedCount}</span>
						)}
					</button>
					{isConsideredRecommended && (
						<Badge className="flex-shrink-0" size="small" rounded variant="blue">
							<Badge.LeadingIcon as={CheckBadgeIcon} />
							Recommended
						</Badge>
					)}
				</div>

				{isHighlighted && isItsOwnPart && assembly.kind === 'single' && (
					<>
						<div className="flex items-center justify-between pl-[3.75rem] px-3">
							<div className="text-xs">
								<dt className="sr-only">Part number</dt>
								<dd>{part?.mpn ?? '--'}</dd>
							</div>
							<div className="text-xs">
								<dt className="sr-only">Grades</dt>
								<dd>{assembly.grades ? `Grade ${formatGradeRange(assembly.grades)}` : '--'}</dd>
							</div>

							<div className="text-xs">
								<dt className="sr-only">Availability</dt>
								<dd>{assembly.availability ? AVAILABILITY[assembly.availability] : '--'}</dd>
							</div>

							<div className="text-xs">
								<dt className="sr-only">Shipping time</dt>
								<dd>{assembly.shipping ? formatShippingTime(assembly.shipping) : '--'}</dd>
							</div>
							<ConfidenceBadge confidence={assembly.confidence ?? 0} />
						</div>
						<FitmentInfo fitment={assembly.fitment ?? []} attributes={assembly.attributes ?? []} />
					</>
				)}
			</div>

			{(isHighlighted || isExpanded || isAdded) && assembly.kind === 'variants' && children}

			{isExpanded && assembly.assemblies.length > 0 && (
				<div className="flex flex-col w-full pl-6 gap-1.5">
					<span className="text-xs font-semibold pb-3 pl-4">
						Assembly includes these {assembly.assemblies.length} components
					</span>
					<div className="flex flex-col w-full divide-y divide-gray-100">
						<ControlledPartAssemblyItems
							control={control}
							selection={selection}
							assemblies={assembly.assemblies}
							job={job}
							highlighted={highlighted}
							parentAdded={parentAdded ?? isAdded}
							scrollTo={scrollTo}
							onHighlight={onHighlight}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

type VariantProps = InheritableElementProps<
	'div',
	{
		highlighted: string[];
		variant: DisplayableAssemblyVariant;
		id: string;
		checkbox?: Omit<CheckboxProps, 'id' | 'radius' | 'size'>;
		referToDiagram?: boolean;
		onHighlight: (ids: string[]) => void;
	}
>;

export const Variant = ({
	className,
	id,
	variant,
	checkbox,
	highlighted,
	referToDiagram,
	onHighlight,
	...rest
}: VariantProps) => {
	const checkboxId = useId();
	return (
		<div
			className={tlsx(
				'flex flex-col justify-center gap-3 w-full py-2 pl-4 pr-3 hover:bg-gray-50',
				className
			)}
			data-testid={`parts-assembly-variant-${variant.id}`}
			{...rest}
		>
			<div className="flex items-center gap-3">
				<label htmlFor={checkboxId} className="px-3">
					<Checkbox
						id={checkboxId}
						radius="xl"
						size="md"
						data-testid={`parts-assembly-add-${variant.part.partIdentity}`}
						{...checkbox}
					/>
				</label>
				<PartSlot
					type="button"
					code={formatCode(variant.code)}
					isHighlighted={highlighted.includes(id)}
					onClick={() => onHighlight([id])}
					data-testid={`parts-assembly-highlight-${formatCode(variant.code)}`}
				/>
				<button
					type="button"
					className="flex-1 text-sm text-start"
					onClick={() => onHighlight([id])}
				>
					{variant.description}
				</button>
			</div>
			<div className="flex items-center justify-between pl-[3.75rem] px-3">
				<div className="text-xs">
					<dt className="sr-only">Part number</dt>
					<dd>{variant.part.mpn ?? '--'}</dd>
				</div>
				<div className="text-xs">
					<dt className="sr-only">Grades</dt>
					<dd>{variant.grades ? `Grade ${formatGradeRange(variant.grades)}` : '--'}</dd>
				</div>

				<div className="text-xs">
					<dt className="sr-only">Availability</dt>
					<dd>{variant.availability ? AVAILABILITY[variant.availability] : '--'}</dd>
				</div>

				<div className="text-xs">
					<dt className="sr-only">Shipping time</dt>
					<dd>{variant.shipping ? formatShippingTime(variant.shipping) : '--'}</dd>
				</div>
				<ConfidenceBadge confidence={variant.confidence ?? 0} />
			</div>
			{referToDiagram ? (
				<div className="ml-[3.75rem] text-xs text-gray-500 italic">Refer to diagram</div>
			) : (
				<FitmentInfo
					className="ml-[3.75rem]"
					fitment={variant.fitment ?? undefined}
					attributes={variant.attributes ?? undefined}
				/>
			)}
		</div>
	);
};

PartAssemblyItem.Variant = Variant;

type PartSlotProps = InheritableElementProps<
	'button',
	{
		code: string | null;
		isHighlighted: boolean;
	}
>;

const PartSlot = ({
	className,
	code,
	isHighlighted,
	onClick,
	disabled,
	...rest
}: PartSlotProps) => {
	return (
		<button
			className={tlsx(
				'p-0.5 h-[3ch] min-w-[3ch] text-xs text-center bg-gray-200 rounded',
				{
					'bg-blue-600 text-white': isHighlighted
				},
				{
					'opacity-40 cursor-not-allowed': disabled
				},
				{
					'min-w-0 w-1 h-1 rounded-full': !code
				},
				className
			)}
			disabled={!code || disabled}
			onClick={onClick}
			{...rest}
		>
			{code}
		</button>
	);
};

PartAssemblyItem.PartSlot = PartSlot;

export type DiagramBrowsingPreviewProps = InheritableElementProps<
	'button',
	{
		diagram: DisplayableBrowsingDiagram;
		selection: PartInterpretationFormData;
		isSelected: boolean;
		onChangeDiagram: (id: string) => void;
	}
>;

export const DiagramBrowsingPreview = ({
	diagram,
	selection,
	isSelected,
	onChangeDiagram
}: DiagramBrowsingPreviewProps) => {
	const addedCount = useMemo(
		() => countAddedAssemblies(diagram.assemblies, selection),
		[diagram, selection]
	);
	return (
		<button
			type="button"
			className={tlsx('flex flex-col gap-2', { 'text-blue-600': isSelected })}
			onClick={() => onChangeDiagram(diagram.id)}
		>
			<Indicator zIndex={5} disabled={addedCount === 0} size="1.25rem" label={`${addedCount}`}>
				<ImageWithSkeleton
					className={tlsx('aspect-square w-24 lg:w-28 xl:w-32 rounded ring-1 ring-gray-200', {
						'ring-2 ring-blue-500': isSelected
					})}
					src={diagram.image.thumb}
					loading="lazy"
				/>
			</Indicator>
			<span className="capitalize text-start [text-wrap:pretty] text-sm">
				{diagram.name} ({diagram.code})
			</span>
		</button>
	);
};

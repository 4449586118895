import { useAnalytics } from '@/app/hooks/use-analytics';
import { RepairhubEvents } from '@/app/utils/analytics/events';
import { isDefined } from '@/app/utils/common';
import { Job, decodeGapcPartIdentityKey } from '@sdk/lib';
import { isNil } from 'lodash-es';
import { useCallback } from 'react';
import { DisplayableAssembly, DisplayableDiagram, DisplayableDiagramPartSlot } from '../../types';
import { findAssemblyById } from '../../utils';

type UseHighlightAnalyticsProps = {
	job: Job;
	assemblies: DisplayableAssembly[];
	diagram?: DisplayableDiagram;
	diagramHighlight: (partSlot: DisplayableDiagramPartSlot | null) => boolean;
};

export const useHighlightAnalytics = ({
	job,
	assemblies,
	diagram,
	diagramHighlight
}: UseHighlightAnalyticsProps) => {
	const { logEvent } = useAnalytics();

	const logDiagramHighlight = useCallback(
		(partSlot: DisplayableDiagramPartSlot) => {
			const parts = partSlot.assemblies
				?.map(id => findAssemblyById(id, assemblies))
				?.filter(isDefined)
				?.flatMap(assembly => {
					if (assembly.kind === 'variants') {
						return assembly.variants.map(({ part, description }) => {
							const { mpn, gapcBrandId } = decodeGapcPartIdentityKey(part.partIdentity);
							return { description, mpn, gapc_brand_id: gapcBrandId };
						});
					}
					if (assembly.part) {
						const { mpn, gapcBrandId } = decodeGapcPartIdentityKey(assembly.part.partIdentity);
						return [{ description: assembly.description, mpn, gapc_brand_id: gapcBrandId }];
					}
					return [];
				});

			if (!diagram || !parts?.length) {
				return;
			}

			for (const part of parts) {
				logEvent(
					RepairhubEvents.job_part_diagram_interaction({
						job_id: job.id,
						diagram: { name: diagram.name, code: diagram.code, part_slot: partSlot.oemCode },
						part
					})
				);
			}
		},
		[job, diagram, assemblies]
	);

	const diagramHighlightWithAnalytics = useCallback(
		(partSlot: DisplayableDiagramPartSlot | null) => {
			const isSelecting = diagramHighlight(partSlot);
			if (isSelecting && !isNil(partSlot)) {
				logDiagramHighlight(partSlot);
			}
			return isSelecting;
		},
		[logDiagramHighlight, diagramHighlight]
	);

	return { logDiagramHighlight, diagramHighlightWithAnalytics };
};

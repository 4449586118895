import StandardDialog from '@/app/organisms/standard-dialog';
import { GapcBrand } from '@/sdk/lib';
import { InheritableElementProps } from '@/types/utilties';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Select, TextInput } from '@mantine/core';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

const schema = yup.object({
	mpn: yup.string().required().label('Mpn'),
	brand: yup.string().required().label('Brand'),
	description: yup.string().required().label('Description')
});

export type PartInterpretationCustomPartData = yup.InferType<typeof schema>;

type PartInterpretationCustomPartProps = InheritableElementProps<
	'form',
	{
		open: boolean;
		brands: GapcBrand[];
		initialDescription?: string;
		onSubmit: (data: PartInterpretationCustomPartData) => void;
		onClose: () => void;
	}
>;

export const PartInterpretationCustomPart = ({
	open,
	brands,
	initialDescription,
	onSubmit,
	onClose,
	id,
	...rest
}: PartInterpretationCustomPartProps) => {
	const { control, setValue, formState, handleSubmit } = useForm<PartInterpretationCustomPartData>({
		defaultValues: {
			description: initialDescription ?? '',
			brand: brands[0]?.id
		},
		resolver: yupResolver(schema)
	});
	const { isDirty, isSubmitting } = formState;

	useEffect(() => {
		if (isDirty && isSubmitting) return;
		setValue('description', initialDescription ?? '');
	}, [initialDescription, setValue]);

	return (
		<StandardDialog open={open} onClose={onClose}>
			<StandardDialog.Title>Add custom part</StandardDialog.Title>
			<StandardDialog.Content className="w-full min-w-[32rem] max-w-2xl">
				<form id={id} noValidate onSubmit={handleSubmit(onSubmit)} {...rest}>
					<div className="space-y-4">
						<div className="flex items-center w-full">
							<Controller
								control={control}
								name="description"
								render={({ field: { value, ...field } }) => (
									<TextInput
										label="Part name"
										placeholder="Part name"
										className="w-full"
										required
										value={value ?? ''}
										{...field}
									/>
								)}
							/>
						</div>
						<div className="grid grid-cols-8 gap-3 mt-2">
							<Controller
								control={control}
								name="mpn"
								render={({ field: { value, ...field } }) => (
									<TextInput
										label="Mpn"
										placeholder="Mpn"
										className="col-span-4"
										required
										value={value ?? ''}
										{...field}
									/>
								)}
							/>
							<Controller
								control={control}
								name="brand"
								render={({ field }) => {
									return (
										<Select
											data={brands.map(({ id, name }) => ({ value: id, label: name }))}
											label="Brand"
											placeholder="Brand"
											className="col-span-4"
											required
											disabled={brands.length <= 1}
											{...field}
										/>
									);
								}}
							/>
						</div>
					</div>
					<div className="flex items-center justify-end gap-3 mt-12">
						<Button variant="default" type="button">
							Cancel
						</Button>
						<Button form={id} type="submit">
							Add part
						</Button>
					</div>
				</form>
			</StandardDialog.Content>
		</StandardDialog>
	);
};

import { isDefined } from '@/app/utils/common';
import { fuzzyIncludes, similarity, upperToCapitalize } from '@/app/utils/string';
import { PartAssembly } from '@/sdk/lib';
import { isNil, partition, sortBy, uniq, uniqBy } from 'lodash-es';
import { DisplayableAssembly, DisplayableBrowsingDiagram } from '../types';

export const filterSearchAssemblies = (
	assemblies: DisplayableAssembly[],
	search?: string
): DisplayableAssembly[] => {
	return assemblies
		.map(({ assemblies, ...rest }) => ({
			...rest,
			assemblies: filterSearchAssemblies(assemblies, search)
		}))
		.filter(
			({ searchables, assemblies }) =>
				isSearchablesIncludes(searchables, search) || assemblies.length > 0
		);
};

export const filterSearchDiagrams = (
	diagrams: DisplayableBrowsingDiagram[],
	search?: string
): DisplayableBrowsingDiagram[] => {
	return diagrams
		.map(diagram => {
			if (isSearchablesIncludes([diagram.name, diagram.code], search)) {
				return diagram;
			}
			return {
				...diagram,
				assemblies: filterSearchAssemblies(diagram.assemblies, search)
			};
		})
		.filter(diagram => diagram.assemblies.length > 0);
};

const isSearchablesIncludes = (searchables: string[], search?: string) => {
	const s = search?.trim()?.toLowerCase();
	if (isNil(s) || s.length === 0) {
		return true;
	}
	return searchables.some(searchable => fuzzyIncludes(searchable, s));
};

export const getPartAssemblySearchables = (assembly: PartAssembly) =>
	[
		assembly.description,
		assembly.partSlot?.gapcPartType?.name,
		assembly.partSlot?.gapcPosition?.name,
		assembly.part?.mpn,
		...(assembly.partSlot?.gapcPartType?.aliases ?? [])
	]
		.filter(isDefined)
		.flatMap(getPositionAliases);

const getPositionAliases = (str: string): string[] => {
	const aliases = {
		'r/h': ['r/h', 'right hand', 'right-hand'],
		'l/h': ['l/h', 'left hand', 'left-hand']
	};

	return Object.entries(aliases).flatMap(([key, replacements]) => {
		if (!str.includes(key)) {
			return [str];
		}
		return replacements.map(each => str.replaceAll(key, each));
	});
};

const ALIAS_NGRAM_THRESHOLD = 0.75;

export const getSearchSuggestions = (assemblies: DisplayableAssembly[], query: string) => {
	if (!query) {
		return { aliases: [], terms: [] };
	}

	// find matches and convert into easier format (also keep other for "most common")
	const [unsortedMatches, others] = partition(
		assemblies
			.filter(assembly => assembly.kind === 'variants' || !isNil(assembly.part))
			.map(assembly => getSuggestion(assembly, query)),
		({ similarity }) => similarity
	);

	// sort matches and pick best 3 (by similarity and padded with common others)
	const matches = sortBy(unsortedMatches, ({ similarity }) => -similarity);
	const commonOthers = others.slice(0, 3 - matches.length);
	const terms = uniqBy([...matches, ...commonOthers], ({ description }) => description).slice(0, 3);

	// use the matches to find relevant aliases as in:
	// - any keyword that are related to one that highly matches the query (by threshold)
	// - and not the highly match keyword itself (distinct and avoid potential "bumper cover" and "cover bumper" case)
	const unsortedAliases = matches
		.filter(({ similarity }) => similarity >= ALIAS_NGRAM_THRESHOLD)
		.map(({ description, aliases, ...rest }) => {
			const keywords = uniq([description, ...aliases]);
			return {
				keywords: keywords.filter(
					keyword =>
						similarity(keyword, query) < ALIAS_NGRAM_THRESHOLD &&
						!terms.some(({ description }) => keyword === description)
				),
				...rest
			};
		})
		.flatMap(({ keywords, similarity }) =>
			keywords.map(description => ({ description: upperToCapitalize(description), similarity }))
		);

	// sort by the similary of the matching sibling keyword and then alphabetically
	const aliases = uniqBy(
		sortBy(
			unsortedAliases,
			({ similarity }) => -similarity,
			({ description }) => description
		),
		({ description }) => description
	).slice(0, 2);
	return { terms, aliases };
};

const getSuggestion = (assembly: DisplayableAssembly, query: string) => {
	return {
		id: assembly.id,
		description: assembly.description,
		aliases:
			assembly.kind === 'single'
				? assembly.partSlot?.gapcPartType?.aliases ?? []
				: assembly.variants.flatMap(({ partSlot }) => partSlot?.gapcPartType?.aliases ?? []),
		searchables: assembly.searchables,
		similarity: Math.max(...assembly.searchables.map(searchable => similarity(searchable, query)))
	};
};

import { withSignedIn } from '@/app/hoc/with-access';
import { Section } from '@/app/templates/page-section';

import { PageTemplate } from '@templates/page-template';
import { Suspense } from 'react';
import { Link, Outlet } from 'react-router-dom';

const VehicleSearchPage = () => {
	return (
		<PageTemplate>
			<Section className="grid place-items-center mt-4">
				<div className="max-w-sm w-full">
					<div className="flex items-center justify-between w-full">
						<Section.Title>Create Job</Section.Title>
						<Link to="/jobs" className="text-sm font-medium text-theme-600">
							Cancel
						</Link>
					</div>
					<div className="mt-6">
						<Suspense fallback={<div>Loading</div>}>
							<Outlet />
						</Suspense>
					</div>
				</div>
			</Section>
		</PageTemplate>
	);
};

export default withSignedIn(VehicleSearchPage);

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthCredentials } from '../models/AuthCredentials';
import type { BasicAuthChangePassword } from '../models/BasicAuthChangePassword';
import type { NormalizedResponse_for_SingularItemPayload_for_Nullable_User_and_UsersGetAditional } from '../models/NormalizedResponse_for_SingularItemPayload_for_Nullable_User_and_UsersGetAditional';
import type { NormalizedResponse_for_SingularItemPayload_for_String_and_Null } from '../models/NormalizedResponse_for_SingularItemPayload_for_String_and_Null';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UserService {
	constructor(public readonly httpRequest: BaseHttpRequest) {}
	/**
	 * login a user and return jwt on success
	 * @param requestBody
	 * @returns NormalizedResponse_for_SingularItemPayload_for_String_and_Null
	 * @throws ApiError
	 */
	public login(
		requestBody: AuthCredentials
	): CancelablePromise<NormalizedResponse_for_SingularItemPayload_for_String_and_Null> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/users.login',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
	/**
	 * retrieve user info
	 * @returns NormalizedResponse_for_SingularItemPayload_for_Nullable_User_and_UsersGetAditional
	 * @throws ApiError
	 */
	public get(): CancelablePromise<NormalizedResponse_for_SingularItemPayload_for_Nullable_User_and_UsersGetAditional> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/users.get'
		});
	}
	/**
	 * change password
	 * @param requestBody
	 * @returns null
	 * @throws ApiError
	 */
	public passwordUpdate(requestBody: BasicAuthChangePassword): CancelablePromise<null> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/users.password.update',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
}

import { isDefined } from '@/app/utils/common';
import { GapcDiagramSegmentPolygon } from '@/sdk/lib';
import { useQuery } from '@tanstack/react-query';
import { DisplayableDiagram, DisplayableDiagramSegmentedPartSlot } from '../../types';

type Rgb = { r: number; g: number; b: number };

const getAssemblyOnImage = async (
	original: string,
	path: GapcDiagramSegmentPolygon[],
	rgb?: Rgb
) => {
	// Create canvas
	const canvas = document.createElement('canvas');
	document.body.append(canvas);
	canvas.style.position = 'absolute';
	canvas.style.top = '0';
	canvas.style.left = '0';
	const ctx = canvas.getContext('2d', { willReadFrequently: true });
	if (!ctx) {
		return;
	}

	// Fetch (make local) of the original image
	const res = await fetch(original);
	const blob = await res.blob();
	const src = URL.createObjectURL(blob);

	// Load image and adjust canvas to match
	const img = new Image();
	await new Promise<Event>(res => {
		img.addEventListener('load', res);
		img.src = src;
	});
	canvas.width = img.width;
	canvas.height = img.height;

	// Draw path and clip canvas
	ctx.beginPath();
	const [{ x, y }, ...rest] = path;
	ctx.moveTo(x, y);
	for (const { x, y } of rest) {
		ctx.lineTo(x, y);
	}
	ctx.closePath();
	ctx.clip();

	// Put image into canvas (should be clipped)
	ctx.drawImage(img, 0, 0);

	// Crop and trim image into canvas
	const rect = {
		x1: Math.min(...path.map(({ x }) => x)),
		x2: Math.max(...path.map(({ x }) => x)),
		y1: Math.min(...path.map(({ y }) => y)),
		y2: Math.max(...path.map(({ y }) => y))
	};
	const imageData = ctx.getImageData(rect.x1, rect.y1, rect.x2 - rect.x1, rect.y2 - rect.y1);

	// Resize the canvas to the cropped size
	canvas.width = rect.x2 - rect.x1;
	canvas.height = rect.y2 - rect.y1;

	// Put the cropped image data back onto the resized canvas
	ctx.putImageData(imageData, 0, 0);

	// Change colour
	if (rgb) {
		const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
		const { data } = imageData;

		for (let i = 0; i < data.length; i += 4) {
			// Check if the pixel is black (or very dark)
			if (data[i] < 10 && data[i + 1] < 10 && data[i + 2] < 10) {
				// Change the color to the new color
				data[i] = rgb.r; // Red
				data[i + 1] = rgb.g; // Green
				data[i + 2] = rgb.b; // Blue
			}
		}

		ctx.putImageData(imageData, 0, 0);
	}

	// Convert canvas into image url
	const clipped = canvas.toDataURL();

	// Cleanup
	URL.revokeObjectURL(src);
	document.body.removeChild(canvas);
	canvas.remove();

	return { src: clipped, ...rect };
};

export const useDiagramSegments = (
	diagram: DisplayableDiagram,
	rgb: Rgb = { r: 29, g: 78, b: 216 }
) => {
	const { data, isLoading } = useQuery({
		queryKey: ['diagram', 'segments', diagram.id],
		queryFn: async (): Promise<DisplayableDiagramSegmentedPartSlot[]> => {
			try {
				const flatten = diagram.partSlots.flatMap(({ id, segments }) =>
					segments.map(({ polygons }) => ({
						id,
						polygons
					}))
				);

				const images = await Promise.all(
					flatten.map(({ polygons }) => getAssemblyOnImage(diagram.image.large, polygons, rgb))
				);
				const segmented = flatten
					.map(({ id }, i) => [id, images[i]] as const)
					.map(([id, image]) => ({ id, image }));

				return diagram.partSlots.map(({ id, ...rest }) => ({
					id,
					...rest,
					images: segmented
						.filter(({ id: _id }) => id === _id)
						.map(({ image }) => image)
						.filter(isDefined)
				}));
			} catch (e) {
				return [];
			}
		}
	});

	return { isSegmenting: isLoading, partSlots: data };
};

import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { Dialog, Transition } from '@headlessui/react';
import { CloseButton, LoadingOverlay } from '@mantine/core';
import { Fragment, PropsWithChildren, createContext, useContext, useMemo } from 'react';

export type StandardDialogProps = {
	open: boolean;
	afterLeave?: () => void;
} & StandardDialogContext &
	PropsWithChildren<unknown>;

type StandardDialogContext = {
	onClose: () => void;
};

// create a context for onClose that can be shared between StandardDialog and StandardDialog.Title
const StandardDialogContext = createContext<StandardDialogContext>(
	// Lies, but we set it on first render.
	{} as StandardDialogContext
);

export const useStandardDialog = () => useContext(StandardDialogContext);

const StandardDialog = ({ open, onClose, afterLeave, children }: StandardDialogProps) => {
	const dialogContext = useMemo(() => ({ onClose }), [onClose]);
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-40" onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
					afterLeave={afterLeave}
				>
					<div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
				</Transition.Child>
				<div className="fixed inset-0 z-30 overflow-y-auto">
					<div className="flex items-end justify-center min-h-full sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white rounded-t-lg shadow-xl mt-14 pb-6 sm:pb-[unset] sm:rounded-lg sm:my-8 sm:min-w-[384px]">
								<StandardDialogContext.Provider value={dialogContext}>
									{children}
								</StandardDialogContext.Provider>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

const Title = ({
	children,
	className,
	...rest
}: // eslint-disable-next-line @typescript-eslint/ban-types
InheritableElementProps<'div', {}>) => {
	const { onClose } = useStandardDialog();
	return (
		<Dialog.Title
			as="div"
			className={tlsx(
				'flex items-center justify-between w-full px-5 py-3.5 border-b sm:px-6 sm:py-3',
				className
			)}
			{...rest}
		>
			<h1 className="font-semibold text-gray-900">{children}</h1>
			<CloseButton size="md" aria-label="Close dialog" onClick={onClose} />
		</Dialog.Title>
	);
};

const Content = ({
	isFetching,
	children,
	className,
	...rest
}: InheritableElementProps<'div', { isFetching?: boolean }>) => (
	<div className={tlsx('p-4 sm:p-6 relative', className)} {...rest}>
		<LoadingOverlay visible={isFetching ?? false} overlayBlur={2} />
		{children}
	</div>
);

StandardDialog.Title = Title;
StandardDialog.Content = Content;

export default StandardDialog;

import { ElementRef, ReactEventHandler, useEffect, useMemo, useState } from 'react';

type ImageSizes = {
	width: number;
	height: number;
	naturalWidth: number;
	naturalHeight: number;
};

export const useImageDimensionsRatio = () => {
	const [imageElement, setImageElement] = useState<ElementRef<'img'> | null>(null);
	const [imageSizes, setImageSize] = useState<ImageSizes | null>(null);

	const ratio = useMemo(() => {
		if (!imageSizes) {
			return { width: 1, height: 1 };
		}

		return {
			width: imageSizes.width / imageSizes.naturalWidth,
			height: imageSizes.height / imageSizes.naturalHeight
		};
	}, [imageSizes]);

	const onLoad: ReactEventHandler<HTMLImageElement> = ev => {
		setImageElement(ev.currentTarget);
	};

	useEffect(() => {
		if (!imageElement) {
			return;
		}

		const onResize = () => {
			setImageSize({
				width: imageElement.width,
				height: imageElement.height,
				naturalWidth: imageElement.naturalWidth,
				naturalHeight: imageElement.naturalHeight
			});
		};

		onResize();

		// Listen if the image itself changes without any change to the screensize (scrollbar appearing)
		const observer = new ResizeObserver(onResize);
		observer.observe(imageElement);

		// Listen if the window / screen got resized
		window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
			observer.disconnect();
		};
		// return () => observer.disconnect();
	}, [imageElement, setImageSize]);

	return {
		ratio,
		imageSizes,
		onLoad
	};
};

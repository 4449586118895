import { InheritableElementProps } from '@/types/utilties';
import { TrashIcon } from '@heroicons/react/24/solid';
import { ActionIcon } from '@mantine/core';
import { decodeGapcPartIdentityKey, encodeGapcPartIdentityKey } from '@sdk/lib';
import { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import {
	DisplayableAssembly,
	PartInterpretationFormData,
	PartInterpretationSelection
} from '../../types';
import { formatCode, formatPrimaryCode } from '../../utils/part-slot-code';
import { PartAssemblyItem } from '../part-interpretation-form/subcomponents';

type ControlledPartSelectionItemProps = InheritableElementProps<
	'div',
	{
		control: Control<PartInterpretationFormData>;
		id: string;
		assembly: DisplayableAssembly;
		highlighted: string[];
		onHighlight: (ids: string[]) => void;
	}
>;
export const ControlledPartSelectionItem = ({
	control,
	assembly,
	id,
	highlighted,
	onHighlight
}: ControlledPartSelectionItemProps) => {
	const isHighlighted = useMemo(
		() =>
			assembly.kind === 'variants'
				? assembly.variants.some(({ id }) => highlighted.includes(id))
				: highlighted.includes(assembly.id),
		[assembly, highlighted]
	);

	const { partIdentity, partSlotIds } = useMemo(
		() =>
			assembly.kind === 'single'
				? { partIdentity: assembly.part?.partIdentity, partSlotIds: assembly.partSlotIds }
				: assembly.variants
						.filter(variant => id === variant.id)
						.map(({ part, partSlotIds }) => ({ partIdentity: part?.partIdentity, partSlotIds }))[0],
		[assembly, id]
	);

	const code = useMemo(
		() =>
			assembly.kind === 'single' ? formatCode(assembly.code) : formatPrimaryCode(assembly.codes),
		[assembly]
	);

	const onClick = () => {
		onHighlight(
			assembly.kind === 'variants' ? assembly.variants.map(({ id }) => id) : [assembly.id]
		);
	};

	return (
		<Controller
			control={control}
			name={id}
			render={({ field }) => (
				<div className="flex items-center gap-2.5 py-4" data-testid={`parts-added-${id}`}>
					<PartAssemblyItem.PartSlot
						type="button"
						code={code}
						isHighlighted={isHighlighted}
						onClick={onClick}
						data-testid={`parts-added-highlight-${code}`}
					/>
					<span className="flex-1 text-sm">{assembly.description}</span>
					<ActionIcon
						variant="transparent"
						color="red"
						onClick={() => {
							if (!partIdentity) {
								return;
							}
							field.onChange({
								...decodeGapcPartIdentityKey(partIdentity),
								partSlotIds,
								quantity: 0,
								assemblyId: id
							});
						}}
						data-testid={`parts-added-remove-${partIdentity}`}
					>
						<TrashIcon className="w-4 h-4" />
					</ActionIcon>
				</div>
			)}
		/>
	);
};

type ControlledCustomPartItemProps = InheritableElementProps<
	'div',
	{
		control: Control<PartInterpretationFormData>;
		id: string;
		part: PartInterpretationSelection;
	}
>;
export const ControlledCustomPartItem = ({ control, part, id }: ControlledCustomPartItemProps) => {
	const { partIdentity, partSlotIds } = useMemo(() => {
		const partIdentity = encodeGapcPartIdentityKey(part);
		return { partIdentity, partSlotIds: part.partSlotIds };
	}, [part, id]);

	return (
		<Controller
			control={control}
			name={id}
			render={({ field }) => (
				<div className="flex items-center gap-2.5 py-4" data-testid={`parts-added-${id}`}>
					<span className="flex-1 text-sm">{part.description ?? part.mpn}</span>
					<ActionIcon
						variant="transparent"
						color="red"
						onClick={() => {
							if (!partIdentity) return;
							field.onChange({
								...decodeGapcPartIdentityKey(partIdentity),
								partSlotIds,
								quantity: 0,
								assemblyId: null
							});
						}}
						data-testid={`parts-added-remove-${partIdentity}`}
					>
						<TrashIcon className="w-4 h-4" />
					</ActionIcon>
				</div>
			)}
		/>
	);
};

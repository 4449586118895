import ImageWithSkeleton from '@/app/atoms/image-with-skeleton';
import FitmentInfo from '@/app/molecules/fitment-info';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { Switch } from '@mantine/core';
import { motion, useTransform } from 'framer-motion';
import { ElementRef, useEffect, useId, useMemo, useRef, useState } from 'react';
import ZoomStepper from '../../../../molecules/zoom-stepper';
import { useDiagramSegments } from '../../hooks/use-diagram-segments';
import { useImageDimensionsRatio } from '../../hooks/use-image-dimensions-ratio';
import { useImagePan } from '../../hooks/use-image-pan';
import { DisplayableDiagram, DisplayableDiagramPartSlot } from '../../types';
import { transformDiagramGraphics } from '../../utils/diagrams';
import { formatCode } from '../../utils/part-slot-code';
import { PartAssemblyItem } from '../part-interpretation-form/subcomponents';

const MIN_SCALE = 0.2;
const MAX_SCALE = 5;

type PartAssemblyDiagramProps = InheritableElementProps<
	'div',
	{
		diagram: DisplayableDiagram;
		highlighted?: string[];
		onHighlight: (partSlot: DisplayableDiagramPartSlot | null) => void;
	}
>;

export const PartAssemblyDiagram = ({
	diagram,
	className,
	highlighted,
	onHighlight,
	...rest
}: PartAssemblyDiagramProps) => {
	const switchId = useId();
	const [hideOverlay, setHideOverlay] = useState(false);
	const { ref, x, y, scale } = useImagePan<ElementRef<'div'>>({
		initialScale: 0.65,
		scaleBounds: {
			min: MIN_SCALE,
			max: MAX_SCALE
		}
	});

	const { ratio, imageSizes, onLoad } = useImageDimensionsRatio();

	const { partSlots } = useDiagramSegments(diagram);

	const invertedScale = useTransform(scale, scale => 1 / scale);

	const isAnyHighlighted = useMemo(
		() =>
			diagram.partSlots.some(
				partSlot =>
					partSlot.assemblies?.some(id => highlighted?.includes(id)) && partSlot.segments.length > 0
			),
		[diagram, highlighted]
	);

	const graphics = useMemo(
		() => transformDiagramGraphics(partSlots ?? diagram.partSlots),
		[diagram, partSlots]
	);

	const onZoomIn = () => {
		const newScale = scale.get() * 1.1;
		return scale.set(Math.min(MAX_SCALE, newScale));
	};

	const onZoomOut = () => {
		const newScale = scale.get() / 1.1;
		return scale.set(Math.max(MIN_SCALE, newScale));
	};

	return (
		<div
			className={tlsx(
				'relative overflow-hidden select-none flex items-center justify-center',
				className
			)}
			{...rest}
		>
			<motion.div
				className="relative touch-none"
				ref={ref}
				style={{ x, y, scale }}
				transition={{
					type: 'inertia'
				}}
			>
				<img
					className={tlsx('opacity-100', {
						'opacity-20': isAnyHighlighted && !hideOverlay
					})}
					src={diagram.image.large}
					onLoad={onLoad}
					draggable={false}
				/>

				{!hideOverlay && (
					<>
						<svg
							className="absolute inset-0"
							width={imageSizes?.width}
							height={imageSizes?.height}
							viewBox={`0 0 ${imageSizes?.naturalWidth ?? 0} ${imageSizes?.naturalHeight ?? 0}`}
							pointerEvents="none"
						>
							{graphics.map((graphic, index) => {
								switch (graphic.kind) {
									case 'segment': {
										const isHighlighted = graphic.partSlot.assemblies?.some(id =>
											highlighted?.includes(id)
										);
										return (
											<image
												key={graphic.href}
												className={tlsx('z-[1] opacity-0', {
													'opacity-100': isHighlighted
												})}
												href={graphic.href}
												x={graphic.x}
												y={graphic.y}
												width={graphic.width}
												height={graphic.height}
											/>
										);
									}
									case 'hotspot': {
										return (
											<rect
												key={`${graphic.partSlot.id}-${index}`}
												className="fill-white stroke-white stroke-[10] z-[1]"
												x={graphic.x}
												y={graphic.y}
												width={graphic.width}
												height={graphic.height}
											/>
										);
									}
									case 'polygon': {
										const isHighlighted = graphic.partSlot.assemblies?.some(id =>
											highlighted?.includes(id)
										);
										return (
											<path
												key={`${graphic.partSlot.id}-${index}`}
												className={tlsx('fill-none', {
													'fill-blue-600/5 stroke-blue-600 stroke-[2]': isHighlighted
												})}
												d={graphic.d}
												onClick={() =>
													onHighlight(
														!isHighlighted && graphic.partSlot.assemblies ? graphic.partSlot : null
													)
												}
												pointerEvents="all"
											/>
										);
									}
								}
							})}
						</svg>
						{diagram.partSlots.map(partSlot => {
							return partSlot.hotspots.map((hotspot, index) => {
								const top = hotspot.y1Px * ratio.height;
								const left = hotspot.x1Px * ratio.width;
								const width = (hotspot.x2Px - hotspot.x1Px) * ratio.width;
								const height = (hotspot.y2Px - hotspot.y1Px) * ratio.height;
								const style = {
									top: top + height / 2,
									left: left + width / 2,
									x: '-50%',
									y: '-50%',
									scale: invertedScale
								};
								const isNotInteractive = !partSlot.assemblies?.length;
								const isHighlighted = partSlot.assemblies?.some(id => highlighted?.includes(id));

								return (
									<motion.div
										key={`${partSlot.code}-${index}`}
										className={tlsx(
											'absolute w-fit h-fit z-[2]',
											{ 'z-[3]': isHighlighted },
											{ 'z-[1]': isNotInteractive }
										)}
										style={style}
									>
										<PartAssemblyItem.PartSlot
											code={formatCode(partSlot.code)}
											isHighlighted={isHighlighted ?? false}
											disabled={isNotInteractive}
											data-testid={`parts-diagram-highlight-${formatCode(partSlot.code)}`}
											onClick={() =>
												onHighlight(!isHighlighted && partSlot.assemblies ? partSlot : null)
											}
										/>
									</motion.div>
								);
							});
						})}
					</>
				)}
			</motion.div>
			<ZoomStepper
				className="absolute top-2 left-2 z-[4]"
				onZoomIn={onZoomIn}
				onZoomOut={onZoomOut}
			/>
			<div className="absolute top-2 right-2 flex flex-col items-end gap-2">
				<span className="capitalize font-semibold text-gray-500 text-xs m-1">
					{diagram.code}: {diagram.name}
				</span>
				{diagram.fitment && <FitmentInfo className="bg-white" fitment={diagram.fitment} />}
				<div className="flex items-center gap-2">
					<label htmlFor={switchId} className="text-xs">
						Hide numbers
					</label>
					<Switch
						id={switchId}
						checked={hideOverlay}
						onChange={() => setHideOverlay(prev => !prev)}
						onLabel={<EyeSlashIcon className="w-4 h-4" />}
						offLabel={<EyeIcon className="w-4 h-4" />}
					/>
				</div>
			</div>
		</div>
	);
};

type PreviewProps = InheritableElementProps<
	'li',
	{
		diagram: DisplayableDiagram;
		selectedDiagram?: DisplayableDiagram;
		onSelect: (diagramId: string) => void;
	}
>;

const Preview = ({ diagram, selectedDiagram, onSelect, ...rest }: PreviewProps) => {
	const ref = useRef<ElementRef<'li'>>(null);
	const isSelected = useMemo(() => selectedDiagram?.id === diagram.id, [diagram, selectedDiagram]);

	useEffect(() => {
		if (isSelected) {
			ref.current?.scrollIntoView({ behavior: 'smooth' });
		}
	}, [isSelected]);

	return (
		<li ref={ref} {...rest}>
			<button
				className={tlsx('relative w-max h-max border-2 border-gray-200 rounded-md', {
					'border-blue-600': diagram.id === selectedDiagram?.id
				})}
				onClick={() => onSelect(diagram.id)}
			>
				<ImageWithSkeleton
					className="h-20 w-20 bg-white rounded-md object-center object-scale-down"
					src={diagram.image.thumb}
				/>
			</button>
		</li>
	);
};

PartAssemblyDiagram.Preview = Preview;

type SelectionProps = InheritableElementProps<
	'ul',
	{
		selectedDiagram: DisplayableDiagram | undefined;
		diagrams: DisplayableDiagram[];
		onSelect: (diagramId: string) => void;
	}
>;

const Selection = ({ selectedDiagram, diagrams, onSelect, className, ...rest }: SelectionProps) => {
	return (
		<ul
			className={tlsx('flex items-center gap-2 max-w-[calc(100%-1rem)] overflow-x-auto', className)}
			{...rest}
		>
			{diagrams.map(each => (
				<Preview
					key={each.id}
					diagram={each}
					selectedDiagram={selectedDiagram}
					onSelect={onSelect}
				/>
			))}
		</ul>
	);
};

PartAssemblyDiagram.Selection = Selection;

export const AppLoader = () => {
	return (
		<div className="flex flex-col justify-end h-full">
			<div className="w-full border-t pb-safe">
				<div className="flex gap-3 p-3">
					<div className="flex-1 h-10 bg-gray-200 rounded animate-pulse" />
					<div className="flex-1 h-10 bg-gray-200 rounded animate-pulse" />
					<div className="flex-1 h-10 bg-gray-200 rounded animate-pulse" />
				</div>
			</div>
		</div>
	);
};

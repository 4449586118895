import { CartItemOfferRaw, CartSubtotalPriceRaw, ShippingTimeRaw } from '../../generated';
import { SupplyItemOffer } from '../types';
import { Context } from '../utils/context';
import { createListing } from './listings';

export const createCartSubtotalPrice = (subtotal: CartSubtotalPriceRaw) => ({
	currency: subtotal.currency,
	price: subtotal.price,
	displayPrice: subtotal.display_price ?? null
});

export const createShippingTime = (shippingTime: ShippingTimeRaw) => ({
	eta: shippingTime.eta,
	arrival_time: shippingTime.arrival_time,
	business_days: shippingTime.business_days
});

export const createOffer = (offer: CartItemOfferRaw, ctx: Context): SupplyItemOffer => {
	const rawListing = ctx.resources.get({ path: 'listings', id: offer.listing_id });
	if (!rawListing) {
		throw new Error(`Cannot find listing ${offer.listing_id}`);
	}

	const listing = createListing(rawListing, undefined, ctx);
	if (!listing) {
		throw new Error(`Cannot create offer for listing ${offer.listing_id}`);
	}

	if (offer.type === 'Product') {
		return {
			type: 'Product',
			listing,
			offerId: offer.offer_id
		};
	}

	return {
		type: 'Kit',
		listing,
		offerIds: offer.offer_ids
	};
};

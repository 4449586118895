import { useSearchQuery } from '@/app/hooks/use-search-param';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { ReactComponent as SearchIcon } from '@assets/search.svg';
import { TextInput } from '@mantine/core';

type JobSearchBarProps = InheritableElementProps<'div', object>;

const JobSearchBar = ({ className, ...rest }: JobSearchBarProps) => {
	const keyword = useSearchQuery('keyword');

	return (
		<div className={tlsx('space-y-2', className)} {...rest}>
			<TextInput
				variant="default"
				radius="xl"
				placeholder="Search by job number"
				autoCapitalize="none"
				data-testid="job-search"
				value={keyword.values[0] ?? ''}
				onChange={e => keyword.set(e.target.value)}
				rightSectionWidth={40}
				rightSection={<SearchIcon />}
			/>
		</div>
	);
};

export default JobSearchBar;

import { EmptyState } from '@/app/atoms/empty-state';
import { DefaultText, HeaderCell } from '@/app/common/components/molecules/cell-builder';
import { JobDetails } from '@/app/features/job/components/job-details';
import { JobImageGrid } from '@/app/features/job/components/job-image-grid';
import { OrderSummary } from '@/app/features/orders/components/order-summary';
import { OrderRequestsSummary } from '@/app/features/supply/components/order-requests-summary';
import { withSignedIn } from '@/app/hoc/with-access';
import { BasicTable } from '@/app/molecules/order-table';
import { Section } from '@/app/templates/page-section';
import { PageTemplate } from '@/app/templates/page-template';
import { tlsx } from '@/app/utils/tw-merge';
import { JobPart } from '@/sdk/lib';
import { jobsQueries } from '@/sdk/react';
import { queries } from '@/sdk/react/queries';
import { draft_order } from '@/sdk/reflect/reflect';
import { InheritableElementProps } from '@/types/utilties';
import { HomeIcon } from '@heroicons/react/24/outline';
import { Button } from '@mantine/core';
import { useSuspenseQueries, useSuspenseQuery } from '@tanstack/react-query';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

type JobPageProps = {
	jobId: string;
};

const JobPage = () => {
	const { jobId } = useParams<JobPageProps>();
	if (!jobId) {
		throw new Error('Missing required jobId parameter');
	}

	const { data: jobData } = useSuspenseQuery(jobsQueries.get({ jobId }));
	const { data: partData } = useSuspenseQuery(jobsQueries.listParts({ jobId }));
	const { data: draftOrderData } = useSuspenseQuery({
		...queries.draft_orders.list({ job_id: jobId }),
		// Refetch for new status' every 30 seconds
		refetchInterval: 30_000
	});

	const canCreateOrderRequest = partData.parts.length > 0;
	const canSelectParts = jobData.job.collisions.length > 0;

	return (
		<PageTemplate className="p-6 bg-gray-50">
			<PageTemplate.Header className="px-6 border-b-gray-200">
				<div className="flex items-center justify-between w-full mx-auto max-w-7xl">
					<Button
						leftIcon={<HomeIcon className="w-5 h-5" />}
						variant="default"
						component={Link}
						to="/jobs"
					>
						Home
					</Button>
				</div>
			</PageTemplate.Header>
			<div className="grid w-full grid-cols-12 gap-6 mx-auto max-w-7xl">
				<div className="col-span-12 space-y-6 md:col-span-4">
					<JobDetails job={jobData.job} />
					<JobImageGrid />
				</div>
				<div className="col-span-12 space-y-6 md:col-span-8">
					<OrderSummary jobId={jobId} />
					<OrderRequestsSection
						orderRequests={draftOrderData.draft_orders}
						jobId={jobId}
						canCreate={canCreateOrderRequest}
					/>
					<PartsList parts={partData.parts} jobId={jobId} canSelect={canSelectParts} />
					<CollisionAreas jobId={jobId} />
				</div>
			</div>
		</PageTemplate>
	);
};

const CollisionAreas = ({
	jobId,
	className
}: InheritableElementProps<'section', { jobId: string }>) => {
	const [{ data: job }, { data: collisionData }] = useSuspenseQueries({
		queries: [jobsQueries.get({ jobId }), jobsQueries.getCollisionMap({ jobId })]
	});

	const areas = useMemo(() => {
		return job.job.collisions.flatMap(collision => {
			const item = collisionData.collisionMap.collisionAreas.find(area => area.id === collision.id);
			if (!item) {
				return [];
			}

			return {
				id: collision.id,
				name: item.name,
				severity: collision.severity
			};
		});
	}, [job, collisionData]);

	if (areas.length === 0) {
		return (
			<Section className={tlsx('bg-white px-6 pt-6 pb-4 border rounded-md', className)}>
				<Section.Title className="sr-only">Collisions</Section.Title>
				<EmptyState className="bg-white">
					<EmptyState.Title>No collision areas</EmptyState.Title>
					<EmptyState.Description>
						Add collision areas to this job to start adding parts.
					</EmptyState.Description>
					<Button variant="subtle" color="blue" component={Link} to={`/job/${jobId}/collision`}>
						Add collision areas
					</Button>
				</EmptyState>
			</Section>
		);
	}

	return (
		<Section className={tlsx('bg-white p-6 border rounded-md', className)}>
			<div className="flex items-center justify-between">
				<Section.Title className="text-lg">Collisions</Section.Title>
				<Button variant="subtle" color="blue" component={Link} to={`/job/${jobId}/collision`}>
					Edit collisions
				</Button>
			</div>
			<div className="mt-4 mb-3 flex items-center w-full">
				<span className="text-sm text-gray-900 font-semibold flex-1">Region</span>
				<span className="text-sm text-gray-900 font-semibold flex-1">Severity</span>
			</div>
			<ul className="space-y-2">
				{areas.map(area => (
					<li key={area.id} className="flex w-full items-center py-1.5 border-b last:border-none">
						<span className="text-sm flex-1">{area.name}</span>
						<span
							className={tlsx(
								'text-sm flex-1',
								{ 'text-red-600': area.severity === 'Heavy' },
								{ 'text-yellow-600': area.severity === 'Medium' },
								{ 'text-blue-600': area.severity === 'Light' }
							)}
						>
							{area.severity}
						</span>
					</li>
				))}
			</ul>
		</Section>
	);
};

const OrderRequestsSection = ({
	orderRequests,
	canCreate,
	jobId,
	className
}: InheritableElementProps<
	'section',
	{ orderRequests: draft_order.exp.DraftOrder[]; jobId: string; canCreate: boolean }
>) => {
	if (!canCreate) {
		return null;
	}

	if (orderRequests.length === 0) {
		return (
			<Section className={tlsx('bg-white px-6 pt-6 pb-4 border rounded-md', className)}>
				<Section.Title className="text-lg">Order requests</Section.Title>
				<EmptyState className="bg-white">
					<EmptyState.Title>No requests</EmptyState.Title>
					<EmptyState.Description>
						Build your first request to start ordering parts
					</EmptyState.Description>
					<Button variant="subtle" color="blue" component={Link} to={`/job/${jobId}/supply`}>
						Select supply
					</Button>
				</EmptyState>
			</Section>
		);
	}

	return <OrderRequestsSummary orderRequests={orderRequests} jobId={jobId} />;
};

const PartsList = ({
	jobId,
	parts,
	canSelect,
	className
}: InheritableElementProps<'section', { jobId: string; parts: JobPart[]; canSelect: boolean }>) => {
	const columnHelper = createColumnHelper<JobPart>();
	const columns = [
		columnHelper.accessor('quantity', {
			id: 'quantity',
			header: () => <HeaderCell>Qty.</HeaderCell>,
			cell: info => <DefaultText>{info.getValue()}</DefaultText>
		}),
		columnHelper.accessor('description', {
			id: 'description',
			header: () => <HeaderCell>Name</HeaderCell>,
			cell: info => <DefaultText>{info.getValue()}</DefaultText>
		})
	];

	const table = useReactTable({
		// todo: proper transform using part slot etc
		data: parts.sort((a, b) => (a.description ?? '--').localeCompare(b.description ?? '--')),
		columns,
		getCoreRowModel: getCoreRowModel()
	});

	if (!canSelect) {
		return null;
	}

	if (parts.length === 0) {
		return (
			<Section className={tlsx('bg-white px-6 pt-6 pb-4 border rounded-md', className)}>
				<Section.Title className="text-lg">Parts</Section.Title>
				<EmptyState className="bg-white">
					<EmptyState.Title>No parts</EmptyState.Title>
					<EmptyState.Description>Select parts to find supply</EmptyState.Description>
					<Button variant="subtle" color="blue" component={Link} to={`/job/${jobId}/parts`}>
						Select parts
					</Button>
				</EmptyState>
			</Section>
		);
	}

	return (
		<Section className={tlsx('bg-white px-6 pt-6 pb-4 border rounded-md', className)}>
			<div className="flex items-center justify-between">
				<Section.Title className="text-lg">Parts</Section.Title>
				<Button variant="subtle" color="blue" component={Link} to={`/job/${jobId}/parts`}>
					View parts
				</Button>
			</div>
			<div className="mt-2 overflow-y-auto max-h-56">
				<BasicTable table={table} />
			</div>
		</Section>
	);
};

export default withSignedIn(JobPage);
